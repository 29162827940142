import React from "react";
import '../css/terms.css';

function Eng_privacy() {



  return (
    <>
        <div id="privacy" className="terms sub">
            <div className="container">
                <h3 className="sub_title">Privacy Policy</h3>
                <hr className="star-primary wow zoomIn"></hr>
                <div className="terms_box">
                    <p>ACT GAMES Co., Ltd. Privacy Policy<br/><br/>
                    ACT GAMES Co., Ltd. (“Company”) respects your privacy and personal information, and observes the “Privacy Agreement”, “Protection of Communications Secrets Act”, “Telecommunications Business Act”, and the laws regarding the promotion of information and communication network”, etc. The Company provides this Privacy Policy to inform you the purpose of collecting your personal information how we make use of it as well as what measures we take to protect your personal information.<br/><br/>
                    This Privacy Policy includes the following content.
                    </p>
                </div>

                <div className="terms_box">
                    <h4>Article. 1 (The Elements of Personal Information we may collect and the collecting method)</h4>
                    <ul>
                        <li>In the first stage of joining the game service, the company may collect your personal information as below to provide various services
                            <ul className="sub_list">
                                <li>- The unique serial number for user identification afforded by the User’s Platform Operator, User’s first and last name or the nickname, user’s mobile ID Number (including the mobile device ID or UUID), etc.</li>
                            </ul>
                        </li>
                        <li>Upon using the service, following information may be produced and collected.</li>
                            <ul className="sub_list">
                                <li>- User Status Information, Date and Time of User’s Visit to the company, Record of Service Use, Record of Abnormal behaviors, physical location of user’s device, IP information and etc.</li>
                            </ul>
                        <li>We are collecting personal information as below for customer service. - Email address, nickname, another name which the user has agreeded to show.</li>
                        <li>. In case that the company requires the following; Information of user’s credit card, mobile network operator, purchase record, coupon code, and other purchase related information may be collected. When the company is requested for recovery or refund of purchased items and paying services, the company can collect user’s mail address, the historical data collection. When the company need to see the proof of a user declaring that any purchase was made by another person without one’s will, the company can ask for and collect additional personal information including the actual name of the user and Family Relation Certificate.</li>
                        <li>The company does not collect any sensitive and private information (Race, Personal Belief, Philosophy, Political Tendency, Criminal Record, Medical Record, etc.)</li>
                        <li>The company collects user’s personal information in the following ways.
                            <ul className="sub_list">
                                <li>- Collect via Platform Operator in a partnership with the company for the service.</li>
                                <li>- Collect via voluntary submission from the user who is using the service.</li>
                                <li>- Collect via the information that is already provided by the user for service utility.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="terms_box">
                    <h4>Article. 2 (Collecting Personal Information and the Use)</h4>
                    <ul>
                        <li>. Basic function to encounter the service.
                            <ul className="sub_list">
                                <li>- We collect and save the mobile ID Number (including the mobile device ID or UUID) by checking the Unique Identification Number of user’s account for the purpose of verifying the user’s account.</li>
                            </ul>
                        </li>
                        <li>Fulfillment of contract as a game service provider and balancing the Charging and Setting.
                            <ul className="sub_list">
                                <li>- We provide free/premium contents, purchased goods, self-authentication system, age authentication, finalization of what is bought and paid accordingly, balanced charges and collection of the uncollected charges.</li>
                            </ul>
                        </li>
                        <li>User Management
                            <ul className="sub_list">
                                <li>- We require Self-Verification upon using the service, User-Verification before the access to the account of the game service, Individual Identification, Prevention of Mod-program and Abusing any content of the game, Approval of Joining the game service, Limits to creating multiple accounts, Preservation of records of Personal information for further authentication and dispute conciliation, handling of complaints and civil affairs, and conveyance of notification.</li>
                            </ul>
                        </li>
                        <li>Development of New Service and Marketing/ Advertisement.
                            <ul className="sub_list">
                                <li>- We may refer to user’s personal information not only to develop new contents but also provide customized services. Our service bases on the statistic in-game data which can be used for advertising purposes. Also, the validation of service is confirmed before the release. The personal information can be a source to be referenced to use in creating any events or advertisements for users to participate. Access frequency of users and other types of similar information can be used as statistical information to research the state of service use.</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 3 (Sharing of User’s Information)</h4>
                    <ul>
                        <li>We use personal information of users in the boundary of ‘Collecting Personal Information and the Use’ and there would be no situation where we over-use such information without the consent of users or reveal it to 3rd person. However, exceptions may be aroused as
                            <ul className="sub_list">
                                <li>- We gain user’s consent.</li>
                                <li>- We process the personal information as illegible to protect the privacy of individuals for the purpose of statistical data use.</li>
                                <li>- We meet the situation where we have to accept the request of investigating agencies to follow certain procedures.</li>
                            </ul>
                        </li>
                        <li>In the case that is inevitable for affiliate companies to provide user’s information to the limited extent, we share or provide such information after having the consent from users about to whom and what information we are sharing with in what purpose and for the purpose of providing various kind of service.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 4 (Charges of Personal Information and Transfer to Other Countries)</h4>
                    <ul>
                        <li>In principle, the Company does not entrust other companies for handling of personal information of users.</li>
                        <li>Despite Article 1, the company can entrust other companies for better managing and handling some of the personal information much smoother as follows. Also, it can be safely managed in accordance with the privacy laws.
                            <ul className="sub_list">
                                <li>- Contractor: Payment Market, Payment Agency (Thailand / Vietnam only)</li>
                                <li>- Business contents: Payment agent and identity verification</li>
                                <li>- Period: Till the moment of the termination of service or termination of consignment contract</li>
                            </ul>
                        </li>
                        <li>The Company will not transfer the personal information of users to outside the country without the consent of the user.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 5 (Period of possessing and using personal information)</h4>
                    <p>In principle, the personal information of the user is destroyed without delay when the purpose of collecting and using the personal information is achieved. However, the following information will be kept for the period specified below for the following reasons.</p>
                    <ul>
                        <li>If a member requests withdrawal or destruction of personal information, the information shall be destroyed immediately and irretrievably.
However, if it is necessary to preserve the information for a certain period of time in accordance with the provisions of the relevant laws and regulations, the company will keep the personal information for the period specified in the relevant laws and regulations, and will not use this information for other purposes. 
The retention period for each item shall be in accordance with Paragraph 4.
</li>
                        <li>In the case of investigations or investigations related to the violation of related laws and regulations, the information of the users will be preserved until the end of investigation.</li>
                        <li>If there is a claim-obligation relationship between the company and the user, the personal information of the user is retained until the settlement of the relationship is resolved or complete.</li>
                        <li>When it is necessary to preserve the information according to the related laws and regulations such as commercial law, consumer protection law in e-commerce, etc, the company keeps user information for a certain period determined by related laws and regulations. In this case, the company will only use the information it keeps for the purpose of storing it, and the period of preservation is as follows.
                            <ul className="sub_list">
                                <li>- Records on contract or withdrawal of subscription (Reason for preservation: Consumer Protection Act on Electronic Commerce, etc., Preservation period: 5 years)</li>
                                <li>- Records on provision of goods and any information about the payments (Reason for Preservation: Consumer Protection Act on Electronic Commerce, etc. Preservation period: 5 years)</li>
                                <li>- Records on complaints or disputes with consumers (Reason for Preservation: Consumer Protection Act on Electronic Commerce, etc. Preservation period: 3 years)</li>
                                <li>- Date and time of telecommunication, open•end time, user id, frequency of use, location tracking records for the origin from the base station (Reason for Preservation: Communication Confidentiality Protection Act, Preservation period: 1 year)</li>
                                <li>- Record of identification (Reason for Preservation: Act on the Promotion of Information and Communications Network Utilization and Information Protection, etc. Preservation period: 6 months)</li>
                                <li>- Records on displaying and advertising (Reason for Preservation: Consumer Protection Act on Electronic Commerce, etc. Preservation period: 6 months)</li>
                                <li>- Records on the visiting the company (Reason for Preservation: Communication Confidentiality Protection Act, preservation period: 3 months)</li>
                            </ul>
                        </li>
                    </ul>
                </div> 

                <div className="terms_box">
                    <h4>Article. 6 (Procedures and Methods of Destroying Personal Information)</h4>
                    <p>In principle, the personal information of a user is destroyed without delay when the purpose of collecting and using the personal information is achieved. The procedure and method are as follows.</p>
                    <ul>
                        <li>Destruction procedure
                            <ul className="sub_list">
                                <li>- The information entered by the user to join the service is transferred to a separate DB after the purpose is accomplished and stored for a certain period of time according to the internal policy and other related laws and regulations (see the period of possession and use).</li>
                                <li>- Personal information will not be used for any other purpose other than that held by law.</li>
                            </ul>
                        </li>
                        <li>. Destruction method of Personal information
                            <ul className="sub_list">
                                <li>- Paperwork is destroyed by crusher or by incineration.</li>
                                <li>- Any form of electronic file is deleted using a technical method that cannot play the record.</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 7 (Rights of Users and Legal Guardians, and How to Act)</h4>
                    <ul>
                        <li>Users and legal guardians can view or modify personal information of users who are registered at any time, or users who are under 14 years old, through platform operators or the open market operators, to request termination of services through logging- out and deleting process of applications.</li>
                        <li>In order for the user to check his / her personal information that is not owned by the company, the company shall compare the subscription history that the company has to the other personal information that user’s platform operator or the open market operator providing the service. match them so that the company can verify the user’s identification.</li>
                        <li>The Company shall not use or provide personal information until the correction is completed if the user requests for correction of errors in the personal information. In addition, if wrong personal information has already been provided to a third party, the Company will promptly notify the third party of the result of the correction and allow the correction to be made.</li>
                        <li>The Company treats personal information that has been revoked or deleted at the request of the user or legal guardian as specified in Article 5, "Period of possessing and using personal information", and is prohibited from being viewed or used for other purposes.</li>
                        <li>Your California Privacy Rights<br></br>
                        California Civil Code Section 1798 allows California residents to have the right to request (a) information identifying any third party to whom the company may have disclosed (within the previous calendar year) his/her personal information for that third party’s direct marketing purposes; and (b) a description of the categories of personal information disclosed. If you are a California resident and wish to obtain such information, please send a request email to <span style={{color:"#ff5967"}}>support@actgames.co.kr.</span>
                        </li>
                        <li>Privacy Rights for California Minors<br></br>
                        California residents under the age of 18 having a registered account, have the right to request removal of any content or information that he or she posted anywhere within our Services, such as public forum or message boards in our Websites or Applications. To request removal of such information, you can contact the company at <span style={{color:"#ff5967"}}>support@actgames.co.kr</span> stating that you reside in California. Please be aware that the removal of your information or content may not be complete or comprehensive, and residual copies of such removed information or content may remain on our servers. In addition, we are not obligated to remove any posted content or information that has been copied or reposted by a third party, that has been rendered anonymous, or that we are required to keep by law.
                        </li>
                    </ul>
                </div>                                                                                                               

                <div className="terms_box">
                    <h4>Article. 8 (Technical and administrative protection measures of personal information)</h4>
                    <p>The Company takes the following technical and administrative measures to ensure the safety of personal information in order to prevent personal information from being lost, stolen, leaked, altered or damaged. However, the Company cannot guarantee 100% protection for the loss or corruption of the above personal information due to the process of transmission of the user's personal information or the attack by Hackers and other 3rd entity.</p>
                    <ul>
                        <li>Measures against hacking
                            <ul className="sub_list">
                                <li>- The company is doing its best to prevent personal information from being leaked or damaged by hacking or computer virus. In order to prevent damage to personal information, we backup the data from time to time to prevent personal information or data from being leaked or damaged, and we can securely transmit personal information over the network through encrypted communication. We use intrusion prevention system to control unauthorized access from the outside, and try to have all possible technical devices to ensure security in other systems.</li>
                            </ul>
                        </li>
                        <li>Minimization of the staff handling such information and the training.
                            <ul className="sub_list">
                                <li>- The staffs who handle company's personal information is limited to the person in charge and a separate password is given to them to update them regularly. We constantly emphasize the importance of compliance with Actgames's privacy policy through regular training.</li>
                            </ul>
                        </li>
                        <li>Establishment and enforcement of internal management plan
                            <ul className="sub_list">
                                <li>- The Company has established and implemented an internal management plan for the safe management of personal information.</li>
                            </ul>
                        </li>
                        <li>Operation of personal information protection organization
                            <ul className="sub_list">
                                <li>- The Company is responsible for confirming compliance with the Actgames Privacy Policy and the person in charge through the in-house personal information protection organization, so that if any problem is found, it can be corrected immediately. However, the Company does not take any responsibility for any problems caused by leakage of personal information for reasons such as user's negligence. The Company still is in charge for the issues aroused by company's intentional or material negligence.</li>
                            </ul>
                        </li>
                    </ul>
                </div>                

                <div className="terms_box">
                    <h4>Article. 9 (Chief Manager of Personal Information)</h4>
                    <p>In order to protect your personal information and to handle relevant complaints, the Company has designated a Chief Manager of Personal Information as described below.</p>
                    <ul>
                        <li>[Chief Manager of Personal information]
                        <br></br>Position: Director of Business
                        <br></br>Name: Hyukmin Ma
                        <br></br>Phone: +82-70-4117-9408
                        <br></br>E-mail: support@actgames.co.kr</li>
                    </ul>
                </div>                

                <div className="terms_box">
                    <h4>Article. 10 (Obligation of Notification)</h4>
                    <p>The Company will notify the user through the notice at least 7 days before the addition, deletion or modification of the contents of the privacy policy.</p>
                </div>
                                
                <div className="terms_box">
                    <h4>This Privacy Policy will be effective from the date of September 27th, 2021.</h4>
                    <p>* This Terms & Conditions is translated version from Korean to English. Please refer to Korean version for more details.</p>
                </div>                
            </div>
        </div>
    </>
  );
}

export default Eng_privacy;
