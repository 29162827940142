import React, {useEffect, useState, useRef} from "react";
import {useParams} from 'react-router-dom';
import '../css/coupon.css';
import axios from "axios";
import base64 from 'base-64';
import utf8 from 'utf8';

function Coupon() {
    const params = useParams();
    let Languages = {
        coupon: 'Coupon',
        nickname: 'Nickname',
        couponcode: 'Redeem Coupon',
        done: 'Redeem',
        donemessage: 'Coupon Reward Delivered',
        inputname: 'Enter your nickname',
        inputcode: 'Enter the coupon number',
        message1: 'The coupon number is invalid.',
        message2: 'The coupon number has already been used.',
        message3: 'Coupon cannot be used simultaneously.',
        message4: 'This nickname does not exist.'
    }

    if (params.LangId == 'jp') {
        Languages = {
            ...Languages,
            coupon: 'クーポン',
            nickname: '名前',
            couponcode: 'クーポン登録',
            done: '登録',
            donemessage: '支払われました',
            inputname: 'ニックネームを 入力してください ',
            inputcode: 'シリアルコードを入力してください。',
            message1: ' 無効なシリアルコードです。',
            message2: 'すでに使用したシリアルコードです。',
            message3: ' 重複使用ができないクーポンです。',
            message4: '存在しないニックネームです。'
        }
    }
    if (params.LangId == 'kr') {
        Languages = {
            ...Languages,
            coupon: '쿠폰',
            nickname: '닉네임',
            couponcode: '쿠폰 등록',
            done: '등록',
            donemessage: '지급되었습니다',
            inputname: '닉네임을 입력하세요',
            inputcode: '쿠폰번호를 입력해주세요',
            message1: '유효하지 않은 쿠폰번호입니다.',
            message2: '이미 사용한 쿠폰번호입니다.',
            message3: '중복해서 사용할 수 없는 쿠폰입니다.',
            message4: '존재하지 않는 닉네임 입니다.'
        }
    }
    const [couponDone, setcouponDone] = useState(false);
    const [checkNickName, setCheckNickName] = useState(false);
    const [checkCoupone, setCheckCoupone] = useState(false);
    const [currentGameUrl, setCurrentGameUrl] = useState(null);
    const nickNameInputRef = useRef(null);
    const couponCodeInputRef = useRef(null);
    const [erNameText, setErNameText] = useState(null);
    const [erCouponText, setErCouponText] = useState(null);

    const [numbers, setNumbers] = useState('');

    const checkCode = async (name, coupon, callback) => {

        // console.log("name :" + name + " coupon :" + coupon );
        setCheckNickName(false);
        setCheckCoupone(false);
        const num = /^[A-Za-z0-9_]*$/;
        if (!num.test(coupon)) {
            setCheckCoupone(true);
            setErCouponText(Languages.message1);
        } else {

            const dataobj = {
                name: base64.encode(utf8.encode(name)),
                coupon: base64.encode(coupon),
            };

            axios.post(currentGameUrl, dataobj)
                .then(response => {

                    if (response.data.success) {
                        if (response.data.code == 0) {
                            // console.log("response.data.code : " + response.data.code);
                            setcouponDone(true);
                        }
                    } else {
                        console.log("aaaa" + response.data);
                        console.log(response.data.code);
                        if (response.data.code == -99) {
                            // console.log(response.data.code);
                            setCheckNickName(true);
                            setErNameText(Languages.message4);
                        }
                        if (response.data.code == 0) {
                            // console.log(response.data.code);
                            setcouponDone(true);
                        }
                        if (response.data.code == -1) {
                            // console.log(response.data.code);
                            setCheckCoupone(true);
                            setErCouponText(Languages.inputcode);
                        }
                        if (response.data.code == -2 || response.data.code == -3 || response.data.code == -4 || response.data.code == -7) {
                            // console.log(response.data.code);
                            setCheckCoupone(true);
                            setErCouponText(Languages.message1);
                        }
                        if (response.data.code == -5) {
                            // console.log(response.data.code);
                            setCheckCoupone(true);
                            setErCouponText(Languages.message2);
                        }
                        if (response.data.code == -6) {
                            // console.log(response.data.code);
                            setCheckCoupone(true);
                            setErCouponText(Languages.message3);
                        }
                    }
                })

        }

        // console.log("currentGameUrl : " + currentGameUrl);
        // const url = "/coupon";
        // const url = "https://sk.coupon.develop.actgames.co/coupon";

        /*  code	message
       0	SUCCESS
       -99	username:${username} not found. (요청한 유저를 DB에서 찾을수없는 경우)
       -1 	username:${username}, coupon:${coupon}, empty or length(${couponlen}) failed. (쿠폰 ID가 비어있는 경우)
       -2	username:${username}, coupon:${coupon}, itemid:${_rewardItem} not found. (쿠폰으로 요청한 아이템이 서버에서 없는 아이템인 경우)
       -3 	username:${username}, coupon:${coupon}, master(coupon id) not found. (쿠폰ID가 틀리거나 사용이 안되도록 off된 경우)
       -4	username:${username}, coupon:${coupon}, expired(beginUTC:${beginstr}, endUTC:${endstr}, curUTC:${curstr}) (쿠폰이 사용기간이 지난 경우)
       -5	username:${username}, coupon:${coupon}, type:${_cpnType}, used(UTC ${usedstr}) already. (다른 유저가 이미 사용한 쿠폰ID인 경우)
       -6	username:${username}, coupon:${coupon}, type:${_cpnType}, ${coupon_master} already. (자신이 이미 사용한 쿠폰ID인 경우)
       -7	username:${username}, coupon:${coupon}, master:${coupon_master} failed(${ret}). (사용한 쿠폰ID를 DB에 업데이트가 안되는 경우)  */

    };

    useEffect(() => {
        // console.log("params :"+ params.LangId);
        console.log("params :" + params.GameName);
        if (params.GameName === "pekopop"
            || params.GameName === "aggretsuko"
            || params.GameName === "bubspuzzleblast"
            || params.GameName === "mogmogplanet"
            || params.GameName === "kitty") {
            let targetGame = document.getElementsByClassName("CouponSection");
            targetGame[0].className += " " + params.GameName;
            targetGame[0].className += " " + params.LangId;
            if (params.GameName == "pekopop") {
                setCurrentGameUrl("https://peko2.coupon.actgames.co/coupon");
            }
            if (params.GameName == "mogmogplanet") {
                setCurrentGameUrl("https://sk.coupon.actgames.co/coupon");
            }
            if (params.GameName == "bubspuzzleblast") {
                setCurrentGameUrl("https://bb.coupon.actgames.co/coupon");
            }
            if (params.GameName == "aggretsuko") {
                // setCurrentGameUrl("https://qa.retsuko.actgames.co:8443/v1/api/coupon/use/web");
                setCurrentGameUrl("https://retsuko.actgames.co/v1/api/coupon/use/web");
            }
            if (params.GameName == "kitty") {
                setCurrentGameUrl("https://production.ap.n.1.kf.actgames.co/coupon");
            }
        }
    }, []);


    return (
        <>
            <div className="CouponSection">
                <div className="CouponSectionWrap">
                    <h6 className="CouponTitle">{Languages.coupon}</h6>
                    {couponDone ||
                        <div className="BaseWrap">
                            <div className="InputWrap">
                                <label htmlFor="UserName" className="UserName">{Languages.nickname}</label>
                                <input ref={nickNameInputRef} type="text" name="UserName"
                                       placeholder={Languages.inputname}></input>
                                {checkNickName && <div className="ERmessage">{erNameText}</div>}
                            </div>
                            <div className="InputWrap">
                                <label htmlFor="CouponCode" className="CouponCode">{Languages.couponcode}</label>
                                <input ref={couponCodeInputRef} type="text" name="CouponCode"
                                       placeholder={Languages.inputcode}></input>
                                {checkCoupone && <div className="ERmessage">{erCouponText}</div>}
                            </div>
                            <button className="ButtonWrap"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        const nickName = nickNameInputRef.current.value;
                                        const couponeCode = couponCodeInputRef.current.value;
                                        checkCode(nickName, couponeCode, (success) => {
                                            console.log("callback : checkCode " + success);
                                        });
                                    }}
                            >
                                <div className="LeftBG"></div>
                                <div className="CenterBG">
                                    <h5>{Languages.done}</h5>
                                </div>
                                <div className="RightBG"></div>
                            </button>
                        </div>
                    }
                    {couponDone &&
                        <div className="ResultWrap">
                            <img className="CheckImg"/>
                            <h4>{Languages.donemessage}</h4>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default Coupon;
