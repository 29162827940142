import React from "react";
import "../css/terms.css";

function Kor_privacy() {
  return (
    <>
      <div id="privacy" className="terms sub">
        <div className="container">
          <h3 className="sub_title">개인정보 처리 방침</h3>
          <hr className="star-primary wow zoomIn"></hr>
          <div className="terms_box">
            <p>
              ACT GAMES Co., Ltd.(이하 “회사”)는 이용자의 개인정보를 중요시하며,
              “개인정보 보호법”, “통신비밀보호법”, “전기통신사업법”, “위치정보의
              보호 및 이용 등에 관한 법률”, "정보통신망 이용촉진 및 정보보호"에
              관한 법률 등 관련 법규들을 준수하고 있습니다. 회사는 본
              개인정보처리방침을 통하여 이용자께서 제공하시는 개인정보가 어떠한
              용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
              취해지고 있는지 알려드립니다.
            </p>
          </div>

          <div className="terms_box">
            <p>본 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</p>
          </div>

          <div className="terms_box">
            <h4>제 1 조 수집하는 개인정보 항목 및 수집방법</h4>
            <ul>
              <li>
                회사는 각종 서비스의 제공을 위해 최초 서비스 가입 시 아래와 같은
                개인정보를 수집하고 있습니다.
                <ul className="sub_list">
                  <li>
                    - 이용자의 플랫폼사업자(Google Play, App Store 등)의
                    서비스에서 제공되는 고유식별번호, 이용자 이름 또는
                    별명(닉네임), 기기고유번호 (디바이스 아이디 또는 UUID) 등
                  </li>
                </ul>
              </li>
              <li>
                서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수
                있습니다.
                <ul className="sub_list">
                  <li>
                    - 이용자 상태정보, 방문 일시, 서비스 이용 기록, 불량 이용
                    기록, 이용자 기기 위치정보, IP정보 등
                  </li>
                </ul>
              </li>
              <li>
                고객지원 서비스 이용 시 아래와 같은 개인 정보를 수집하고
                있습니다.
                <ul className="sub_list">
                  <li>
                    - 이메일 주소, 닉네임, 이용자가 공개여부를 설정한 이름
                  </li>
                </ul>
              </li>
              <li>
                무료/유료 서비스 이용 과정에서 결제 등을 위하여 불가피하게
                필요한 경우 신용카드 정보, 통신사 정보, 구매내역 및 상품권 번호,
                기타 결제 관련 정보가 수집될 수 있으며, 고객의 유료콘텐츠 복구
                및 환불을 요청할 시 원활한 고객상담을 위하여 메일 주소 및
                구매내역 확인내용, 그리고 본인이 아닌 타인에 의해 결제된
                사실확인을 위해 실명과 가족관계 증명 등 추가적인 개인정보를
                수집할 수 있습니다.
              </li>
              <li>
                회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한
                개인정보(인종, 사상 및 신조, 정치적 성향이나 범죄기록, 의료정보
                등)를 수집하지 않습니다.
              </li>
              <li>
                회사는 다음과 같은 방법으로 개인정보를 수집합니다.
                <ul className="sub_list">
                  <li>
                    - 회사와 서비스 제공관련 제휴관계에 있는 플랫폼사업자를 통한
                    수집
                  </li>
                  <li>
                    - 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집
                  </li>
                  <li>
                    - 이용자가 회사가 제공하는 서비스를 이용함으로써 자동으로
                    수집
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 2 조 개인정보의 수집 및 이용목적</h4>
            <ul>
              <li>
                게임 기본 기능의 제공
                <ul className="sub_list">
                  <li>
                    - 이용자 계정의 고유식별번호를 통해 플랫폼사업자에게서 제공
                    받은 기기고유번호(디바이스 아이디 또는 UUID)를 수집하고
                    저장하여 이용자 계정의 정상 여부를 식별하기 위한 목적
                  </li>
                </ul>
              </li>
              <li>
                서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
                <ul className="sub_list">
                  <li>
                    - 무료 또는 유료콘텐츠의 제공, 구매를 통한 물품배송 또는
                    청구서 등 발송, 본인인증, 연령인증, 구매 및 요금 결제, 정산,
                    요금추심 목적
                  </li>
                </ul>
              </li>
              <li>
                이용자 관리
                <ul className="sub_list">
                  <li>
                    - 서비스 이용에 따른 본인확인, 게임서비스 제공과 관련한
                    이용자 확인, 개인식별, 불량이용자의 부정 이용방지와 비인가
                    사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 본인확인 및
                    분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항
                    전달 목적
                  </li>
                </ul>
              </li>
              <li>
                신규 서비스 개발 및 마케팅/광고에의 활용
                <ul className="sub_list">
                  <li>
                    - 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른
                    서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및
                    광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 이용자의
                    서비스이용에 대한 통계 목적
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 3 조 수집한 개인정보의 공유 및 제공</h4>
            <ul>
              <li>
                회사는 이용자들의 개인정보를 "개인정보의 수집목적 및
                이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의
                없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의
                개인정보를 외부에 공개하거나 제3자에게 제공하지 않습니다. 다만
                다음의 각 경우는 예외로 합니다.
                <ul className="sub_list">
                  <li>- 이용자가 사전에 동의한 경우</li>
                  <li>
                    - 통계작성 목적으로 특정 개인을 알아볼 수 없는 형태로
                    개인정보를 가공하여 제공하는 경우
                  </li>
                  <li>
                    - 기타 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진
                    절차와 방법에 따라 수사기관의 요구가 있는 경우
                  </li>
                </ul>
              </li>
              <li>
                회사가 다양한 서비스를 제공할 목적으로 사업 제휴업체에 제한된
                범위 내에서 이용자의 정보 제공이 필요한 경우, 회사는 이용자의
                정보를 제공받거나 공유하는 자가 누구이며, 제공 또는 공유되는
                개인정보항목이 무엇인지, 개인정보를 제공하거나 공유하는 목적이
                무엇인지 등에 대해 사전에 이용자로부터 동의를 받은 후에 이를
                제공 또는 공유합니다.
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 4 조 개인정보취급 위탁 및 국외 이전</h4>
            <ul>
              <li>
                회사는 원칙적으로 이용자 개인정보의 취급 등을 외부에 위탁하지
                않습니다.
              </li>
              <li>
                제1항에도 불구하고, 회사는 원활한 개인정보 업무처리를 위하여
                이용자의 개인정보 처리업무 중 일부를 다음과 같이 외부에 위탁할
                수 있으며, 위탁 시 관계 법령에 따라 개인정보가 안전하게 관리될
                수 있도록 합니다.
                <ul className="sub_list">
                  <li>수탁업체: 고객 서비스, 결제마켓, 결제 대행사</li>
                  <li>업무내용: 고객 문의 대응, 결제 대행 및 본인인증</li>
                  <li>기간: 서비스 탈퇴 또는 위탁 계약 종료 시</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 5 조 개인정보 보유 및 이용기간</h4>
            <p>
              이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
              달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
              이유로 명시한 기간 동안 보존합니다.
            </p>
            <ul>
              <li>
                회원이 탈퇴를 요청하거나 개인정보 담당자에게 파기를 요청한 경우,
                개인정보는 지체 없이 파기되며 복구되지 않도록 처리됩니다.단,
                관계법령의 규정에 따라 일정기간 정보를 보존할 필요가 있는 경우,
                회사는 관계 법령에서 지정된 기간 동안 개인 정보를 보관하며, 본
                정보를 다른 목적으로 이용하지 않습니다. 각 항목별 보존기간은
                4항을 따른다.
              </li>
              <li>
                이용자에 대하여 관계 법령 위반에 따른 수사•조사 등이 진행 중인
                경우 해당 수사•조사 종료 시까지 해당 이용자의 정보를 보존합니다.
              </li>
              <li>
                회사와 이용자 사이에 채권•채무 관계가 잔존하는 경우 해당
                채권•채무 관계의 정산이 완료 시까지 해당 이용자의 개인정보를
                보존합니다.
              </li>
              <li>
                상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
                규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한
                일정한 기간 동안 이용자 정보를 보관합니다. 이 경우 회사는
                보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와
                같습니다.
                <ul className="sub_list">
                  <li>
                    계약 또는 청약철회 등에 관한 기록 (보존 이유 : 전자상거래
                    등에서의 소비자보호에 관한 법률, 보존 기간 : 5년)
                  </li>
                  <li>
                    대금결제 및 재화 등의 공급에 관한 기록 (보존 이유 :
                    전자상거래 등에서의 소비자보호에 관한 법률, 보존 기간 : 5년)
                  </li>
                  <li>
                    소비자의 불만 또는 분쟁처리에 관한 기록 (보존 이유 :
                    전자상거래 등에서의 소비자보호에 관한 법률, 보존 기간 : 3년)
                  </li>
                  <li>
                    전기통신 일시, 개시•종료시간, 가입자번호, 사용도수,
                    발신기지국 위치추적자료에 관한 기록 (보존 이유 :
                    통신비밀보호법, 보존 기간 : 1년)
                  </li>
                  <li>
                    본인확인에 관한 기록 (보존 이유 : 정보통신망 이용촉진 및
                    정보보호 등에 관한 법률, 보존 기간 : 6개월)
                  </li>
                  <li>
                    표시•광고에 관한 기록 (보존 이유 : 전자상거래 등에서의
                    소비자보호에 관한 법률, 보존 기간 : 6개월)
                  </li>
                  <li>
                    방문에 관한 기록 (보존 이유 : 통신비밀보호법, 보존 기간 :
                    3개월)
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 6 조 개인정보 파기절차 및 방법</h4>
            <p>
              이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
              달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은
              다음과 같습니다.
            </p>
            <ul>
              <li>
                파기절차
                <ul className="sub_list">
                  <li>
                    이용자가 서비스 가입 등을 위해 입력한 정보는 목적이 달성된
                    후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한
                    정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된
                    후 파기됩니다.
                  </li>
                  <li>
                    개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의
                    다른 목적으로 이용되지 않습니다.
                  </li>
                </ul>
              </li>
              <li>
                파기방법
                <ul className="sub_list">
                  <li>
                    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                    파기합니다.
                  </li>
                  <li>
                    전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
                    기술적 방법을 사용하여 삭제합니다.
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 7 조 이용자 및 법정대리인의 권리와 그 행사방법</h4>
            <ul>
              <li>
                이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만
                14세 미만 아동인 이용자의 개인정보를 플랫폼사업자나 오픈마켓
                사업자를 통해 조회하거나 수정할 수 있으며 애플리케이션의
                로그아웃과 삭제를 통해 서비스 해지를 요청할 수 있습니다.
              </li>
              <li>
                이용자가 회사가 보유하고 있지 않은 자신의 개인정보를 확인하기
                위하여는, 회사가 서비스를 제공하는 플랫폼사업자 또는 오픈마켓
                사업자로부터 가입 내역 확인 및 개인정보 제공을 받아 이를 회사에
                기록된 정보와 대조하여 해당 이용자가 본인임을 확인할 수 있어야
                합니다.
              </li>
              <li>
                회사는 이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정이
                완료되기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
                또한, 잘못된 개인정보를 제3 자에게 이미 제공한 경우 회사는 지체
                없이 제3자에게 정정처리의 결과를 통지하여 정정이 이루어지도록
                합니다.
              </li>
              <li>
                회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
                그의 개인정보를 "제5조 개인정보의 보유 및 이용기간"에 명시된
                바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
                처리하고 있습니다.
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 8 조 개인정보의 기술적/관리적 보호 대책</h4>
            <p>
              회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난,
              누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
              기술적/관리적 대책을 강구하고 있습니다. 단, 회사는 이용자의
              개인정보가 전달되는 과정, 혹은 해커 등 제3자의 공격으로 인하여 위
              개인정보가 유실 또는 변질되는 부분에 대하여 100%의 보호를 보장할
              수 없습니다.
            </p>
            <ul>
              <li>
                해킹 등에 대비한 대책
                <ul className="sub_list">
                  <li>
                    - 회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의
                    개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고
                    있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고,
                    이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록
                    방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서
                    개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고
                    침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
                    있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
                    기술적 장치를 갖추려 노력하고 있습니다.
                  </li>
                </ul>
              </li>
              <li>
                취급 직원의 최소화 및 교육
                <ul className="sub_list">
                  <li>
                    - 회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고
                    이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고
                    있으며, 담당자에 대한 수시 교육을 통하여 에이시티게임즈
                    개인정보처리방침의 준수를 항상 강조하고 있습니다.
                  </li>
                </ul>
              </li>
              <li>
                내부관리계획의 수립 및 시행
                <ul className="sub_list">
                  <li>
                    - 회사는 개인정보의 안전한 처리를 위하여 내부관리계획을
                    수립하여 시행하고 있습니다.
                  </li>
                </ul>
              </li>
              <li>
                개인정보보호전담기구의 운영
                <ul className="sub_list">
                  <li>
                    - 회사는 사내 개인정보보호전담기구 등을 통하여
                    에이시티게임즈 개인정보처리방침의 이행사항 및 담당자의
                    준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로
                    잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의
                    또는 회사의 고의 또는 중대한 과실이 아닌 사유로 개인정보가
                    유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지
                    않습니다.
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 9 조 개인정보 관리책임자</h4>
            <p>
              회사는 고객님의 개인정보를 보호하고 개인정보와 관련된 불만을
              처리하기 위하여 아래와 같이 개인정보 관리책임자를 지정하고
              있습니다.
            </p>
            <ul>
              <li>
                [개인정보 관리책임자]
                <br />
                소속/직위: Director of Business
                <br />
                성명: Hyukmin Ma
                <br />
                전화번호: +82-70-4117-9408
                <br />
                E-mail: support@actgames.co.kr
              </li>
            </ul>
          </div>

          <div className="terms_box">
            <h4>제 10 조 고지의 의무</h4>
            <p>
              회사는 개인정보처리방침의 내용 추가, 삭제 또는 수정이 있을 시에는
              최소 7일 전에 공지사항을 통해 이용자에게 고지합니다.
            </p>
          </div>

          <div className="terms_box">
            <h4>본 개인정보처리방침은 2021년 09월 27일부터 시행됩니다.</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Kor_privacy;
