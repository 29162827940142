import React from "react";
import '../css/terms.css';

function Jpn_commerce() {
  return (
    <div className="TermsWrap">
        <div className="terms sub">
            <div className="container">
                <h3 className="sub_title">特定商取引法に基づく表記</h3>
                <hr className="star-primary wow zoomIn"></hr>
                
                <div className="terms_box">
                    <h4>販売業者</h4>
                    <p>ACT GAMES Co., Ltd.</p>
                </div>        
                
                <div className="terms_box">
                    <h4>販売責任者</h4>
                    <p>SungYoung Seo</p>
                </div>
                
                <div className="terms_box">
                    <h4>本社</h4>
                    <p>所在地：Acetwintower1cha,Digital-ro 285,Guro-gu,Seoul,Korea 08381
                    <br/>電話番号：82-70-4115-8450
                    <br/>※お問い合わせへのご回答は原則メールにておこなっております。
                    <br/>何卒ご協力をお願いいたします。
                    </p>
                </div>
                                
                <div className="terms_box">
                    <h4>問い合わせ先</h4>
                    <p>ゲーム内『お問い合わせ』からメールにてお問い合わせください。
                    <br/>お問い合わせ順に対応しておりますが、現在混雑しておりますので、回答まで5営業日ほどお時間をいただいております。
                    <br/>何卒ご了承くださいませ。
                    <br/><strong>営業時間</strong> 11:00～19:00（土日祝日除く）
                    </p>
                </div>
                
                <div className="terms_box">
                    <h4>苦情または相談窓口</h4>
                    <p>所在地：Acetwintower1cha,Digital-ro 285,Guro-gu,Seoul,Korea 08381
                    <br/>連絡先：support@actgames.co.kr
                    </p>
                </div>
                
                <div className="terms_box">
                    <h4>商品販売価格</h4>
                    <p>商品ごとに別途記載しております。消費税は内税として表示しております。</p>
                </div>
                
                <div className="terms_box">
                    <h4>利用上の注意</h4>
                    <p>ゴールドの払い戻しは行っておりません。
                    <br/>ご利用につきましては、「サービス内通貨ガイドライン」をご確認ください。</p>
                </div>
                
                <div className="terms_box">
                    <h4>商品販売価格以外の必要料金</h4>
                    <p>インターネット接続には別途、ご契約の通信事業者への通信料が発生します。
                    <br/>携帯電話からのご利用には、携帯通信料またはパケット通信料が発生します。</p>
                </div>
                
                <div className="terms_box">
                    <h4>商品受け渡し時期</h4>
                    <p>購入手続き完了後、即時にお引渡しいたします。</p>
                </div>
                
                <div className="terms_box">
                    <h4>商品代金の支払い時期および方法</h4>
                    <p><strong>＜ ゴールド購入の場合＞</strong>
                    <br/>クレジットカード：各カード会社引き落とし日となります。
                    <br/>携帯電話会社の料金回収：携帯電話料金と一緒に請求されます。
                    <br/>支払時期は携帯電話会社の規定によります。
                    </p>
                    <p><strong>＜ ゴールド以外のアイテム利用の場合＞</strong>
                    <br/>購入手続き完了後、即時にお引渡しいたします。
                    <br/>ゴールドによるお支払いになります。
                    </p>
                </div>
                <div className="terms_box">
                    <h4>商品の返品・交換</h4>
                    <p>商品の特性上、返品または交換は一切お受け出来ません。</p>
                </div>
                <div className="terms_box">
                    <h4>推奨環境・対応端末について</h4>
                    <p>Android 4.4 / iOS 9.0以降</p>
                </div>
                <div className="terms_box">
                    <h4>未成年者の購入について</h4>
                    <p>会員が未成年者の場合は、保護者の方の同意を得てからご購入ください。 その他、ゴールドについては、必ず、「サービス内通貨ガイドライン」の内容を確認し、同意をされた上でご購入ください。</p>
                </div>                
            </div>
        </div> 
    </div>
  );
}

export default Jpn_commerce;
