import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: {
      translation: { 
        games_zoids_name:"Zoids Wild Area",
        games_zoids_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.zoidscard&hl=en&gl=BR",
        games_zoids_iosUrl:"https://apps.apple.com/app/bubs-puzzle-blast/id1585641856",
        games_zoids_windowsUrl:"https://data.zoidswild.io/download/ZoidsWildArenaSetup.exe",
        games_zoids_apkUrl:"https://data.zoidswild.io/download/ZoidsWildArena.apk",
        games_zoids_thumbImgUrl:"",
        games_zoids_videoUrl:"https://data.actgames.co.kr/actgames/images/games/zoids/video.mp4",
        games_zoids_appImgUrl:"",
        games_zoids_Title:"ZOIDS WILD ARENA is a trading card game (TCG) \nthat incorporates units from the ZOIDS WILD franchise as cards.",
        games_zoids_Dec:"The game allows players to form a deck using 30 cards, which will allow them to battle against each other for supremacy. \nEvery card is upgradeable, giving everyone the chance to build a strong deck without relying on luck.\nTest your wits against others all over the world!",
        games_zoids_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_01.png",
        games_zoids_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_02.png",
        games_zoids_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_03.png",
        games_zoids_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_04.png",
        games_zoids_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_05.png",
        games_zoids_phoneImg6:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_06.png",
        games_zoids_phoneImg7:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_07.png",
        games_zoids_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/zoids/en/01.png",
        games_zoids_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/zoids/en/02.png",
        games_zoids_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/zoids/en/03.png",
        games_zoids_copyright:"© TOMY/ZW,TX © TOMY/ZW,MBS © TOMY © 2022 ACTGAMES CO., LTD. All rights reserved",
        games_zoids_Privacy:"https://terms.zoidswild.io/privacy-policy",
        games_zoids_Terms:"https://terms.zoidswild.io/",

        games_bubs_name:"Bub's Puzzle Blast",
        games_bubs_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.bubble&hl=en&gl=BR",
        games_bubs_iosUrl:"https://apps.apple.com/app/bubs-puzzle-blast/id1585641856",
        games_bubs_windowsUrl:"false",
        games_bubs_apkUrl:"false",
        games_bubs_thumbImgUrl:"",
        games_bubs_videoUrl:"https://data.actgames.co.kr/actgames/images/games/bubs/video.mp4",
        games_bubs_appImgUrl:"",
        games_bubs_Title:"The legendary masterpiece, \n the perfect combination of the nostalgic Bubble Bobble and Puzzle Blast!",
        games_bubs_Dec:"Defeat monsters with original characters from the original series.\nUse your brain and try to reach the top of the Rainbow Tower with colorful bubbles moving in the air.\nDestroy monsters and obstacles by popping bubbles of the same color and making Special Bubbles!\nRescue your enchanted friends and join forces to build your own Miniroon Town.\n\n Are you ready to pop bubbles?",
        games_bubs_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/bubs/en/phone_01.png",
        games_bubs_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/bubs/en/phone_02.png",
        games_bubs_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/bubs/en/phone_03.png",
        games_bubs_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/01.webp",
        games_bubs_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/02.webp",
        games_bubs_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/03.webp",
        games_bubs_copyright:"©TAITO 1986,2021",
        games_bubs_Privacy:"",
        games_bubs_Terms:"",

        games_agg_name:"Aggretsuko",
        games_agg_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.aggretsuko&hl=en&gl=US",
        games_agg_iosUrl:"https://apps.apple.com/us/app/aggretsuko-sanrio-puzzle-game/id1516629235",
        games_agg_windowsUrl:"false",
        games_agg_apkUrl:"false",
        games_agg_thumbImgUrl:"",
        games_agg_videoUrl:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/video.mp4",
        games_agg_appImgUrl:"",
        games_agg_Title:"Aggretsuko is a story of Retsuko,\na red panda who works in the Accounting Department of \nCarrier Man Trading Co., Ltd.",
        games_agg_Dec:"She dreamt of enjoying the glamorous life as a career woman working at a commercial company,\nbut in reality, her bosses bombard her with tasks and her coworkers push her around.\nWhen the stress from her evil boss and foolish behaviors of her coworkers become too much to handle,\nshe heads to the karaoke after work and starts screaming death metal to vent out her anger.",
        games_agg_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/en/phone_01.png",
        games_agg_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/en/phone_02.png",
        games_agg_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/en/phone_03.png",
        games_agg_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/en/phone_04.png",
        games_agg_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/en/01.webp",
        games_agg_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/en/02.webp",
        games_agg_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/en/03.webp",
        games_agg_copyright:"ⓒ2015, 2020 SANRIO CO., LTD.  S/T·F (Appl.No.KAR20003)",
        games_agg_Privacy:"",
        games_agg_Terms:"",

        games_peko_name:"PEKO POP",
        games_peko_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.peko&hl=en",
        games_peko_iosUrl:"https://apps.apple.com/app/peko-pop-match-3-puzzle/id1542304039",
        games_peko_windowsUrl:"false",
        games_peko_apkUrl:"false",
        games_peko_thumbImgUrl:"",
        games_peko_videoUrl:"https://data.actgames.co.kr/actgames/images/games/pekopop/video.mp4",
        games_peko_appImgUrl:"",
        games_peko_Title:"Everyone loves 🍭Peko🍭Peko,\n as sweet as 🍬Milky Candy🍬, appears in the puzzle game!🍧🍧🍧",
        games_peko_Dec:"Move your fingers around the sweet & sour 🍌Fruits🥝 Match 🍓🍓🍓 \n3 or more of them to POP! Mind-popping missions await you at every stage🍧\nUse various items to clear the stages sweeter & easier🍡\n\nIf you love Peko and enjoy puzzle games, you must try it!😋",
        games_peko_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/phone_01.png",
        games_peko_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/phone_02.png",
        games_peko_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/phone_03.png",
        games_peko_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/phone_04.png",
        games_peko_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/phone_05.png",
        games_peko_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/01.webp",
        games_peko_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/02.webp",
        games_peko_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/03.webp",
        games_peko_copyright:"ⓒ2020 FUJIYA CO.,LTD.",
        games_peko_Privacy:"",
        games_peko_Terms:"",

        games_mog_name:"MogMog Planet",
        games_mog_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.shaku&hl=en&gl=US",
        games_mog_iosUrl:"https://apps.apple.com/us/app/mogmog-planet/id1591891005",
        games_mog_windowsUrl:"false",
        games_mog_apkUrl:"false",
        games_mog_thumbImgUrl:"",
        games_mog_videoUrl:"https://data.actgames.co.kr/actgames/images/games/mogmog/video.mp4",
        games_mog_appImgUrl:"",
        games_mog_Title:"「MOG MOG PLANET」,\nknown for its plushes, is now a puzzle game!\nCreate your planet and interact with cute animal friends!",
        games_mog_Dec:"It's a simple match 3 puzzle that anyone can pick up and play!\nClear puzzle stages to obtain Stars and use them to create your very own MOG MOG PLANET!\nThe more planets you own, the more animal friends you will unlock.",
        games_mog_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/mogmog/en/phone_01.png",
        games_mog_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/mogmog/en/phone_02.png",
        games_mog_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/mogmog/en/phone_03.png",
        games_mog_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/mogmog/en/phone_04.png",
        games_mog_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/mogmog/en/01.webp",
        games_mog_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/mogmog/en/02.webp",
        games_mog_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/mogmog/en/03.webp",
        games_mog_copyright:"©Panda's ana ©HIVE Co., Ltd. ©ACT GAMES Co., Ltd. All Rights Reserved.",
        games_mog_Privacy:"",
        games_mog_Terms:"",

        games_kitty_name:"PEKO POP",
        games_kitty_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.kitty&hl=en",
        games_kitty_iosUrl:"https://apps.apple.com/app/kitty-pop-match-3-puzzle/id1542304039",
        games_kitty_windowsUrl:"false",
        games_kitty_apkUrl:"false",
        games_kitty_thumbImgUrl:"",
        games_kitty_videoUrl:"https://data.actgames.co.kr/actgames/images/games/kity/video.mp4",
        games_kitty_appImgUrl:"",
        games_kitty_Title:"산리오 친구들과 귀엽게 머지! 황폐해진 쇼핑 타운을 다시 반짝이는 꿈의 공간으로!\n",
        games_kitty_Dec:"친구들의 발길이 끊긴 채 황폐해진 쇼핑 타운을 보며 슬퍼하는 헬로키티와 쿠로미, 이곳은 더 이상 모두를 위한 꿈의 공간이 아니에요.\n" +
            "하지만 이대로 포기할 수는 없죠! 귀여운 산리오 친구들과 퍼즐을 머지하고, 특별한 나만의 쇼핑 타운을 만드세요.\n" +
            "쇼핑 타운에 가득했던 반짝이는 꿈과 웃음을 되찾아 주세요! 산리오 친구들과 함께라면 불가능은 없답니다💪",
        games_kitty_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/kity/en/phone_01.png",
        games_kitty_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/kity/en/phone_02.png",
        games_kitty_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/kity/en/phone_03.png",
        games_kitty_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/kity/en/phone_04.png",
        games_kitty_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/kity/en/phone_05.png",
        games_kitty_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/kity/en/01.webp",
        games_kitty_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/kity/en/02.webp",
        games_kitty_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/kity/en/03.webp",
        games_kitty_copyright:"ⓒ2024 SANRIO CO., LTD.",
        games_kitty_Privacy:"",
        games_kitty_Terms:"",


        home_title1: "We reach out to the world\nthrough culture and technology.",
        home_title2: "원작의 재미가 게임 콘텐츠로 재 탄생되도록 연구와 노력을 멈추지 않습니다.",
        home_title3: "원작과 게임 콘텐츠가 함께 성장할 수 있는 목표를 추구합니다.",
        news : "News",
        ViewMore: "View More",
        games:"GAMES",
        home_games_dec:"We want to bring joy to as many people as possible with our diverse game content. ",
        seegames:"View Game Titles",
        careers:"Careers",
        home_careers_dec:"We strive to deliver fun and excitement. Let's do it together!",
        seecareers:"View Jobs",


        about_header_text:"We bring vitality to the fun of the original IP.",
        about_actgames_intro:"ACT GAMES",
        about_actgames_intro_dec:"ACT GAMES has released several IP games since its establishment in 2019.\nOur dream is to continue to bring the fun and excitement of globally renowned IPs to life \nthrough game content and create great games that people can play for a long time and remember forever.",
        about_actgames_intro_more:"Learn more about us and our history below.",
        about_actgames_history:"History",
        about_actgames_history2024_12:"'Kitty's Friend' Coming Soon",
        about_actgames_history2023_07:"'ZOIDS WILD ARENA'  Launched",
        about_actgames_history2022_01:"'Mogmog Planet : Match 3 Puzzle' Launched",
        about_actgames_history2021_12:"'Bub's Puzzle Blast' Launched",
        about_actgames_history2020_12:"'Peko Pop : Match 3 Puzzle' Launched",
        about_actgames_history2020_07:"'Aggretsuko : The Short-Timer Strikes Back' Launched",
        about_actgames_history2019_05:"ACT GAMES Founded",
        about_actgames_Advisory:"Advisory",
        about_actgames_Contact:"Contact",
        about_actgames_Contact_dec:"Send a request or comment to actgames at",
        Recruiting:"Career",
        Business:"Business",
        Others:"Others",
        Ceo:"CEO : SEI JIN JUNG",
        OfficeAddress:"Office : 3F, 12, Seolleung-ro 131-gil, Gangnam-gu, Seoul, Republic of Korea",


        careers_header_Text:"Work with us!",
        careers_weare_title:"We are",
        careers_weare_Dec:"Web3 시장을 개척하고 선점하는 도전을 해내고 있습니다.이를 위해 탄탄한 전문가들이 모여 새로운 것들을 시도하고 솔루션을 찾아내는 조직입니다",
        careers_weare_More:"아래와 같은 역량과 태도를 갖춘 분이라면 에이시티게임즈와 좋은 시너지를 낼 수 있을거에요!",
        careers_weare_c1:"Professionalism",
        careers_weare_c1_dec:"스스로 최고의 기준을 설정하고 달성하는 전문성",
        careers_weare_c2:"Enthusiasm & Problem Solving",
        careers_weare_c2_dec:"문제를 정의하고 돌파하는 집요함과 열정",
        careers_weare_c3:"Communication Collaboration",
        careers_weare_c3_dec:"정확하게 소통하고 넓게 공유하며 동료들과 효과적으로 협업하는 태도",
        careers_welfare:"Welfare",
        careers_welfare_dec:"일의 본질에 집중하고 일상의 즐거움을 위한 문화를 만들어 나갑니다",
        careers_welfare_s1_title:"Time & Refresh",
        careers_welfare_s1_li1:"10시~11시 사이 유연출근제",
        careers_welfare_s1_li2:"매년 3개의 보너스 휴가 지급",
        careers_welfare_s1_li3:"장기근속 휴가 및 휴가비 지원",
        careers_welfare_s1_li4:"반반차 제도",
        careers_welfare_s2_title:"Work Tools",
        careers_welfare_s2_li1:"고사양 업무 장비 지원",
        careers_welfare_s2_li2:"도서 및 교육 지원",
        careers_welfare_s2_li3:"",
        careers_welfare_s2_li4:"",
        careers_welfare_s3_title:"Snack & Gift",
        careers_welfare_s3_li1:"생밀 및 명절 선물제공",
        careers_welfare_s3_li2:"경조 휴가 및 경조금 지원",
        careers_welfare_s3_li3:"점심 케이터링, 머피 및 간식 제공",
        careers_welfare_s3_li4:"",
        careers_list_title:"Careers",

        footer_termsLinkURL:"/eng/sub/provision.php",
        footer_PrivacyLinkURL:"/eng/sub/privacy.php",
        footer_GamePolicyLinkURL:"/eng/sub/service.php",
        footer_googleLinkURL:"https://play.google.com/store/apps/dev?id=4641796618685466052&hl=en&gl=US",
        footer_appleLinkURL:"https://apps.apple.com/developer/act-games-co-ltd/id1585641858"
      }
    },
    kr: {
      translation: {


        games_zoids_name:"ZOIDS WILD ARENA",
        games_zoids_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.zoidscard&hl=en&gl=BR",
        games_zoids_iosUrl:"https://apps.apple.com/app/bubs-puzzle-blast/id1585641856",
        games_zoids_windowsUrl:"https://data.zoidswild.io/download/ZoidsWildArenaSetup.exe",
        games_zoids_apkUrl:"https://data.zoidswild.io/download/ZoidsWildArena.apk",
        games_zoids_thumbImgUrl:"",
        games_zoids_videoUrl:"https://data.actgames.co.kr/actgames/images/games/zoids/video.mp4",
        games_zoids_appImgUrl:"",
        games_zoids_Title:"ZOIDS WILD ARENA는 'ZOIDS WILD'에 등장하는 \n유닛을 카드로 구현한 트레이딩 카드 게임(TCG)입니다. ",
        games_zoids_Dec:"이 게임에서 플레이어는 30장의 카드로 덱을 구성하여 전투를 벌일 수 있습니다. \n모든 카드는 업그레이드할 수 있으므로 누구나 운에 의존하지 않고 강력한 덱을 구성할 수 있습니다. \n전 세계의 다른 플레이어들과 실력을 겨뤄보세요! (국내 서비스 미정)",
        games_zoids_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_01.png",
        games_zoids_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_02.png",
        games_zoids_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_03.png",
        games_zoids_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_04.png",
        games_zoids_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_05.png",
        games_zoids_phoneImg6:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_06.png",
        games_zoids_phoneImg7:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_07.png",
        games_zoids_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/zoids/en/01.png",
        games_zoids_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/zoids/en/02.png",
        games_zoids_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/zoids/en/03.png",
        games_zoids_copyright:"© TOMY/ZW,TX © TOMY/ZW,MBS © TOMY © 2022 ACTGAMES CO., LTD. All rights reserved",
        games_zoids_Privacy:"https://terms.zoidswild.io/privacy-policy",
        games_zoids_Terms:"https://terms.zoidswild.io/",

        games_bubs_name:"버블스 퍼즐 블래스트",
        games_bubs_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.bubble&hl=kr&gl=US",
        games_bubs_iosUrl:"https://apps.apple.com/kr/app/bubs-puzzle-blast/id1585641856",
        games_bubs_windowsUrl:"false",
        games_bubs_apkUrl:"false",
        games_bubs_thumbImgUrl:"",
        games_bubs_videoUrl:"https://data.actgames.co.kr/actgames/images/games/bubs/video.mp4",
        games_bubs_appImgUrl:"",
        games_bubs_Title:"버블보블 세계관을 그대로 반영한\n[Bub's Puzzle Blast !]",
        games_bubs_Dec:"전설적인 명작, 추억의 버블보블과 블래스트의 완벽한 조화!\n원작 시리즈에서 볼 수 있었던 오리지널 캐릭터와 함께 몬스터들을 물리치세요.\n두뇌를 총동원하여 화려하게 공중으로 이동하는 버블로 마법의 탑 정상을 향해 도전해보세요.\n같은 색 버블을 터트리고 특수버블을 만들어 몬스터와 장애물을 파괴하고\n마법에 걸린 친구들을 구출하여 나만의 미니룬 타운을 건설하세요.\n\n지금 플레이해 보세요!",
        games_bubs_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/phone_01.png",
        games_bubs_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/phone_02.png",
        games_bubs_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/phone_03.png",
        games_bubs_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/01.webp",
        games_bubs_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/02.webp",
        games_bubs_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/bubs/kr/03.webp",
        games_bubs_copyright:"©TAITO 1986,2021",
        games_bubs_Privacy:"",
        games_bubs_Terms:"",

        games_agg_name:"어그레츠코 - 월급쟁이의 역습",
        games_agg_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.aggretsuko&hl=kr&gl=US",
        games_agg_iosUrl:"https://apps.apple.com/kr/app/aggretsuko-sanrio-puzzle-game/id1516629235",
        games_agg_windowsUrl:"false",
        games_agg_apkUrl:"false",
        games_agg_thumbImgUrl:"",
        games_agg_videoUrl:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/video.mp4",
        games_agg_appImgUrl:"",
        games_agg_Title:"Netflix애니메이션으로 인기있는 산리오의 이색적인 캐릭터\n「어그레츠코」가 퍼즐게임으로 등장！",
        games_agg_Dec:"레츠코는 캐러리맨 상사의 경리부에서 일하는 레서팬더.\n동경하던 대기업에서 화려한 커리어 우먼 라이프를 기대했지만, 현실은 어처구니 없는 상사와 제멋대로인 동료에게 휘둘리는 하루하루.\n악덕 상사와 동료들에 치여 쌓인 스트레스를, 퇴근길에 홀로 노래방에 가서 데스메탈로 풀고 애써 힘찬 내일을 기약하는,\n어쩌면 '나'와 많이 닮은 삶을 살고 있는 친구.",
        games_agg_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/kr/phone_01.png",
        games_agg_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/kr/phone_02.png",
        games_agg_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/kr/phone_03.png",
        games_agg_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/kr/01.webp",
        games_agg_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/kr/02.webp",
        games_agg_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/kr/03.webp",
        games_agg_copyright:"ⓒ2015, 2020 SANRIO CO., LTD.  S/T·F (Appl.No.KAR20003)",
        games_agg_Privacy:"",
        games_agg_Terms:"",

        games_peko_name:"PEKO POP",
        games_peko_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.peko&hl=kr",
        games_peko_iosUrl:"https://apps.apple.com/kr/app/peko-pop-match-3-puzzle/id1542304039",
        games_peko_windowsUrl:"false",
        games_peko_apkUrl:"false",
        games_peko_thumbImgUrl:"",
        games_peko_videoUrl:"https://data.actgames.co.kr/actgames/images/games/pekopop/video.mp4",
        games_peko_appImgUrl:"",
        games_peko_Title:"누구나 사랑하는 🍭페코🍭\n🍬밀키 캔디🍬 달콤한 그녀가 퍼즐게임으로 등장!🍧🍧🍧",
        games_peko_Dec:"달콤새콤 🍌과일🥝을 손가락으로 움직여서 같은색 🍓🍓🍓과일을 3개 이상 연결해봐요!\n 스테이지마다 색다른 미션이 당신을 기다립니다!🍧 다양한 아이템으로 쉽고 상쾌하게 스테이지를 클리어 해 보자구요!\n\n🍡페코를 좋아하고 재밌는 퍼즐게임을 좋아하신다면 반드시 해보세요!",
        games_peko_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/pekopop/kr/phone_01.png",
        games_peko_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/pekopop/kr/phone_02.png",
        games_peko_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/pekopop/kr/phone_03.png",
        games_peko_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/pekopop/kr/phone_04.png",
        games_peko_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/pekopop/kr/phone_05.png",
        games_peko_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/01.webp",
        games_peko_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/02.webp",
        games_peko_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/pekopop/en/03.webp",
        games_peko_copyright:"ⓒ2020 FUJIYA CO.,LTD.",
        games_peko_Privacy:"",
        games_peko_Terms:"",

        games_mog_name:"MogMog Planet",
        games_mog_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.shaku&hl=kr&gl=US",
        games_mog_iosUrl:"https://apps.apple.com/kr/app/mogmog-planet/id1591891005",
        games_mog_windowsUrl:"false",
        games_mog_apkUrl:"false",
        games_mog_thumbImgUrl:"",
        games_mog_videoUrl:"https://data.actgames.co.kr/actgames/images/games/mogmog/video.mp4",
        games_mog_appImgUrl:"",
        games_mog_Title:"뽑기인형으로 유명한「모구모구 플래닛」이 퍼즐게임으로 등장!\n나만의 플래닛을 만들고, 개성 넘치는 동물들의 생활을 지켜봐 주세요!",
        games_mog_Dec:"간단한 3매치 퍼즐방식으로 누구라도 쉽게 플레이 할 수 있습니다!\n퍼즐 스테이지를 클리어하여 별을 획득하고, 획득한 별을 사용하여 자신만의 모구모구 플래닛을 만들어 보세요!\n보유하는 플래닛이 늘어날수록 개성넘치는 동물들도 다양해 집니다.",
        games_mog_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/mogmog/kr/phone_01.png",
        games_mog_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/mogmog/kr/phone_02.png",
        games_mog_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/mogmog/kr/phone_03.png",
        games_mog_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/mogmog/kr/phone_04.png",
        games_mog_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/mogmog/kr/01.webp",
        games_mog_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/mogmog/kr/02.webp",
        games_mog_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/mogmog/kr/03.webp",
        games_mog_copyright:"©Panda's ana ©HIVE Co., Ltd. ©ACT GAMES Co., Ltd. All Rights Reserved.",
        games_mog_Privacy:"",
        games_mog_Terms:"",

        games_kitty_name:"키티와 친구들",
        games_kitty_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.kitty&hl=kr",
        games_kitty_iosUrl:"https://apps.apple.com/kr/app/kitty-pop-match-3-puzzle/id1542304039",
        games_kitty_windowsUrl:"false",
        games_kitty_apkUrl:"false",
        games_kitty_thumbImgUrl:"",
        games_kitty_videoUrl:"https://data.actgames.co.kr/actgames/images/games/kity/video.mp4",
        games_kitty_appImgUrl:"",
        games_kitty_Title:"산리오 친구들과 귀엽게 머지! 황폐해진 쇼핑 타운을 다시 반짝이는 꿈의 공간으로!\n",
        games_kitty_Dec:"친구들의 발길이 끊긴 채 황폐해진 쇼핑 타운을 보며 슬퍼하는 헬로키티와 쿠로미, 이곳은 더 이상 모두를 위한 꿈의 공간이 아니에요.\n" +
            "하지만 이대로 포기할 수는 없죠! 귀여운 산리오 친구들과 퍼즐을 머지하고, 특별한 나만의 쇼핑 타운을 만드세요.\n" +
            "쇼핑 타운에 가득했던 반짝이는 꿈과 웃음을 되찾아 주세요! 산리오 친구들과 함께라면 불가능은 없답니다💪",
        games_kitty_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/kity/kr/phone_01.png",
        games_kitty_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/kity/kr/phone_02.png",
        games_kitty_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/kity/kr/phone_03.png",
        games_kitty_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/kity/kr/phone_04.png",
        games_kitty_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/kity/kr/phone_05.png",
        games_kitty_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/kity/en/01.webp",
        games_kitty_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/kity/en/02.webp",
        games_kitty_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/kity/en/03.webp",
        games_kitty_copyright:"ⓒ2024 SANRIO CO., LTD.",
        games_kitty_Privacy:"",
        games_kitty_Terms:"",

        home_title1: "문화와 기술을 통해 세계로 나아갑니다.",
        home_title2: "원작의 재미가 게임 콘텐츠로 재 탄생되도록 연구와 노력을 멈추지 않습니다.",
        home_title3: "원작과 게임 콘텐츠가 함께 성장할 수 있는 목표를 추구합니다.",
        news : "뉴스",
        ViewMore: "더보기",
        games:"GAMES",
        home_games_dec:"다양한 게임 콘텐츠로 많은 사람들에게 즐거움을 전달하고자 합니다.",
        seegames:"게임타이틀 보기",
        careers:"채용",
        home_careers_dec:"우리는 재미와 감동을 전달하기 위해 노력하고 있습니다. 함께 만들어요! ",
        seecareers:"채용 보기",



        about_header_text:"원작 IP의 재미에 활력을 불어넣습니다.",
        about_actgames_intro:"ACT GAMES",
        about_actgames_intro_dec:"ACT GAMES는 2019년 출시 (설립)이후,  여러 IP 게임을 출시했습니다.\n우리의 꿈은 앞으로도 글로벌 유명 IP 의 재미와 감동을 게임 콘텐츠로 재탄생시키고 많은 사람들이 오랫동안 플레이하고 영원히 기억할 수 있는 훌륭한 게임을 만드는 것입니다.",
        about_actgames_intro_more:"아래에서 당사와 당사의 역사에 대해 자세히 알아보세요.",
        about_actgames_history:"History",
        about_actgames_history2024_12:"키티와 친구들 출시 예정",
        about_actgames_history2023_07:"ZOIDS WILD ARENA 출시",
        about_actgames_history2022_01:"Mogmog Planet : Match 3 Puzzle 출시",
        about_actgames_history2021_12:"Bub's Puzzle Blast 출시 ",
        about_actgames_history2020_12:"Peko Pop : Match 3 Puzzle 출시",
        about_actgames_history2020_07:"어그레츠코 : 월급쟁이의 역습 출시",
        about_actgames_history2019_05:"ACT GAMES 설립",
        about_actgames_Advisory:"자문",
        about_actgames_Contact:"Contact",
        about_actgames_Contact_dec:"actgames에 요청이나 의견을 보내주세요.",
        Recruiting:"직업",
        Business:"비즈니스",
        Others:"기타",
        Ceo:"CEO : SEI JIN JUNG",
        OfficeAddress:"Office : 3F, 12, Seolleung-ro 131-gil, Gangnam-gu, Seoul, Republic of Korea",


        careers_header_Text:"Work with us!",
        careers_weare_title:"We are",
        careers_weare_Dec:"Web3 시장을 개척하고 선점하는 도전을 해내고 있습니다.이를 위해 탄탄한 전문가들이 모여 새로운 것들을 시도하고 솔루션을 찾아내는 조직입니다",
        careers_weare_More:"아래와 같은 역량과 태도를 갖춘 분이라면 에이시티게임즈와 좋은 시너지를 낼 수 있을거에요!",
        careers_weare_c1:"Professionalism",
        careers_weare_c1_dec:"스스로 최고의 기준을 설정하고 달성하는 전문성",
        careers_weare_c2:"Enthusiasm & Problem Solving",
        careers_weare_c2_dec:"문제를 정의하고 돌파하는 집요함과 열정",
        careers_weare_c3:"Communication Collaboration",
        careers_weare_c3_dec:"정확하게 소통하고 넓게 공유하며 동료들과 효과적으로 협업하는 태도",
        careers_welfare:"Welfare",
        careers_welfare_dec:"일의 본질에 집중하고 일상의 즐거움을 위한 문화를 만들어 나갑니다",
        careers_welfare_s1_title:"Time & Refresh",
        careers_welfare_s1_li1:"10시~11시 사이 유연출근제",
        careers_welfare_s1_li2:"매년 3개의 보너스 휴가 지급",
        careers_welfare_s1_li3:"장기근속 휴가 및 휴가비 지원",
        careers_welfare_s1_li4:"반반차 제도",
        careers_welfare_s2_title:"Work Tools",
        careers_welfare_s2_li1:"고사양 업무 장비 지원",
        careers_welfare_s2_li2:"도서 및 교육 지원",
        careers_welfare_s2_li3:"",
        careers_welfare_s2_li4:"",
        careers_welfare_s3_title:"Snack & Gift",
        careers_welfare_s3_li1:"생밀 및 명절 선물제공",
        careers_welfare_s3_li2:"경조 휴가 및 경조금 지원",
        careers_welfare_s3_li3:"점심 케이터링, 머피 및 간식 제공",
        careers_welfare_s3_li4:"",
        careers_list_title:"Careers",

        footer_termsLinkURL:"/kor/sub/provision.php",
        footer_PrivacyLinkURL:"/kor/sub/privacy.php",
        footer_GamePolicyLinkURL:"/kor/sub/service.php",
        footer_googleLinkURL:"https://play.google.com/store/apps/dev?id=4641796618685466052&hl=kr&gl=US",
        footer_appleLinkURL:"https://apps.apple.com/kr/developer/act-games-co-ltd/id1585641858"

        
      }
    },
    jp: {
      translation: {


        games_zoids_name:"Zoids Wild Area",
        games_zoids_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.zoidscard&hl=en&gl=BR",
        games_zoids_iosUrl:"https://apps.apple.com/app/bubs-puzzle-blast/id1585641856",
        games_zoids_windowsUrl:"https://data.zoidswild.io/download/ZoidsWildArenaSetup.exe",
        games_zoids_apkUrl:"https://data.zoidswild.io/download/ZoidsWildArena.apk",
        games_zoids_thumbImgUrl:"",
        games_zoids_videoUrl:"https://data.actgames.co.kr/actgames/images/games/zoids/video.mp4",
        games_zoids_appImgUrl:"",
        games_zoids_Title:"ZOIDS WILD ARENAは、「ZOIDS WILD」に登場する\nユニットをカード化したトレーディングカードゲーム（TCG）",
        games_zoids_Dec:"プレイヤーは30枚のカードでデッキを組み、対戦することが出来ます。\nどのカードもアップグレード可能で、運に頼らず強力なデッキを組むチャンスがある。\n世界中のプレイヤーと知恵比べをしよう！(日本サービス未定）",
        games_zoids_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_01.png",
        games_zoids_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_02.png",
        games_zoids_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_03.png",
        games_zoids_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_04.png",
        games_zoids_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_05.png",
        games_zoids_phoneImg6:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_06.png",
        games_zoids_phoneImg7:"https://data.actgames.co.kr/actgames/images/games/zoids/en/phone_07.png",
        games_zoids_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/zoids/en/01.png",
        games_zoids_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/zoids/en/02.png",
        games_zoids_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/zoids/en/03.png",
        games_zoids_copyright:"© TOMY/ZW,TX © TOMY/ZW,MBS © TOMY © 2022 ACTGAMES CO., LTD. All rights reserved",
        games_zoids_Privacy:"https://terms.zoidswild.io/privacy-policy",
        games_zoids_Terms:"https://terms.zoidswild.io/",

        games_bubs_name:"Bub’s Puzzle Blast!",
        games_bubs_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.bubble&hl=ja&gl=US",
        games_bubs_iosUrl:"https://apps.apple.com/jp/app/bubs-puzzle-blast/id1585641856",
        games_bubs_windowsUrl:"false",
        games_bubs_apkUrl:"false",
        games_bubs_thumbImgUrl:"",
        games_bubs_videoUrl:"https://data.actgames.co.kr/actgames/images/games/bubs/video.mp4",
        games_bubs_appImgUrl:"",
        games_bubs_Title:"バブルボブルの世界に新しいブラストパズルがやってきた！\nBub's Puzzle Blast!",
        games_bubs_Dec:"タイトーの名作「バブルボブル」とブラストパズルが完璧なハーモニーで融合して登場！\nオリジナルの「バブルボブル」シリーズに登場したモンスターたちをパズルを解きながら倒そう！\n魔法に掛かったミニルンたちを助けだし、自分だけのミニルンタウンを作れるよ！\n\nレインボータワーの最上階を目指してパズルに挑戦しよう！",
        games_bubs_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/bubs/jp/phone_01.png",
        games_bubs_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/bubs/jp/phone_02.png",
        games_bubs_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/bubs/jp/phone_03.png",
        games_bubs_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/bubs/jp/01.webp",
        games_bubs_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/bubs/jp/02.webp",
        games_bubs_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/bubs/jp/03.webp",
        games_bubs_copyright:"©TAITO 1986,2021",
        games_bubs_Privacy:"",
        games_bubs_Terms:"",

        games_agg_name:"アグレッシブ烈子 - 腰掛けの逆襲",
        games_agg_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.aggretsuko&hl=ja&gl=US",
        games_agg_iosUrl:"https://apps.apple.com/jp/app/aggretsuko-sanrio-puzzle-game/id1516629235",
        games_agg_windowsUrl:"false",
        games_agg_apkUrl:"false",
        games_agg_thumbImgUrl:"",
        games_agg_videoUrl:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/video.mp4",
        games_agg_appImgUrl:"",
        games_agg_Title:"Netflixアニメで人気のサンリオ異色のキャラクター\n「アグレッシブ烈子」がパズルゲームで登場！",
        games_agg_Dec:"烈子はキャラリーマン商事の経理部で働くレッサーパンダ。\n憧れの商社で華の丸の内OL生活と思いきや、現実は無茶な仕事を押し付けてくる上司や自分勝手な同僚に振り回される毎日。\n上司からの仕事や同僚の愚行に不満がたまり、言い返せないが怒りがたまると我慢できず、\n仕事帰りに一人カラオケに行き、デスボイスでメタルを歌うのがストレス発散方法。",
        games_agg_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/jp/phone_01.png",
        games_agg_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/jp/phone_02.png",
        games_agg_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/jp/phone_03.png",
        games_agg_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/jp/01.webp",
        games_agg_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/jp/02.webp",
        games_agg_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/aggretsuko/jp/03.webp",
        games_agg_copyright:"ⓒ2015, 2020 SANRIO CO., LTD.  S/T·F (Appl.No.KAR20003)",
        games_agg_Privacy:"",
        games_agg_Terms:"",

        games_peko_name:"PEKO POP",
        games_peko_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.peko&hl=en",
        games_peko_iosUrl:"https://apps.apple.com/app/peko-pop-match-3-puzzle/id1542304039",
        games_peko_windowsUrl:"false",
        games_peko_apkUrl:"false",
        games_peko_thumbImgUrl:"",
        games_peko_videoUrl:"https://data.actgames.co.kr/actgames/images/games/pekopop/video.mp4",
        games_peko_appImgUrl:"",
        games_peko_Title:"皆が大好きな「ミルキー」でおなじみの人気キャラクター\n「ペコちゃん」がパズルゲームで登場！",
        games_peko_Dec:"🍰ペコちゃんとは🍰 ペコちゃんは、不二家のマスコットキャラクターである。\n当初は1950年に発売された同社の菓子「ミルキー」の商品キャラクターとして誕生したが、後に同社全体のマスコットとなった。",
        games_peko_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/phone_01.png",
        games_peko_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/phone_02.png",
        games_peko_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/phone_03.png",
        games_peko_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/phone_04.png",
        games_peko_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/phone_05.png",
        games_peko_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/01.webp",
        games_peko_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/02.webp",
        games_peko_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/pekopop/jp/03.webp",
        games_peko_copyright:"ⓒ2020 FUJIYA CO.,LTD.",
        games_peko_Privacy:"",
        games_peko_Terms:"",

        games_mog_name:"MogMog Planet",
        games_mog_googleUrl:"https://play.google.com/store/apps/details?id=com.actgames.shaku&hl=ja&gl=US",
        games_mog_iosUrl:"https://apps.apple.com/jp/app/mogmog-planet/id1591891005",
        games_mog_windowsUrl:"false",
        games_mog_apkUrl:"false",
        games_mog_thumbImgUrl:"",
        games_mog_videoUrl:"https://data.actgames.co.kr/actgames/images/games/mogmog/video.mp4",
        games_mog_appImgUrl:"",
        games_mog_Title:"同じ動物ブロックを3つ揃えて消す3マッチパズルゲームなので、\nどなたでも簡単に楽しめます！",
        games_mog_Dec:"ステージをクリアすると星を獲得。その星を使って、\n自分だけのシャクレルプラネットを作ってみましょう！また、\nプラネットごとに暮らす動物が異なるので次々とプラネットを増やしていきましょう！",
        games_mog_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/mogmog/jp/phone_01.png",
        games_mog_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/mogmog/jp/phone_02.png",
        games_mog_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/mogmog/jp/phone_03.png",
        games_mog_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/mogmog/jp/phone_04.png",
        games_mog_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/mogmog/jp/01.webp",
        games_mog_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/mogmog/jp/02.webp",
        games_mog_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/mogmog/jp/03.webp",
        games_mog_copyright:"©Panda's ana ©HIVE Co., Ltd. ©ACT GAMES Co., Ltd. All Rights Reserved.",
        games_mog_Privacy:"",
        games_mog_Terms:"",

        games_kitty_name:"サンリオしつけビデオ ハローキティといっしょ",
        games_kitty_googleUrl:"https://play.google.com/store/apps/details?id=com.hive.kitty&hl=en",
        games_kitty_iosUrl:"https://apps.apple.com/app/kitty-pop-match-3-puzzle/id1542304039",
        games_kitty_windowsUrl:"false",
        games_kitty_apkUrl:"false",
        games_kitty_thumbImgUrl:"",
        games_kitty_videoUrl:"https://data.actgames.co.kr/actgames/images/games/kity/video.mp4",
        games_kitty_appImgUrl:"",
        games_kitty_Title:"산리오 친구들과 귀엽게 머지! 황폐해진 쇼핑 타운을 다시 반짝이는 꿈의 공간으로!\n",
        games_kitty_Dec:"친구들의 발길이 끊긴 채 황폐해진 쇼핑 타운을 보며 슬퍼하는 헬로키티와 쿠로미, 이곳은 더 이상 모두를 위한 꿈의 공간이 아니에요.\n" +
            "하지만 이대로 포기할 수는 없죠! 귀여운 산리오 친구들과 퍼즐을 머지하고, 특별한 나만의 쇼핑 타운을 만드세요.\n" +
            "쇼핑 타운에 가득했던 반짝이는 꿈과 웃음을 되찾아 주세요! 산리오 친구들과 함께라면 불가능은 없답니다💪",
        games_kitty_phoneImg1:"https://data.actgames.co.kr/actgames/images/games/kity/jp/phone_01.png",
        games_kitty_phoneImg2:"https://data.actgames.co.kr/actgames/images/games/kity/jp/phone_02.png",
        games_kitty_phoneImg3:"https://data.actgames.co.kr/actgames/images/games/kity/jp/phone_03.png",
        games_kitty_phoneImg4:"https://data.actgames.co.kr/actgames/images/games/kity/jp/phone_04.png",
        games_kitty_phoneImg5:"https://data.actgames.co.kr/actgames/images/games/kity/jp/phone_05.png",
        games_kitty_copyrightBgImg1:"https://data.actgames.co.kr/actgames/images/games/kity/jp/01.webp",
        games_kitty_copyrightBgImg2:"https://data.actgames.co.kr/actgames/images/games/kity/jp/02.webp",
        games_kitty_copyrightBgImg3:"https://data.actgames.co.kr/actgames/images/games/kity/jp/03.webp",
        games_kitty_copyright:"ⓒ2024 SANRIO CO., LTD.",
        games_kitty_Privacy:"",
        games_kitty_Terms:"",

        home_title1: "文化と技術を通じて世界に進みます。",
        home_title2: "원작의 재미가 게임 콘텐츠로 재 탄생되도록 연구와 노력을 멈추지 않습니다.",
        home_title3: "원작과 게임 콘텐츠가 함께 성장할 수 있는 목표를 추구합니다.",
        news : "News",
        ViewMore: "View More",
        games:"GAMES",
        home_games_dec:"私たちは様々なゲームコンテンツで多くの人に”楽しさ”を伝えたいです。 ",
        seegames:"ゲームタイトルを見る",
        careers:"採用情報",
        home_careers_dec:"私たちは楽しさと感動を届けるために努力しています。一緒に作りましょう ",
        seecareers:"採用情報を見る",





        about_header_text:"オリジナルIPの面白さを活力に変えます。",
        about_actgames_intro:"ACT GAMES",
        about_actgames_intro_dec:"ACT GAMESは2019年設立以来、複数のIPゲームをリリースしました。\n私たちの夢は、今後もグローバルの有名IPの楽しさと感動をゲームコンテンツにし, 多くの人に長く遊んでもらい、\n永遠に記憶に残るような素晴らしいゲームを作ることです。",
        about_actgames_intro_more:"当社と当社の歴史について詳しくは、以下をご覧ください。",
        about_actgames_history:"歴史",
        about_actgames_history2024_12:"'Kitty's Friend'  リリース 予定",
        about_actgames_history2023_07:"'ZOIDS WILD ARENA'  リリース",
        about_actgames_history2022_01:"'Mogmog Planet : Match 3 Puzzle' リリース",
        about_actgames_history2021_12:"'Bub's Puzzle Blast' リリース",
        about_actgames_history2020_12:"'Peko Pop : Match 3 Puzzle' リリース",
        about_actgames_history2020_07:"'Aggretsuko : The short-timer strikes back' リリース",
        about_actgames_history2019_05:"ACT GAMES 設立",
        about_actgames_Advisory:"アドバイザリー",
        about_actgames_Contact:"Contact",
        about_actgames_Contact_dec:"actgames にリクエストやコメントを送信してください。",
        Recruiting:"職業",
        Business:"ビジネス",
        Others:"その他",
        Ceo:"代表 : SEI JIN JUNG",
        OfficeAddress:"オフィス : ソウル江南区三成洞129-2",

        careers_header_Text:"Work with us!",
        careers_weare_title:"We are",
        careers_weare_Dec:"Web3 시장을 개척하고 선점하는 도전을 해내고 있습니다.이를 위해 탄탄한 전문가들이 모여 새로운 것들을 시도하고 솔루션을 찾아내는 조직입니다",
        careers_weare_More:"아래와 같은 역량과 태도를 갖춘 분이라면 에이시티게임즈와 좋은 시너지를 낼 수 있을거에요!",
        careers_weare_c1:"Professionalism",
        careers_weare_c1_dec:"스스로 최고의 기준을 설정하고 달성하는 전문성",
        careers_weare_c2:"Enthusiasm & Problem Solving",
        careers_weare_c2_dec:"문제를 정의하고 돌파하는 집요함과 열정",
        careers_weare_c3:"Communication Collaboration",
        careers_weare_c3_dec:"정확하게 소통하고 넓게 공유하며 동료들과 효과적으로 협업하는 태도",
        careers_welfare:"Welfare",
        careers_welfare_dec:"일의 본질에 집중하고 일상의 즐거움을 위한 문화를 만들어 나갑니다",
        careers_welfare_s1_title:"Time & Refresh",
        careers_welfare_s1_li1:"10시~11시 사이 유연출근제",
        careers_welfare_s1_li2:"매년 3개의 보너스 휴가 지급",
        careers_welfare_s1_li3:"장기근속 휴가 및 휴가비 지원",
        careers_welfare_s1_li4:"반반차 제도",
        careers_welfare_s2_title:"Work Tools",
        careers_welfare_s2_li1:"고사양 업무 장비 지원",
        careers_welfare_s2_li2:"도서 및 교육 지원",
        careers_welfare_s2_li3:"",
        careers_welfare_s2_li4:"",
        careers_welfare_s3_title:"Snack & Gift",
        careers_welfare_s3_li1:"생밀 및 명절 선물제공",
        careers_welfare_s3_li2:"경조 휴가 및 경조금 지원",
        careers_welfare_s3_li3:"점심 케이터링, 머피 및 간식 제공",
        careers_welfare_s3_li4:"",
        careers_list_title:"Careers",

        footer_termsLinkURL:"/jpn/sub/provision.php",
        footer_PrivacyLinkURL:"/jpn/sub/privacy.php",
        footer_GamePolicyLinkURL:"/eng/sub/service.php",
        footer_googleLinkURL:"https://play.google.com/store/apps/dev?id=4641796618685466052&hl=ja&gl=US",
        footer_appleLinkURL:"https://apps.apple.com/jp/developer/act-games-co-ltd/id1585641858"
      }
    }
  },
  keySeparator: false,
  interpolation: { escapeValue: false }
});

export default i18n;
