import React, {useEffect , useState} from "react";
import '../css/careers.css';
import { Link } from 'react-router-dom';

function Careers(props) {
  const [positionType, setPositionType] = useState(''); // '' == all
  const [positionMap, setPostionMap] = useState({}); // postions를 key로 정렬한 데이터
  const [data, setData] = useState([]); // 원본 데이터
  const [collapse, setCollapse] = useState('');

  var liArr = [];

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    fetchCareersData('./json/careers.json');
  }, []);

  const fetchCareersData = (url) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const careers = data.careersdata;
        setData(careers)
        
        /**
         * position: [data]
         */
        const careersMap = careers.reduce((acc, cur) => {
          const { position } = cur;
          
          acc[position] = [...(acc[position] || []), cur]
          return acc;
        }, {});

        setPostionMap(careersMap);
        
      })
      .catch(err => {

      });
    ;
  };

  return (
    <section className="CareersSection">
      <div className="SectionHeaderWrap">
        <h2>{props.translation('careers_header_Text')}</h2>
        <div className="BGPattern100"></div>
        <div className="MovieScreen"></div>
      </div>
      <div className="CareersIntronWrap">
        <div className="IntroImgWrap">
        </div>
        <div className="TextWrap">
          <div className="PaddingWrap">
          <h2>{props.translation('careers_weare_title')}</h2>
          <h6>{props.translation('careers_weare_Dec')}</h6>
          <p>{props.translation('careers_weare_More')}</p>
          <div className="C1">
            <h5>{props.translation('careers_weare_c1')}</h5>
            <h6>{props.translation('careers_weare_c1_dec')}</h6>
          </div>
          <div className="C2">
            <h5>{props.translation('careers_weare_c2')}</h5>
            <h6>{props.translation('careers_weare_c2_dec')}</h6>
          </div>
          <div className="C3">
            <h5>{props.translation('careers_weare_c3')}</h5>
            <h6>{props.translation('careers_weare_c3_dec')}</h6>
          </div>
          </div>
        </div>
      </div>
      <div className="WelfareWarp">
        <div className="PaddingWrap">
          <div className="TitleWrap">
            <h2>{props.translation('careers_welfare')}</h2>
            <h5>{props.translation('careers_welfare_dec')}</h5>
          </div>
          <div className="WelfareIconeTypeWrap">
            <div className="IconType">
              <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_heart_time.png"/>
              <h6>유연출근제</h6>
              <p>10시~11시 사이 자율출근</p>
            </div>
            <div className="IconType">
            <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_heart_2hour.png"/>
              <h6>반반차 제도</h6>
              <p>2시간 단위의 연차 사용 가능</p>
            </div>
            <div className="IconType">
            <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_heart_vacation.png"/>
              <h6>보너스 휴가</h6>
              <p>매년 3개의 보너스휴가</p>
              <p>장기근속 휴기 & 포상 지급</p>
            </div>
            <div className="IconType">
            <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_heart_laptop.png"/>
              <h6>안락한 업무 환경</h6>
              <p>선릉지역 6층 사옥 근무</p>
              <p>고사양 업무 장비 지원</p>
            </div>
            <div className="IconType">
            <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_book.png"/>
              <h6>성장 지원</h6>
              <p>도서 및 교육 지원</p>
            </div>
            <div className="IconType">
            <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_gift.png"/>
              <h6>선물과 축하</h6>
              <p>30만원 상당 생일 선물, 명절 선물 제공</p>
            </div>
            <div className="IconType">
            <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_heart.png"/>
              <h6>경조사 지원</h6>
              <p>경조 휴가 및 경조금 지급</p>
            </div>
            <div className="IconType">
            <img src="https://data.actgames.co.kr/actgames/images/carrers/icons_heart_food.png"/>
              <h6>점심 케이터링</h6>
              <p>사내 식당 및 점심 제공</p>
            </div>
          </div>

          {/* <div className="WelfareListWrap">

            <div className="WelfareContent">
              <img src="https://data.actgames.co.kr/actgames/images/carrers/6f.png" />
              <h4>{props.translation('careers_welfare_s1_title')}</h4>
              <ul>
                <li>{props.translation('careers_welfare_s1_li1')}</li>
                <li>{props.translation('careers_welfare_s1_li2')}</li>
                <li>{props.translation('careers_welfare_s1_li3')}</li>
                <li>{props.translation('careers_welfare_s1_li4')}</li>
              </ul>
            </div>
            <div className="WelfareContent">
              <img src="https://data.actgames.co.kr/actgames/images/carrers/hi.png" />
              <h4>{props.translation('careers_welfare_s2_title')}</h4>
              <ul>
                <li>{props.translation('careers_welfare_s2_li1')}</li>
                <li>{props.translation('careers_welfare_s2_li2')}</li>
              </ul>
            </div>
            <div className="WelfareContent">
              <img src="https://data.actgames.co.kr/actgames/images/carrers/b1.png" />
              <h4>{props.translation('careers_welfare_s3_title')}</h4>
              <ul>
                <li>{props.translation('careers_welfare_s3_li1')}</li>
                <li>{props.translation('careers_welfare_s3_li2')}</li>
                <li>{props.translation('careers_welfare_s3_li3')}</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>

      <div className="JobList">
        <div className="JobListWrap">
          <h2>Careers</h2>
          <div className="JobCategory">
            <div className="JobCategoryWrap">
              <button type="button" className={!positionType ? 'active': ''} onClick={() => setPositionType('')}>All[<span>{data.length}</span>]</button>
              {Object.entries(positionMap).map(([key, value]) => (
                  <button 
                    key={key} 
                    type="button" 
                    className={positionType === key ? 'active': ''}
                    data-key={key}
                    onClick={() => setPositionType(key)}>{key}[<span>{value.length}</span>]
                  </button>
                )
              )}
            </div>
          </div>
          {(positionType ? positionMap[positionType] : data).map((value, i) => {
            const collapseKey = `${positionType}:${i}`;
            const isOpen = collapse === `${positionType}:${i}`
            return (
              <div className="JobContentWrap" key={value.title + i.toString()}>
                <button type="button" className={`Collapsible ${isOpen ? 'active' : ''}` } onClick={() => setCollapse(isOpen ? '' :`${positionType}:${i}`)}>
                  <h5>{value.team}</h5>
                  <h3>{value.title}</h3>
                  <div>
                    <p>{value.position}</p>
                    <p>{value.year}</p>
                    <p>{value.employment}</p>
                    <p className="Date">{value.date}</p>
                  </div>
                </button>
                { isOpen && (
                  <div className="CollapsibleContent">
                  <div className="ContentWrap">
                    <h6>직무</h6>
                    <div className="ContentTextWrap">
                      <p>{value.position}</p>
                    </div>
                  </div>
                  <div className="ContentWrap">
                    <h6>담당업무</h6>
                    <div className="ContentTextWrap">
                      <ul>
                        {value.responsibilities.map((v) => (
                          <li key={v}>{v}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="ContentWrap">
                    <h6>자격사항</h6>
                    <div className="ContentTextWrap">
                      <ul>
                        {value.qualifications.map((v) => (
                          <li key={v}>{v}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="ContentWrap">
                    <h6>우대사항</h6>
                    <div className="ContentTextWrap">
                      <ul>
                        {value.preferential.map((v) => (
                          <li key={v}>{v}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="ContentWrap">
                    <h6>지원서류</h6>
                    <div className="ContentTextWrap">
                      <ul>
                        <li>{value.document}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="ContentWrap">
                    <h6>고용형태</h6>
                    <div className="ContentTextWrap">
                      <ul>
                        <li>정규직</li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="ContentWrap">
                    <h6>채용전형</h6>
                    <div className="ContentStepWrap">
                      <div className="Step">
                      <div></div>
                        <p>서류심사</p>
                      </div>
                      <div className="Step">
                        <div></div>
                        <p>실무진 면접</p>
                      </div>
                      <div className="Step EndPoint">
                      <div></div>
                        <p>최종합격</p>
                      </div>
                    </div>
                    <div className="Line"></div>
                  </div> */}
                  <div className="ContentWrap">
                    <h6>채용전형</h6>
                    <div className="ContentTextWrap">
                    <span><Link to="">{value.email}</Link>{value.emaildec}</span>
                    </div>
                  </div>
                </div>
                )}
                
              </div>
            )
          })
          }
          
          
          <div className="BtnWrap">
            <button></button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Careers;
