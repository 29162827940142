import React, {useEffect , useState} from "react";
import i18n from "i18next";
import { Link } from 'react-router-dom';

export default class ChangeLang extends React.Component {

  

  state = {
    lang: "en"
  };
  langChange = e => {

    const headerCheckBox = document.getElementById("Nav");

    this.setState({ [e.target.name]: e.target.value }, () => {
      localStorage.setItem("lang", this.state.lang);
      const lang = e.target.value;
      i18n.changeLanguage(lang);
    });
    this.state.lang = e.target.value;
    var header = document.getElementById("Language");
    var btns = header.getElementsByClassName("MenuBtn");
    for (var i = 0; i < btns.length; i++) {
      var current = document.getElementsByClassName("active");
      if (current.length > 0) {
        current[0].className = current[0].className.replace(" active", "");
      }
    }
    e.target.classList.add("active");
    headerCheckBox.checked = false;
    document.documentElement.lang = e.target.value;

    if (e.target.value == 'jp') {
      // document.documentElement.lang = 'jp'
      document.title = "ACTGAMES ペコポップ,アグレッシブ烈子,Bub's Puzzle Blast,シャクレルプラネット APK APK File Download";
      document.getElementById('description').content = "ACTGAMESの多様なゲームのAPKファイルがダウンロードできます。ご使用のデバイスがAndroid api 30より以下の場合、DOWNLOADでAPKファイルを設置しゲームをプレイしてください。";
      document.getElementById('keywords').content = "ペコちゃん,マッチ3パズル	,APKダウンロード,API30以下,ペコポップ,腰掛けの逆襲,アグレッシブ烈子,バブルボブル,パズルブラスト,Bub's Puzzle Blast	,シャクレルプ";
    

    } else if (e.target.value == 'en') {
      // document.documentElement.lang = 'en';
      document.title = "ACTGAMES PEKO POP, Aggretsuko, Bub's Puzzle Blast, MogMog Planet, APK File Download";
      document.getElementById('description').content = 'You can download APK files of various games by ACT Games.If the API of your Android API is lower than 30, you can download and play the games after installing the APK file from DOWNLOAD.' ;
      document.getElementById('keywords').content = "PEKO,Match 3 Puzzle,APK Download,below API 30,PEKO POP,The Short-timer Strikes Back	,Aggretsuko,Bubble Bobble,Puzzle Blast,Bub's Puzzle Blast,MogMog,Shakurel Planet";

    } else {
      // document.documentElement.lang = 'ko'
      document.title = "ACTGAMES 페코팝 , 레츠코 , 법스 퍼즐 블라스트 , 모구모구 플래넷 APK 파일 다운로드";
      document.getElementById('description').content = 'ACTGAMES의 다양한 게임의 APK파일을 다운로드 할 수 있습니다. 사용하시는 api 30 이하 일 경우 DOWNLOAD에서 APK파일 설치 후 게임 이용을 하실 수 있습니다.' ;
      document.getElementById('keywords').content = "페코,매치3퍼즐,APK 다운로드,API30 이하,페코팝,월급쟁이의 역습,어그레츠코,보글보글,퍼즐블라스트,법스 퍼즐 블라스트,모구모구,샤쿠레루	플래넷"
    }



  };

  render() {
    const { lang } = this.state;
    console.log(document.documentElement.lang);
    return (
      <>
        <div className="Dropdown" >
          <button className="Dropbtn" onChange={this.langChange}>{lang}</button>
          <div className="DropDownContent" id="Language">
            <button className="MenuBtn active" value="en" onClick={this.langChange}>en</button>
            <button className="MenuBtn" value="kr" onClick={this.langChange}>kr</button>
            <button className="MenuBtn" value="jp" onClick={this.langChange}>jp</button>
          </div>
        </div>
      </>
    );
  }
}