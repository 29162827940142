import React from "react";
import '../css/terms.css';
function Eng_provision() {
  return (
    <>
        <div id="privacy" className="terms sub">
            <div className="container">
                <h3 className="sub_title">Service Terms and Conditions</h3>
                <hr className="star-primary wow zoomIn"></hr>
                <div className="terms_box">
                    <p>ACT GAMES Co., Ltd. Service Terms and Conditions.</p>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 1 (Purpose)</h4>
                    <p>The following Service Terms and Conditions regulate all the game and general services provided by ACT GAMES Co., Ltd. (hereinafter referred to as ‘Company’).</p>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 2 (Specific Terms)</h4>
                    <p>Below is the list of used terms.</p>
                    <ul>
                        <li>‘Users’ are referred to the consumers who are using all games and other services (Including general members and temporary members), and can be categorized as below.
                            <ul className="sub_list">
                                <li>1) General Members: The members who have received an official company account after providing personal information. ‘An account’ is a combination of numbers and characters that can identify the user about service use.</li>
                                <li>2) Temporary Members: The members who are using the service with a temporary account that they have received without providing their personal information. When temporary members reset their device or delete the content from their device, the temporary account will be reset and game data will be reset as well.</li>
                            </ul>
                        </li>
                        <li>"content(s)"means information such as text, audio files, music, images, videos, software, programs, computer code, and the like that are used at information and communications network (In accordance with Subpara. 1 of Para.1 of Art.2 of the law regarding promotion of information and communication network use and protection of information). It includes all digital components for the Service, such as game, network service, application, game money, item money.</li>
                        <li>. “Charged content’ is the content that users can purchase by making payments to acquire charged game cash (Para.1 of Art.16), and other game related items.</li>
                        <li>“Post” is referred top all information that is uploaded on the company’s official cyber bulletin board (Company’s official website, In-app board and company’s official social network service board), including characters, documents, images, voices, videos, and mixture of listed.</li>
                        <li>. ‘Device’ is referred to electronic equipment which can be used to download and install content, such as PC, smartphone, and tablet</li>
                        <li>‘Service’ is referred to entire network service provided from the company, regardless of the type of devices.</li>
                        <li>“Application” is referred to all types of downloadable or installable programs via devices (Para.1) to use provided service from the company.</li>
                        <li>“Open Market Provider” is referred to an e-commerce provider that allows you to install the game any make payments (A provider that allows you to make in- game payments, including a telecommunication company).<br/>
                        (i.e. Google Play Appstore, Apple Appstore, and etc)</li>
                        <li>“Payment Gateway” is a merchant service provided by an e-commerce application service provider that authorizes payments via web. Payment Gateway system is valid only in Thailand and Vietnam.</li>
                        <li>“Platform Provider” is a provider or a service that provides cooperate services to a client’s game services.</li>
                        <li>“Cash” is a virtual data that is being used in game applications provided from the company. Users can use cash for the company’s charged service and purchasing in- game content or items</li>
                        <li>“In-App Payment” is referred to all in-app payments that are provided by the company for purchasing cash, items, and other charged content.</li>
                        <li>“Web Payment” is referred to all payments via a website provided by the company to purchase cash, items, and other charged content.</li>
                        <li>Definition of terms used in Terms & Conditions will be based on the related laws, except the definitions that are set as default in this Terms & Conditions.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 3 (Effects, modifications, applications of the Terms and Conditions)</h4>
                    <ul>
                        <li>Terms and Conditions will go into effect when a user installs and runs the downloaded application. The company must display detailed Terms and Conditions on the company’s website or application screen.</li>
                        <li>The Company may modify the Terms and Conditions or Separate Terms and Conditions when the Company deems necessary, without providing prior notice to Users complying with “Regulation of Standardized Contracts Act” and “the law regarding promotion of information and communication network use and protection of information”</li>
                        <li>In case that the company modifies the Terms and Conditions in accordance with Art 2, the company must post an announcement on the company’s official homepage, café, and application bulletin board about the modification minimum 7 days, maximum 30 days prior to the modification.<br/>
                        However, if the modified Terms and Conditions include unfavorable details, the company must notify of modified details to the users via one of the listed methods: email, app messages, agreement pop-up window upon login, and mobile text messages.</li>
                        <li>Users have rights to reject the modified Terms and Conditions. Any users who have an objection to the modified Terms and Conditions may stop and cancel the service (Cancel the membership).</li>
                        <li>If a user does not declare objection until the stated date of application even after confirming the detailed modification notice complying with Art 3, it will be considered that the user has agreed to all modified Terms and Conditions.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 4 (Regulations outside of Terms and Conditions)</h4>
                    <p>Article and Item details outside of stated Terms and Regulations will comply with regulations of the company’s Service Terms and Agreement, the law regarding promotion of information and communication network use and protection of information, and the protection of consumers. If there are not detailed regulations stated in company’s Service Terms and Agreement and other laws, details will comply with normal commercial practice.</p>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 5 (Agreement of Service Terms & Conditions)</h4>
                    <ul>
                        <li>Users will be accepting the Service Terms & Conditions by installing the application and ticking ‘Springcomes Game Service Terms & Conditions’. The company must approve the user’s agreement for the service use.</li>
                        <li>If there are no business or technical issues during Service Terms & Conditions agreement in accordance with user’s agreement stated at Item 1, company makes it a rule to approve the consent the use of service. But, company has right to disapprove or cancel the agreement afterwards under conditions in subparagraphs.
                        <ul className="sub_list">
                            <li>1) Providing false personal information, provision of other’s personal information or in case a user did not provide necessary information.</li>
                            <li>2) Application under the purpose of activities that may hinder public order or customs</li>
                            <li>3) If a user has an intention to use the Service for purposes other than the Service's true intent.</li>
                            <li>4) If a user has an intention to use the Service for other commercial purposes.</li>
                            <li>5) If a user has violated any one of stated regulations</li>
                        </ul>
                        </li>
                        <li>Company has rights to hold the approval until the issues are solved under stated subparagraphs
                        <ul className="sub_list">
                            <li>1) If company does not own enough equipment for stable service</li>
                            <li>2) If company is not capable of stable service due to unexpected business or technical issues</li>
                            <li>3) If there is any ‘member’ found who is capable of taking inappropriate actions</li>
                        </ul>
                        </li>
                        <li>If there is any case the company approves, declines, or holds the user’s agreement on Terms & Service in accordance with the stated Article, the company has a duty to inform the fact to the user.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 6 (Company’s Obligation)</h4>
                    <ul>
                        <li>The company has the obligation not to hinder any of stated Terms & Conditions and constantly try their best to provide stable service.</li>
                        <li>The company must make it a rule to provide stable service for 24 hours a day throughout the year, unless the company has business or technical issues. However, the company also has rights to stop or limit the service in accordance with Art. 8 Para.8.</li>
                        <li>The company ought to try their best to protect user’s personal information (Including credit information) for stable service, hence complying with Privacy Statements is a must.</li>
                        <li>The company must try their best to construct and to maintain their system and manpower for processing user’s discontent and loss recovery request at any time. If the user’s opinions or complaints are objectively admitted, they ought to handle the issue within the reasonable period, but if it is going to take longer than expected, the company must inform the user of the reason and further process schedule.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 7 (User Responsibility)</h4>
                    <ul>
                        <li>Users must not do any action that violates stated subparagraphs while using the company’s service.
                            <ul className="sub_list">
                                <li>1) Users must not provide another user’s information or false information to the company when sending personal information about user inquiry, charged content recovery or refund request, and event winning.</li>
                                <li>2) Activities that lead to misrepresentation of a third party, or intentionally providing false information, provision of other’s personal information, registered information, purchasing charged content by using other’s credit card, wire phone/mobile phone, or bank account without permission.</li>
                                <li>3) Activities that exchange the right to use the Contents into cash, property or other economic benefits without Company's authorization.</li>
                                <li>4) Activities of duplicating, distributing, using company’s information via service and application without company’s prior approval, or abusing its service by means of known or unknown bugs.</li>
                                <li>5) Activities that allow you/or a third party to gather economic benefits by using the company’s service or provided application.</li>
                                <li>6) Activities that infringe intellectual property rights, such as copyrights, trademarks and patents, fame, privacy, and all other rights of the Company and/or a third party granted by the law or contract.</li>
                                <li>7) Activities that deceive any third party to make profits, or give damage to any third party by means of unfair play.</li>
                                <li>8) Activities of defamatory and inflicting damage to others.</li>
                                <li>9) Activities such as publishing unauthorized advertisement or promotion materials, posts that include links to pornographic websites, or exchanging obscene information.</li>
                                <li>10) Activities of sending or spreading sounds, texts, images, video clips to the other party that lead to humiliation and displeasure.</li>
                                <li>11) Activities that induce users to bet their fortune for gambling and speculation.</li>
                                <li>12) Activities of sending, publishing, spreading, using virus, other computer codes, files, and programs that were intentionally created for the purpose of interrupting or destroying device functions, computer software, hardware, or information that is forbidden to be uploaded (Computer program) in accordance with the stated Terms & Conditions.</li>
                                <li>13) Activities that gain unauthorized access to the Service, accounts registered or others’, or to networks from which portions of the Service are provided, including by circumventing or modifying, attempting to circumvent or modify, or assisting or encouraging any other person to circumvent or modify, any security, technology, device, or software that is part of the Service</li>
                                <li>14) Activities of publishing or sending mails by means of impersonating any other person, including a representative or employee of the company.</li>
                                <li>15) Activities that use the Service for sales, marketing, advertisement, political activities, illegal election campaign purposes without the Company’s approval.</li>
                                <li>16) Activities of intentionally interfering with the Company’s operation of the Service, and sending false information that can interfere with stable operation of the Service.</li>
                                <li>17) Activities that may hinder public order or customs, or that may be in contravention of stated Terms & Conditions.</li>
                            </ul>				
                        </li>
                        <li>Users have the obligation to check the notice and modifications regarding Terms & Conditions frequently via the Company’s website or application, and they must not to take any action that can interfere with the Service.</li>
                        <li>Users must not allow a third party to use their personal accounts. If the user finds out that his/her account or personal information has be used by the third party, the user must notify it to the Company and has to follow further guides, and if the user does not follow as the Company’s guide, the user has full responsibility of possible damages and loss (e.g. Even if a third party made a payment on the charged content, the user is still responsible for the payment).</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 8 (Modification of the Service)</h4>
                    <ul>
                        <li>The Company has rights to modify, add, revoke some or entire Service at a regular basis, such as adding new game content and other bug patches in case they are necessary for the Service and technical management.<br/>In this regard, the Company must notify users of details about modified service details and update date via Company’s official website or application.</li>
                        <li>The Company can limit or stop some or entire Service in accordance with the stated subparagraphs. In any cases, the Company shall inform users of details in the official website and application, and users cannot send a refund request about expired paid content upon Service termination.
                            <ul className="sub_list">
                                <li>1) In case of irresistible force such as War, Natural disaster, and a state of national emergency</li>
                                <li>2) In case the Service is unstable due to blackout, system failure, and heavy network traffic</li>
                                <li>3) In case the Service equipment or system goes under maintenance</li>
                                <li>4) In case the Company can no longer provide Service due to the Company’s unstable situation</li>
                            </ul>
                        </li>
                        <li>In case that the Company has stopped the Service in accordance with stated subparagraphs in Para.2, the Company shall inform users via official website or application. But, it is not necessary if the Company stops the Service due to irresistible causes</li>
                        <li>the Company shall not be responsible for the issues caused by Service modification or suspension, except for intentional modification or suspension, or gross negligence.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 9 (Some Service Limits for Minors)</h4>
                    <ul>
                        <li>Minors under the age of 19 are not eligible for any content that is harmful to juveniles serviced by the Company, in accordance with Juvenile Protection Act.</li>
                        <li>If a user desires to use content that is harmful to juveniles, he/she must go through with age verification in accordance with the related law. If the user does not accept the procedure stated in the Article, all related service content will be limited.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 10 (Information Provision & Publication)</h4>
                    <ul>
                        <li>The Company can provide users with Service Terms & Conditions via e-mail, service screen, websites, and the notice in application. Users have rights to decline information emails from the Company, except the emails containing transaction related, and customer inquiries in accordance with the law.</li>
                        <li>The Company reserves the right to provide Users with information such as advertisements via service screen, website, and posting on the application. Users agreeing to the stated Terms & Conditions mean they also agree to advertisement exposure when visiting the official homepage or service</li>
                        <li>The Company reserves the right to send information of Para.1 and advertisements of Para.2 by using collected personal information via SMS(LMS), Smart phone notification (Push Notification), and e-mail under the Users’ consent. If Users do not want to receive any information or advertisements, they have right to decline the notification via Company’s website or Customer Service at at all times.</li>
                        <li>The Company shall not be responsible for any trade activities between Users or between Users and a third party by means of the Company’s Service and advertisement.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 11 (Purchase, Expiry Date, Usage of Charged Content)</h4>
                    <ul>
                        <li>Users can make a payment to purchase all charged content related to the Service. With regards to this, detailed cost or payment method may differ from the type of Service, telecommunication provider, platform provider, or open market provider’s business policy.</li>
                        <li>A User can use purchased content only via the device installed with the application.</li>
                        <li>When purchasing charged content, a User must follow the expiry date stated on the content. However, in case of the Service suspension in accordance with Art.8 Para.2, charged content with unlimited period shall be expired on the stated date in the Service Suspension notice.</li>
                        <li>Users must use their purchased content in their account only, unless the Company mentioned another way to use it, and Users may not transfer or lend their account to any third party nor may their accounts inherited by any third party.</li>
                    </ul>
                </div>
        
                <div className="terms_box">
                    <h4>Article 12 (In-App Payment)</h4>
                    <ul>
                        <li>The Company’s Application includes In-App payment function for purchasing items.</li>
                        <li>Users have a responsibility to utilize password settings function from the device, and password settings functions provided by open markets to prevent a third party from unauthorized In-App payment via the User’s device, and the Company bears a responsibility to apply In-App payment module & library in the application, in accordance with authentication procedures provided by local Communication Commission’s regulations, or ‘Open Market Mobile Content Payment Guide line’.</li>
                        <li>The Company shall not be responsible for any In-App payment damages inflicted by a third party upon the User’s negligence, such as not using the device or open market’s password settings function, and exposing the password to a third party.</li>
                        <li>If a User is using the Minor telecommunication plan to make an In-App payment from the device, it will be considered the user already had consent from parents or legal guardian.</li>
                        <li>A User bears a responsibility to pay the right amount of cost when making In-App purchase.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 13 (Web Payment)</h4>
                    <ul>
                        <li>The Company provides a Web Payment other than In-App payment for the Service.</li>
                        <li>The Company shall not be responsible for any Web payment damages inflicted by a third party upon User’s negligence of exposing his/her account or password information.</li>
                        <li>Web payment is another way of payment method by purchasing prepaid card, and it is available only in Thailand & Vietnam.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>Article. 14 (Post Management)</h4>
                    <ul>
                        <li>The Company reserves the right to move User’s posts within the site if necessary, without any prior notice, and also has the right to delete the posts without prior notice if the Company has unavoidable reason, such as lack of system storage.</li>
                        <li>Users shall bear all responsibility for their posts, and they shall not post anything that infringes a third party’s rights. If a third party has filed objections and complaints regarding possible violations of their rights due to other Users’ posts, the Company reserves the right to take a temporary measure in accordance with the Terms & Conditions, and notify the User.</li>
                        <li>If a third party makes the Company take legal responsibility, such as compensation for infringement of their rights due to other Users’ posts, the Company shall fully cooperate for the Company’s indemnification, and the User out to be fully responsible for the problem, unless the Company is responsible for the incident.</li>
                        <li>The Company reserves the right to delete, move, or refuse to upload the posts without prior notice in accordance with subparagraphs.
                        <ul className="sub_list">
                            <li>1) Posts for the purpose of harassments or libelous attacks against other User or a third party</li>
                            <li>2) Posts that may hinder public order or customs</li>
                            <li>3) Posts about selling User’s account or points</li>
                            <li>4) Posts for commercial purposes</li>
                            <li>5) Posts that contain details about criminal activities</li>
                            <li>6) Posts that infringe a third party’s intellectual property rights or other rights</li>
                            <li>7) Posts that violate the Company post rules, or that don’t fit to the board’s purposes.</li>
                            <li>8) Posts that interfere with the Company’s operation</li>
                            <li>9) Posts that can be regarded as criminal activities</li>
                            <li>10) Posts that include unauthorized advertisements and promotion materials</li>
                            <li>11) Duplicated posts that include the same content</li>
                            <li>12) Posts that encourage any illegal copying or hacking activities</li>
                            <li>13) Posts that are deemed to be violating other Terms & Regulations</li>
                        </ul>
                        </li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 15 (Event Rewards & Prize Money)</h4>
                    <ul>
                        <li>The Company can hold an event of which Users can obtain event rewards or prize money for a certain period.</li>
                        <li>If a User has attended the Company’s official event with false personal information or with inappropriate way, the Company shall cancel the User’s winning rewards.</li>
                        <li>The Company shall deliver the event rewards or prize money to winners after the process of deducting withholding tax of Public Charge And Tax</li>
                        <li>If the event winner is under age of 20, the Company shall deliver the even rewards or prize money after having consent from the winner’s parents or legal guardian.</li>
                        <li>The Company reserves the right to cancel the event winner if the winner does not receive the event reward or prize money within 1 month after the event winner announcement.</li>
                        <li>The Company reserves the right to collect and utilize User’s personal information for the event reward or prize money delivery, all details must comply with the Company’s Privacy Policy.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 16 (Cash & Points)</h4>
                    <ul>
                        <li>There are two types of cashes for the Service; (i) Cash that can be purchased via In-App payment (hereinafter referred to as ‘Paid Cash’) and (ii) Cash that can be acquired as a reward of in-game events or from the Company (hereinafter referred to as ‘Free Cash’), and they will be managed individually.</li>
                        <li>The Company can provide Mileage Points that can be used in the game service for free of charge (hereinafter referred to as “Free Point”).</li>
                        <li>If a User possess Paid Cash, Free Cash, and Free Points, and use them to purchase or use in-game content, (i) Paid Cash, (ii) Free Cash, (iii) Free Points will be deducted respectively as used amount.</li>
                        <li>Free Cash and Free Points are not eligible for the refund and compensation, and the Company shall not be responsible for possible requests.</li>
                        <li>Free Cash and Free Point use may be limited on their use in accordance with conditions. The Company shall notify Users of specific conditions (Expiry date, range of use, or others) via a notice.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 17 (Purchase Withdrawal & Purchase Refunds)</h4>
                    <ul>
                        <li>Users reserve the right to make a withdrawal request (Purchase cancelation) regarding purchases that were made without commission via web payment site or application within 7 days of purchase or within 7 days of usable date. However, the withdrawal may be limited for some charged content in accordance with Art.18 Para.2 of 「Consumer Protection Law of e-commerce」. In case of purchase withdrawal limits, the Company has responsibility to take measures complying with the law by notifying Users of non-withdrawal details.</li>
                        <li>Users cannot get a purchase withdrawal as stated on Para.1 under conditions stated on following subparagraphs that will be against the Company’s intent. However, with regards to the purchase of separate content, a purchase withdrawal will be valid except stated conditions on the following subparagraphs.
                        <ul className="sub_list">
                            <li>1) A charged content which will take effect right upon the purchase</li>
                            <li>2) A bonus content which has been used upon receiving it.</li>
                            <li>3) A content which can be considered ‘used’ upon opening it.</li>
                        </ul>
                        </li>
                        <li>Purchase refunds must be proceeded in accordance with each Open Market’s refund policy, and detailed refund procedure will comply with the Company’s operation policy. Furthermore, purchased content will be deducted along with refund.</li>
                        <li>If a User has changed the charged content to free content, if a User has already used some or all purchased good, if a User has accepted or agreed to charged content details stated on the screen, it will be deemed the user has used the charged content.</li>
                        <li>If the content was gained by Service compensation without making a proper payment, which is being recorded to the system, or if a User has obtained the content through Service events and other partnership events, it will be excluded from the refund. It also includes when a User has purchased the content from another User.</li>
                        <li>If a User sends purchase withdrawal or refund request, it will go through the personal information usage consent process by the Company customer center or the consigned company. Then, the Company shall confirm the User’s purchase list with the Platform Provider or Open Market Provider. During the process, the Company reserves a right to contact the User with provided information from the User for more precise confirmation, and also has a right to request an additional documented evidence. [e.g. A document which includes personal information registered in the Platform Provider, Purchase record in the Open Market, and a document that can prove the purchase had been made against the User’s will.]</li>
                        <li>If there is a cause attributable to the User which as violating the current law or stated Terms & Conditions, the purchase withdrawal may be limited that are stated in Art.19 & Art.20.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 18 (In-App Item Withdrawal)</h4>
                    <ul>
                        <li>Despite of Art.17, if the charged content (hereinafter referred to ‘In-App Item’) that was charged via In-App or Web payment by a User meets the stated subparagraphs, the withdrawal will be limited. In case of withdrawal limitation, the Company shall not violate User’s right to have purchase withdrawal by posting information that states withdrawal limit conditions before making payment on an In-App Item via In-App payment or Web payment methods, but if the Company did not notify a User of detailed withdrawal conditions, the User reserves a right to make purchase withdrawal regardless of stated conditions in subparagraphs.
                        <ul className="sub_list">
                            <li>1) An In-App Item which activates or takes effect on the application right after the purchase</li>
                            <li>2) An In-App Item which was acquired in the Service without In-App Item payment.</li>
                            <li>3) If the bonus in In-App Item was already used.</li>
                            <li>4) If some of package In-App Items were already used.</li>
                            <li>5) If a User has opened In-App Item or capsule/random rate In-App Items that are activated right after opening.</li>
                            <li>6) If a User has used all or some of In-App Items and currency.</li>
                            <li>7) If In-App Item has been expired (In accordance with Art.11 Para.3)</li>
                        </ul>
                        </li>
                        <li>In-App Item payment method complies with open market provider’s payment regulations, and if a mistake has been made during the payment process, a User has to send a refund request to the open market provider. However, if possible, the Company can also request the necessary refund process to the open market provider in accordance with open market’s policy and system.</li>
                        <li>An In-App Item which was received as a gift is not eligible for refund, unless it has flaws, and only the User who sent the gift has the right to make a refund request.</li>
                        <li>Web payment is Payment Gateway method processed by another payment agency that the purchase cannot be refunded directly from the Company. However, it can be withdrawn to payment site’s currency, so that the User can use the currency after the withdrawal.</li>                
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 19 (Withdrawal for Minors)</h4>
                    <ul>
                        <li>If a Minor has purchased charged content without legal guardian’s consent, the Minor or legal guardian are eligible for the withdrawal.</li>
                        <li>If Minor has made a payment within approved amount of cost from the legal guardian or if the Minor made a payment by deceiving his/her age, withdrawal qualification will be limited, which is stated in Para.1</li>
                        <li>The age of a User who made a payment on the content will be judged based on the registered personal information in the device or payment method such as credit card.</li>
                        <li>In order of a Minor to send a purchase withdrawal request in accordance with Art.19, the Minor or legal guardian shall submit a document which can prove them as the Minor or the legal guardian upon Company’s request.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 20 (Service contract Termination & Service Limitation)</h4>
                    <ul>
                        <li>A User reserves a right to terminate the Service contract by quitting the account if the User no longer desires to use the Service, and the contract termination will take effect right after the User’s termination consent.</li>
                        <li>If a User has violated one of the stated subparagraphs in Art.7 Para.1, the Company reserves a right to terminate the User’s contract or suspend the use of service for a certain period.</li>
                        <li>In case of service termination or service suspension, the Company shall notify the user of the reason or effective date via e-mail, in-game mail, or other methods.</li>
                        <li>If the service has been terminated or suspended in accordance with Para.2, the User no longer can use the Service content, and also not eligible for refunds on currency, mobile data fee, fixed monthly service fee that were made for using the charged content.</li>
                        <li>Upon Service contract termination, the Company shall delete all User’s personal information and data (Including content information in the account) except information which the Company shall keep in accordance with the Company’s regulation or Privacy Policy. If a User did not take any action even after the Company had notified the User of information deletion details, the Company shall not be responsible for inflicted damages.</li>
                        <li>The Company does not collect personal information such as e-mail account address, password from a temporary user. If the device or platform has been reset, of if the game has been deleted, the Company cannot recover game information & data for the temporary user, and also shall not be responsible for the damage</li>
                        <li>The Service termination of Art.20 does not affect Compensation for damage details stated in Art.23.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 21 (Protection & Usage of Personal Information)</h4>
                    <ul>
                        <li>The Company shall not utilize User’s personal information that was provided for Service contract for non- service uses or shall not provide the information to a third party without the User’s consent. However, stated subparagraphs can be exceptions.
                        <ul className="sub_list">
                            <li>1) When User’s personal information utilization is permitted or when the Company can provide the information to a third party in accordance with the law.</li>
                            <li>2) When the Company gets consent from a User in accordance with the Company’s other regulations & policy.</li>
                        </ul>
                        </li>
                        <li>Protection & usage of collected personal information will comply with the related law and privacy policy. Privacy policy that is posted on application or website board includes some details for Terms & Conditions, and User agrees to the details.</li>
                        <li>The Company shall manage collected personal information by themselves, however, if necessary, the Company can consign some or all related work to a third party. Related details should be notified to Users via Privacy Policy.</li>
                        <li>The Company shall not be responsible for User’s leaked information due to cause attributable to the User.</li>                
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 22 (The Company’s Exemption of Liability)</h4>
                    <ul>
                        <li>The Company is exempted from responsibility of service suspension caused by War, natural disaster, a state of national emergency, technical flaws that cannot be fixed, or other irresistible forces.</li>
                        <li>The Company shall not be responsible for service suspension/error due to cause attributable to the User. In addition, the Company is exempted from responsibility for damage inflicted due to service suspension or error by a telecommunication provider.</li>
                        <li>The Company shall not be responsible for inevitable service suspension or error caused by Service repair, replacement, maintenance, and construction that had been announced in advance.</li>
                        <li>The Company shall not be responsible for any User’s discomfort of not achieving the target score or rank, and also shall not be responsible for damages inflicted by User’s choices that were made by their will.</li>
                        <li>The Company shall not be responsible for disadvantages or information loss caused by the User after changing personal information by himself/herself (Including account information).</li>
                        <li>The Company is exempted from responsibility for Service problem or network issues that are not related to the Company’s end.</li>
                        <li>The Company shall not intervene or be responsible for disputes between Users or between a User and a third party, and also no responsible for compensation for damages caused by the dispute.</li>
                        <li>The Company shall not assume responsibility for compensation to a User regarding free of charge service or content, unless the Company has a special regulation in the Terms & Regulations. However, damages that were caused by the Company’s intentional or Company’s negligence are exception.</li>
                        <li>If you do not connect your game play on a device to an account that is linked to either your social network account or an official company account, we will not be able to restore any Charged content, Free Cash, Free Points or data associated with your game play if you lose your game play data on a device.</li>
                    </ul>
                </div>	

                <div className="terms_box">
                    <h4>Article. 23 (Compensation for Damage)</h4>
                    <ul>
                        <li>If the Company or a User has inflicted damage by violating the stated Terms & Conditions, each party shall be responsible for compensation for the damage. However, if it was the unintentional damage, each party shall be exempted from the responsibility.</li>
                        <li>If damage has been inflicted intentionally/unintentionally by the Individual Service Provider after a User has agreed to the Individual Service Terms & Regulations while the Company has a partnership with the Individual Service Provider, the Individual Service Provider shall be responsible for the compensation.</li>
                    </ul>
                </div>	

                <div className="terms_box">
                    <h4>Article. 24(Governing Law & Jurisdiction)</h4>
                    <ul>
                        <li>All contradictions happened between the Company and the User regarding the Service must come to a peaceful and satisfactory settlement under an agreement from both parties.</li>
                        <li>If the contradiction cannot be closed as stated in Para.1, each party reserves a right to send dispute conciliation to Content Dispute Resolution Committee in accordance with the Cultural Industry Promotion Basic Act. Conflicts that arise from the Service or conflicts between Users and the Company related to the Service will be governed primarily under the exclusive jurisdiction of a competent court.</li>
                        <li>In the event of all contradictions between the Company and Users of Korea/Asia/America/Europe servers, both parties shall follow the Governing Law of Korea.</li>
                        <li>In the event of all contradictions between the Company and Japan sever Users, both parties shall follow the Governing Law of Japan.</li>
                        <li>In the event of all contradictions between the Company and TW,HK,MO sever Users, both parties shall follow the Governing Law of Taiwan.</li>
                        <li>In case there is any article that cannot be applied nor be enforced in accordance with legal judgment, it will be regarded as not valid. However, other articles will still be in effect.</li>
                    </ul>
                </div>			
                
                
                <div className="terms_box">
                    <h4>This Terms & Conditions is effective as of September 27th, 2021.</h4>
                    <p>* This Terms & Conditions is translated version from Korean to English. Please refer to Korean version for more details.</p>
                </div>

            </div>
        </div>
    </>
  );
}

export default Eng_provision;
