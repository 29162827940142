import React from "react";
import '../css/terms.css';

function Jpn_privacy() {
    const style = {
        TitleColor: {
          color: "#fff",
        }
      };
  return (
    <>
        <div id="privacy" className="terms sub" style={style.TitleColor}>
            <div className="container">
                <h3 className="sub_title">個人情報処理方針</h3>
                <hr className="star-primary wow zoomIn"></hr>
                <div className="terms_box">
                    <p>ACT GAMES Co., Ltd.(以下「会社」)はユーザーの個人情報を重要視し、「個人情報保護法」、「通信秘密保護法」、「電気通信事業法」「位置情報の保護及び利用などに関する法律」、「情報通信網利用促進及び情報保護」に関する法律などの関連法規を遵守しています。会社は、本個人情報取扱方針を介してユーザーから提供された個人情報がいかなる用途と方式で利用されているか、個人情報を保護するためにいかなる措置が取られているかを通知します。</p>
                </div>
                
                <div className="terms_box">
                    <p>本個人情報取扱方針の内容は、次のとおりです。　</p>
                </div>
                            
                <div className="terms_box">
                    <h4>第1条 収集する個人情報の項目と収集方法</h4>
                    <ul>
                        <li>会社は各種サービスを提供するために、最初にサービスに加入する際、以下のような個人情報を収集しています。
                            <ul className="sub_list">
                                <li>- ユーザーのプラットフォーム事業者のサービスで提供される固有識別番号、ユーザー名又は別名(ニックネーム)、端末固有番号(デバイスID又はUUID)など</li>
                            </ul>
                        </li>
                        <li>サービスの利用過程で以下のような情報が生成され収集されることがあります。
                            <ul className="sub_list">
                                <li>- ユーザーの状態情報、訪問日時、サービス利用記録、不良利用記録、ユーザー端末位置情報、IPアドレス情報など</li>
                            </ul>
                        </li>
                        <li>カスタマーサポート利用の際、下記の個人情報を収集しています
                            <ul className="sub_list">
                                <li>- メールアドレス、ニックネーム、利用者の同意により設定されている名前</li>
                            </ul>
                        </li>
                        <li>無料/有料サービスの利用過程で決済などのために必要不可欠な場合、クレジットカード情報、通信会社情報、購入内訳及び商品券番号、その他決済関連情報が収集されることがあり、顧客の有料コンテンツ復旧及び払い戻し要請の際に円滑な顧客相談を行うため、メールアドレスおよび購入内訳確認内容、そして本人ではない他人が決済した事実確認のために実名と家族関係証明などの個人情報を追加収集することができます。</li>
                        <li>会社は、ユーザーの基本的人権を侵害する恐れがある敏感な個人情報(人種、思想及び信条、政治的性向や犯罪記録、医療情報など)は収集いたしません。</li>
                        <li>会社は次のような方法で個人情報を収集します。
                            <ul className="sub_list">
                                <li>- 会社とサービス提供に関する提携関係にあるプラットフォーム事業者を介した収集</li>
                                <li>- サービス加入や使用中のユーザーの自発的な提供を介した収集</li>
                                <li>- ユーザーが会社の提供するサービスを利用することによって自動的に収集</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第2条　個人情報の収集及び利用目的</h4>
                    <ul>
                        <li>ゲームの基本機能の提供
                            <ul className="sub_list">
                                <li>- ユーザーアカウントの固有識別番号を介してプラットフォーム事業者から提供された端末固有番号(デバイスID又はUUID)を収集し保存して、ユーザーアカウントが正常かを識別するための目的</li>
                            </ul>
                        </li>
                        <li>サービス提供に関する契約履行及びサービス提供に伴う料金精算
                            <ul className="sub_list">
                                <li>- 無料又は有料コンテンツの提供、購入を介した物品配送又は請求書などの発送、本人認証、年齢認証、購入及び料金決済、精算、料金徴収の目的</li>
                            </ul>
                        </li>
                        <li>ユーザーの管理
                            <ul className="sub_list">
                                <li>- サービスの利用に伴う本人確認、ゲームサービスの提供に関連したユーザー確認、個人識別、不良ユーザーの不正利用防止と非認可使用の防止、加入意思の確認、加入と加入回数の制限、本人確認及び紛争調停のための記録保存、不満処理などの苦情処理、お知らせ伝達の目的</li>
                            </ul>
                        </li>
                        <li>新規サービスの開発及びマーケティング/広告への活用
                            <ul className="sub_list">
                                <li>- 新規サービスの開発及びオンデマンドサービスの提供、統計学的な特性によるサービスの提供及び広告掲載、サービスの有効性確認、イベント及び広告性情報の提供及び参加機会の提供、アクセス頻度の把握、ユーザーのサービス利用に対する統計の目的</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                                
                <div className="terms_box">
                    <h4>第3条　収集した個人情報の共有及び提供</h4>
                    <ul>
                        <li>会社は、ユーザーの個人情報を「個人情報の収集目的及び利用目的」で告示した範囲内で使用し、ユーザーの事前同意無くしては同範囲を超えて利用できず、原則的にユーザーの個人情報を外部に公開したり、第三者に提供するものではありません。ただし、次の各場合には例外とします。
                            <ul className="sub_list">
                                <li>- ユーザーが事前に同意した場合</li>
                                <li>- 統計作成の目的で、個人を特定できない形で個人情報を加工して提供する場合</li>
                                <li>- その他法令の規定に基づいたり、捜査目的で法令に定められた手続きと方法によって捜査機関の要求がある場合</li>
                            </ul>
                        </li>
                        <li>会社が様々なサービスを提供する目的で、制限のある範囲内で事業提携会社にユーザーの情報を提供する必要がある場合、会社はユーザーの情報を提供したり共有する者が誰か、提供又は共有される個人情報項目が何か、個人情報を提供したり共有する目的が何か、などについて、事前にユーザーから同意を得た後にそれを提供又は共有します。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第4条　個人情報取扱の委託及び国外移転</h4>
                    <ul>
                        <li>会社は、原則的にユーザーの個人情報の取扱いなどを外部に委託するものではありません。</li>
                        <li>第1項にもかかわらず、会社は円滑な個人情報業務処理のために、ユーザーの個人情報処理業務のうち一部を次のとおり外部に委託でき、委託時に関係法令に従って個人情報が安全に管理されるようにします。
                            <ul className="sub_list">
                                <li>- 受託会社:決済マーケット、決済代行会社(タイ/ベトナムのみ該当)</li>
                                <li>- 業務内容:決済代行及び本人認証</li>
                                <li>- 期間:サービス退会又は委託契約終了時</li>
                            </ul>
                        </li>
                        <li>会社は、ユーザーの同意無しにユーザーの個人情報を国外に移転するものではありません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第5条　個人情報の保有及び利用期間</h4>
                    <p>ユーザーの個人情報は、原則的に個人情報の収集及び利用目的が達成されれば遅滞なく破棄します。ただし、次の情報については以下の理由で明示した期間中保存します。</p>
                    <ul>
                        <li>1. ユーザーからの撤退要求があった場合もしくは個人情報担当者に破棄を求めた場合、会社内部の方針によってユーザーの個人情報は直ちに完全削除されます。
ただし、関係法令の規定により一定期間情報を保存する必要がある場合、会社は関係法令で指定された期間、個人情報を保管し、本情報を他の目的に利用しません。各、項目別保存期間は本条４項に従います。
</li>
                        <li>ユーザーに対して関係法令違反による捜査･調査などが進行中の場合、該当の捜査･調査が終了する時まで該当ユーザーの情報を保存します。</li>
                        <li>会社とユーザー間に債権･債務関係が残存する場合、該当の債権･債務関係の清算が完了する時まで該当ユーザーの個人情報を保存します。</li>
                        <li>商法や電子商取引などにおける消費者保護に関する法律などの関係法令の規定により保存する必要がある場合、会社は関係法令で定めた一定の期間、ユーザー情報を保管します。その場合、会社は保管する情報をその保管の目的にだけ利用し、保存期間は以下のとおりです。
                            <ul className="sub_list">
                                <li>- 契約又は契約キャンセルなどに関する記録(保存理由 :電子商取引などにおける消費者保護に関する法律、保存期間 :5年)</li>
                                <li>- 代金決済及び財貨などの供給に関する記録(保存理由 :電子商取引などにおける消費者保護に関する法律、保存期間 :5年)</li>
                                <li>- 消費者の不満又は紛争処理に関する記録(保存理由 :電子商取引などにおける消費者保護に関する法律、保存期間 :3年)</li>
                                <li>- 電気通信日時、開始･終了時間、加入者番号、使用度数、発信基地局の位置追跡資料に関する記録(保存理由 :通信秘密保護法、保存期間 :1年)</li>
                                <li>本人確認に関する記録(保存理由 :情報通信網の利用促進及び情報保護等に関する法律、保存期間 :6ヵ月)</li>
                                <li>表示･広告に関する記録(保存理由 :電子商取引などにおける消費者保護に関する法律、保存期間 :6ヵ月)</li>
                                <li>訪問に関する記録(保存理由 :通信秘密保護法、保存期間 :3ヵ月)</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第6条　個人情報破棄手続き及び方法</h4>
                    <p>ユーザーの個人情報は、原則的に個人情報の収集及び利用目的が達成されれば遅滞なく破棄します。会社の個人情報破棄手続き及び方法は次のとおりです。</p>
                    <ul>
                        <li>破棄手続き
                            <ul className="sub_list">
                                <li>- ユーザーがサービス加入などのために入力した情報は、目的が達成された後別途のDBに移され、内部方針とその他関連法令による情報保護事由に基づき(保有及び利用期間参照)、一定期間保存された後破棄されます。</li>
                                <li>- 個人情報は、法律による場合でなければ、保有以外の他の目的で利用されるものではありません。</li>
                            </ul>
                        </li>
                        <li>破棄方法
                            <ul className="sub_list">
                                <li>- 紙に出力された個人情報は、シュレッダーで細断したり焼却して破棄します。</li>
                                <li>- 電子的なファイルの形で保存された個人情報は、記録を再生できない技術的方法を使用して削除します。</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第7条　ユーザー及び法定代理人の権利とその行使方法</h4>
                    <ul>
                        <li>会社は、満14歳未満のユーザーの個人情報収集･利用･提供に対し、その法定代理人から同意を得ます。その場合、会社は必要な範囲内で法定代理人の氏名など最小限の情報提供を要請できます。</li>
                        <li>ユーザー及び法定代理人はいつでも、登録されている自分或いは当該の満14歳未満の児童であるユーザーの個人情報を、プラットフォーム事業者やオープンマーケット事業者を介して照会したり修正でき、アプリのログアウトと削除を介してサービス解消を要請できます。</li>
                        <li>ユーザーが、会社で保有していない自分の個人情報を確認するためには、会社がサービスを提供するプラットフォーム事業者又はオープンマーケット事業者から加入内訳の確認と個人情報の提供を受け、それを会社に記録された情報と対照して該当ユーザーが本人であることを確認できなければなりません。</li>
                        <li>会社は、ユーザーから個人情報の間違いに対する訂正を要請された場合、訂正が完了するまで当該の個人情報を利用又は提供するものではありません。また、間違った個人情報を第三者にすでに提供した場合、会社は遅滞なく第三者に訂正処理の結果を通知して訂正されるようにします。</li>
                        <li>会社は、ユーザー或いは法定代理人の要請によって解消又は削除されたその個人情報を、「第5条　個人情報の保有及び利用期間」に明示されたところに従って処理し、それ以外の用途で閲覧又は利用できないように処理しています。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第8条　個人情報の技術的/管理的保護ポリシー</h4>
                    <p>会社は、ユーザーの個人情報を取扱うにあたって、個人情報が紛失、盗難、漏出、変造又は毀損されないように、安全性を確保するために次のような技術的/管理的対策を講じています。ただし、会社はユーザーの個人情報が伝達される過程、或いはハッカーなどの第三者の攻撃によって上の個人情報が流出又は変質した部分について、100％の保護を保障することはできません。</p>
                    <ul>
                        <li>ハッキングなどに備えた対策
                            <ul className="sub_list">
                                <li>- 会社は、ハッキングやコンピュータウイルスなどによってユーザーの個人情報が流出したり毀損することを防ぐために最善を尽くしています。個人情報の毀損に備えて資料を随時バックアップし、ユーザーの個人情報や資料が漏出したり損傷しないように予防しており、暗号化通信などを介してネットワーク上で個人情報を安全に転送できるようにしています。そして、侵入遮断システムを利用して外部からの無断アクセスをコントロールしており、その他システム的にセキュリティを確保するため、できる限りのあらゆる技術的装置を備えるために努力しています。</li>
                            </ul>
                        </li>
                        <li>取扱い社員の最少化及び教育
                            <ul className="sub_list">
                                <li>- 会社の個人情報に関する取扱社員は担当者に限定しており、そのための別途のパスワードを付与し定期的に更新しています。担当者に対し随時教育を行ってActgamesの個人情報取扱方針の遵守を常に強調しています。</li>
                            </ul>
                        </li>
                        <li>内部管理計画の樹立及び施行
                            <ul className="sub_list">
                                <li>- 会社は、個人情報を安全に処理するために内部管理計画を樹立して施行しています。</li>
                            </ul>
                        </li>
                        <li>個人情報保護専担メカニズムの運営
                            <ul className="sub_list">
                                <li>- 会社は、社内の個人情報保護専担メカニズムなどを介して、Actgamesの個人情報取扱方針の履行事項と担当者の遵守内容を確認し、問題が発見された場合はすぐに修正して改められるように努力しています。ただし、ユーザー本人の不注意又は会社の故意、又は重大な過失ではない事由で個人情報が流出して発生した問題について、会社は一切の責任を負うものではありません。</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第9条　個人情報管理責任者</h4>
                    <p>会社は、顧客の個人情報を保護して個人情報に関連した不満を処理するために、以下のような個人情報管理責任者を指定しています。</p>
                    <ul>
                        <li>[個人情報管理責任者]
                        <br/>所属/職位: Director of Business
                        <br/>氏名: Hyukmin Ma
                        <br/>電話番号: +827041179408
                        <br/>E-mail: support@actgames.co.kr</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第10条　告示の義務</h4>
                    <p>会社は、個人情報取扱方針の内容追加、削除又は修正がある場合は、最低7日前にお知らせを介してユーザーに告示します。</p>
                </div>
                                
                <div className="terms_box">
                    <h4>2021年09月27日　施行</h4>
                </div>
            </div>
        </div>
    </>
  );
}

export default Jpn_privacy;
