import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';



function Footer(props) {

    return (
        <footer className="Footer">
            <div className="FooterWrap">
                <div className="FooterLogoWrap">
                    <Link to="/" className="Flogo"><img src="https://data.actgames.co.kr/actgames/images/common/actgames_logo.png" /></Link>
                </div>
                <div className="TermsBtn">
                    <Link to={props.translation('footer_termsLinkURL')} target="_blank">Terms of Service</Link>
                    <Link to={props.translation('footer_PrivacyLinkURL')} target="_blank">Privacy Policy</Link>
                    {/* <Link to={props.translation('footer_GamePolicyLinkURL')} target="_blank">Game Policy</Link> */}
                    {/* <Link to="" target="_blank">Support</Link> */}
                </div>
                <div className="FooterBtnWpap">
                <Link to={props.translation('footer_appleLinkURL')} target="_blank"><img src="https://data.actgames.co.kr/actgames/images/common/button_apple.png" /></Link>
                <Link to={props.translation('footer_googleLinkURL')} target="_blank"><img src="https://data.actgames.co.kr/actgames/images/common/button_google.png" /></Link>
                </div>
            </div>
            <div className="CopyRightWrap">
                <p className="CopyRight">© ACT Games Co., Ltd. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;

