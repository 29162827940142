import React from "react";
import '../css/terms.css';

function Jpn_service_mogmog() {
  return (
    <>
        <div className="terms sub">
            <div className="container">
                <h3 className="sub_title">サービス通貨ガイドライン 「シャクレルプラネット」</h3>
                <hr className="star-primary wow zoomIn"></hr>
                <div className="terms_box">
                    <p>本ガイドラインは、「シャクレルプラネット：Match 3 Puzzle」(以下「本サービス」といいます）を通じて当社が発行する仮想通貨「ダイヤ」（以下、「サービス内通貨」といいます）についての購入または利用の条件を定めるものです。なお、本ガイドラインは「利用規約」と共に重畳的に適用され、利用規約の一部を構成します。利用者は、本ガイドラインに同意することを前提として、仮想通貨を購入または利用することができます。</p>
                </div>
                
                <div className="terms_box">
                    <h4>1. サービス内通貨とは</h4>
                    <ul>
                        <li>サービス内通貨は、利用者が購入し、または当社が無償で付与するものがあります。</li>
                        <li>サービス内通貨は、本サービス以外で利用すること、返品または現金に交換すること、当社が発行する他の仮想通貨やポイント等へ合算または交換すること、ならびに利用者間で共有、合算及び譲渡することはできません。</li>
                    </ul>
                </div>
                                
                <div className="terms_box">
                    <h4>2. サービス内通貨の購入</h4>
                    <ul>
                        <li>利用者は、本サービス内の購入確認画面にある表示金額（税込）で、サービス内通貨を購入することができます。</li>
                        <li>利用者は、サービス内通貨の購入代金を、当社および決済代行業者が定める方法で、支払うものとします。</li>
                        <li>20歳未満の利用者は、サービス内通貨の購入時に、必ず親権者の同意を得た上でサービス内通貨を購入してください。</li>
                        <li>サービス内通貨は、本サービス内においてのみ使用することができます。ただし、OSが異なる端末間での共通利用はできません。</li>
                        <li>サービス内通貨の購入、利用及び本サービスの利用に伴い、税金や附帯費用が発生する場合には、利用者がこれらを負担するものとします。</li>
                        <li>利用者と決済代行業者との間で利用料金等を巡って紛争が生じた場合、当該当事者間で解決するものとし、当社は一切責任を負わないものとします。</li>
                        <li>利用者がサービス内通貨を購入した代金決済について、領収書等は発行いたしません。</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>3. サービス内通貨の付与</h4>
                    <p>サービス内通貨を購入後、利用者に付与されるまで、ご利用の端末から本サービスをアンインストールしないようにご注意ください。</p>
                </div>        
                
                <div className="terms_box">
                    <h4>4. 利用停止等</h4>
                    <p>当社は、利用者が利用規約または本ガイドラインに違反したことにより、利用者に対する本サービスの一部または全部の提供を停止し、利用者アカウントの利用を停止及び削除し、または退会等の措置をとった場合、利用者が保有するサービス内通貨の失効、利用停止等の処分をすることができるものとします。</p>
                </div>
                
                <div className="terms_box">
                    <h4>5. サービス内通貨の有効期限</h4>
                    <p>利用者が保有するサービス内通貨に有効期限はありません。</p>
                </div>
                
                <div className="terms_box">
                    <h4>6. サービス内通貨の払い戻しについて</h4>
                    <ul>
                        <li>当社は、法令により払い戻しをするべき場合を除き、理由のいかんを問わずサービス内通貨の払い戻しを行わないものとします。</li>
                        <li>法令の定めに従い、サービス内通貨の払い戻しを行う場合は、本サービス及び当社運営のウェブサイト等で、払い戻しの事実と払い戻し方法の公示等を行い、払い戻しの手続きを行うものとします。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>7. 本ガイドラインの改訂について</h4>
                    <ul>
                        <li>当社は、利用者に対し事前に通知しまたは利用者から承諾を得ることなく、いつでも任意に、本ガイドラインを改訂することができるものとします。変更後の規約は、本サービスまたは当社の別途指定するウェブサイト上に掲示するものとし、当該掲示がなされた時点で効力を生じるものとします。</li>
                        <li>利用者は、本ガイドラインの変更後に本サービスを利用した場合、変更後の本ガイドラインに同意したものとみなされます。仮に変更後の本ガイドラインに同意できない場合、直ちに本サービスの利用を中止するものとします。</li>
                    </ul>
                </div>
                                
                <div className="terms_box">
                    <h4>2021 年 09月 27日 制定</h4>
                </div>
            </div>
        </div>
    </>
  );
}

export default Jpn_service_mogmog;
