import React from "react";
import '../css/terms.css';

function Eng_service() {
  return (
    <>
        <div id="provision" className="terms sub">
            <div className="container">
                <h3 className="sub_title">Game Policy</h3>
                <hr className="star-primary wow zoomIn"></hr>
                <div className="terms_box">
                    <h4>Article. 1 (Purpose)</h4>
                    <ul>
                    <li>This Game Policy has its purpose for ACT GAMES Co., Ltd. (“the company”) to provide all players with all the information regarding the game service and the related.</li>
                    <li>The company shall do the utmost to provide players with the pleasant environment and stabilized game service based on this policy.</li>
                    <li>The company may change the Game Policy if needed, to maintain a stable game service, and any change in the policy will be posted in the community sites (Official SNS, Plug Communities) or the application. Any players shall find the Game Policy anytime to keep away from any possible disadvantages when using the game service.</li>
                    <li>Any matters that are against this Game Policy must follow the ‘Terms of Service’ and the others shall follow the related legislation or the business usage.</li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>Article. 2 (Rights and Duties for Users)</h4>            
                    <ul>
                        <li>The Rights of Users
                            <ul className="sub_list">
                                <li>1) Users may utilize various contents and services related to the game provided by the company free of charge or with charge.</li>
                                <li>2) Users can inquire, suggest, and make any claim to the company regarding any trouble related to the game service</li>
                                <li>3) Users may ask the company to take a proper action against other users who harmed and violated the game policy or have any trouble in using the game service. The company shall take a proper measure to those players based on this Game Policy and the Terms of Service.</li>
                                <li>4) Users can contact the in-game Customer Center for any inquiries regarding the Game Policy set forth by the company including any troubles or infringement of rights.</li>
                            </ul>
                        </li>
                        <li>Duties of Users.
                            <ul className="sub_list">
                                <li>1) Users shall not engage in any conduct contrary to the company’s Terms of Service, Game Policies, applicable laws and social common norms, which may influence the stability of game services or violate the rights of other users using the company’s game services. The company does not have any obligation to be responsible for any unfavorable situations aroused by users’ behaviors against the Terms in documentations. If any occasion that any user encounters any disadvantage due to reasons attributable to the user, which the user apparently violated the terms, the company can claim civil or criminal liability against the user.</li>
                                <li>2) Users shall not commit a breach of such duties that cause any hindrance to the game service or act as a drag on other players game play and shall cooperate to Game Manager’s requests for consultation.</li>
                                <li>3) Users shall promptly notify Game Managers when facing Bugs and any other problems in game. Users shall not perform any misbehaviors that presents mental or economical infringement to other users by transmitting or spreading any information about such bugs. If violated, users may interact certain punishment set by the Game Policy and other legislations depending on the seriousness of the fault.</li>
                                <li>4) Any activity involving the use, distribution, sale, processing, or disposal of any illegal software or hardware that affects the game other than the gaming client proven by the company may be subject to the sanctions provided by this Game Policy and other laws.</li>
                                <li>5) All information contained in the game service and service is owned by the company and the users have the right to use the information within the service period. Therefore, any user activity shall not be made to obtain commercial benefits by using game services.</li>
                            </ul>				  
                        </li>
                    </ul>
                </div>  

                <div className="terms_box">
                    <h4>Article. 3 (Account Management Responsibilities)</h4>            
                    <ul>
                        <li>The responsibilities in managing one’s account and personal information are primarily upon the respective user. The company shall not be held liable in the event of an infringement caused by user’s negligence in management, deliberate sharing of personal information and accounts, or transfer of his/her account other than the apparent reasons attributable to the third party. In this case, protection over user’s rights can be limited.</li>
                        <li>The company shall not approve any account that is shared or traded with or the personal information leaked by the mentioned activity under any circumstances. User’s rights to use the game can be limited without warning if users had attempted to trade the accounts, share it with others, or make deals with the accounts or characters in cash or other items.</li>
                        <li>The company shall not approve any account that is shared or traded with, or the personal information leaked by the mentioned activities under any circumstances. User’s rights to use the game can be limited without warning if one had attempted to trade the account, share it with others, or make deals with the account or characters in cash or other items.</li>
                    </ul>
                </div>                              

                <div className="terms_box">
                    <h4>Article. 4 (Policy for Names)</h4>
                    <ul>
                        <li>‘Name’ defines every title that users designate and input such as the Account Nickname or Guild name used in the game.</li>
                        <li>Users essentially have freedom to choose their nick name.</li>
                        <li>Notwithstanding the 2nd clause, the following names are restricted to use and may be changed by the company without prior notice. (Restrictions may apply to actions using the following names)
                            <ul className="sub_list">
                                <li>1) A name used directly or indirectly as a known name, trademark, name, logo, etc., which is likely to violate the trademark, copyright or ownership of a third party.</li>
                                <li>2) A name that is believed as intended to impersonate a company or its employees.</li>
                                <li>3) Names that can confuse other users such as Kings Raid (The title of the game), Name of NPCs and item names in game.</li>
                                <li>4) Names that include names that symbolize or represent swearing, slang, lewdness, sensationalism, and gambling.</li>
                                <li>5) Names that are antisocial or against relevant laws.</li>
                                <li>6) Names that contain advertising/PR for a specific target</li>
                                <li>7) Names that include other businesses and organizations</li>
                                <li>8) Names that can be provocative, obscene, or suggestive to others.</li>
                                <li>9) Names that can encourage or have the intention of doing business over items and accounts on spot sale or transaction.</li>
                                <li>10) Name of the content that disgraces a nationality, race, religion, person, gender, disability, etc.</li>
                                <li>11) Some modification of the names prohibited, in a different shape of spelling or a combination of different characters which means the same as the names prohibited above.</li>
                                <li>12) Names deemed necessary to be changed by other companies</li>
                            </ul>				  
                        </li>
                    </ul>
                </div>                

                <div className="terms_box">
                    <h4>Article. 5 (Duties and Roles of Game Managers)</h4>
                    <ul>
                    <li>Game Managers play the role in helping users to use the service.</li>
                    <li>The Game management complies with the Game policy, Terms of Service, and Privacy Policy.</li>
                    <li>The managers shall not ask the customer for any personal information such as password and modify or leak the customer's personal information at any case. However, if there is a formal request from the judicial institution, it can be provided to the institution concerned.</li>
                    <li>Game Managers shall respond to customer inquiries and requests as quickly and kindly as possible. However, if it is deemed difficult to conduct the game operation or to violate the Game policy, an objective judgment and followed action may be made.</li>
                    <li>Game Managers shall be informed of all types of bugs that may occur to users, and the company is obliged to identify and modify them. Users are requested to report any type of bug to the management team upon discovery.</li>
                    <li>If an item or a character is lost, or any in-game information is changed due to a technical error, objective data and information can be recovered within the scope of being identified.</li>
                    <li>Game Managers can take any action described in this game policy against customers or groups of customers that violate the game policy.</li>
                    <li>Game Managers may limit some or all the game service provided to a user in case of abnormalities within or outside the game.</li>
                    <li>Any user can be restricted with the game service if one impersonates or engages the role of Game Managers including mediation of conflicts in operating the game.</li>
                    <li>To create a sound gaming culture, Game Managers can edit, move, and delete postings without prior notice in accordance with the game policy, and in some cases, can restrict the use of the website.</li>
                    </ul>
                </div>                

                <div className="terms_box">
                    <h4>Article. 6 (Restrictions to the Use of Game Service)</h4>
                    <ul>
                        <li>The company implements a policy limiting the use of pleasant services to the members and limits the use of the service if the members act in accordance with the policy.</li>
                        <li>All other actions that are deemed to be in violation of the general rules, including the Terms of Service and Game policies, or that may pose a serious threat to the maintenance of pleasant game services, may be subject to warnings and account sanctions.
                        
                            <div className="terms_table" style={{color:"black"}}>
                                <table>
                                <thead>
                                    <tr>
                                        <th width="">Types of misbehaviors</th>
                                        <th width="">Description</th>
                                        <th width="8%">1st</th>
                                        <th width="8%">2nd</th>
                                        <th width="8%">3rd</th>
                                        <th width="8%">4th</th>
                                        <th width="8%">5th</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Curse/Slander</td>
                                        <td>An act to express abuse, derogatory remarks, insult sexual expression, unconditional slander etc.</td>
                                        <td>Warning (Chat-ban 2hr)</td>
                                        <td>Warning (Chat-ban 24hrs)</td>
                                        <td>Warning (Chat-ban 72hrs)</td>
                                        <td>1day</td>
                                        <td>3days</td>
                                    </tr>
                                    <tr>
                                        <td>Chat Spamming</td>
                                        <td>Spamming meaningless or identical text continuously in a chat (more than three times)</td>
                                        <td>Warning (Chat-ban 2hr)</td>
                                        <td>Warning (Chat-ban 24hrs)</td>
                                        <td>Warning (Chat-ban 72hrs)</td>
                                        <td>1day</td>
                                        <td>3days</td>
                                    </tr>
                                    <tr>
                                        <td>Using Inappropriate in Game Name</td>
                                        <td>Use of names that are inappropriate or violated to the Game policy</td>
                                        <td>Warning (Nickname Change)</td>
                                        <td>7days</td>
                                        <td>15days</td>
                                        <td>30days</td>
                                        <td>Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Disturbance</td>
                                        <td>An act to prevent other members from using the game service </td>
                                        <td>1day</td>
                                        <td>7days</td>
                                        <td>15days</td>
                                        <td>30days</td>
                                        <td>Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Inference in Game Management</td>
                                        <td>
                                        <ul className="table_list_style">
                                        <li>Spreading false information or causing confusion among other users in other unjust ways </li>
                                        <li>Disturbing other general game operation</li>
                                        </ul>
                                        </td>						
                                        <td>7days</td>
                                        <td>30days</td>
                                        <td colSpan="3">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Fraud</td>
                                        <td>An act that deceives other player to attempt to earn unfair advantage in game.</td>
                                        <td>7days</td>
                                        <td>30days</td>
                                        <td colSpan="3">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Cash Transaction between Users</td>
                                        <td>
                                        <ul className="table_list_style">
                                        <li>An act to sell in game items or in game money and exchange them for cash or other game item, or attempt to do so</li>
                                        <li>An act to share accounts and characters, to sell or exchange them for cash or in kind</li>
                                        </ul>						
                                        </td>
                                        <td>7days</td>
                                        <td>30days</td>
                                        <td colSpan="3">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Impersonation of Game Managers and the Company</td>
                                        <td>An act of impersonation of Game Master, Staff, or any one related to the Company</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Advertisements for beneficial or business purpose</td>
                                        <td>Any conduct of commercial advertising to pursue profit outside of games</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Account Theft</td>
                                        <td>An act to access another person's account and illegally use, transfer, or delete an account or item</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Abuse</td>
                                        <td>An act of abusing or taking advantage of any activity such as circumventing designed constraints of the game system in an abnormal way</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Application modification</td>
                                        <td>Dissemination of applications that are forged and modulated false or modified</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Use or Dissemination of Unofficial Program</td>
                                        <td>Abnormal use or dissemination of unofficial programs unproven by the company</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>
                                    <tr>
                                        <td>Bug Misuse and Dissemination</td>
                                        <td>An act of deliberately and habitually using bugs to benefit or harm other users and to distribute them</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>		
                                    <tr>
                                        <td>Personal Information Leakage</td>
                                        <td>Disclosure of personal information of other users without consent</td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>		
                                    <tr>
                                        <td>Abnormal Payment</td>
                                        <td>
                                        <ul className="table_list_style">
                                            <li>An act to purchase in-game items or using paid services by using another person's payment method without permission</li>
                                            <li>Exploitation of payment or refund policies provided by the company or the platform provider to make unfair profits</li>
                                        </ul>						  
                                        </td>
                                        <td colSpan="5">Permanent Ban</td>
                                    </tr>					  
                                    </tbody>
                                </table>
                            </div>
                        
                        <ul className="sub_list">
                        <li>1) If applicable to the above items, the user may be restricted under the judgment of the game management and the company's terms and conditions if it harms balance of the game or influence negatively on other users' progress.</li>
                        <li>2) If the cases are repeated or considered to be severe or intended, the restrictions may be added.</li>
                        <li>3) Aside the restrictions, any game money or items acquired by users for any punishable act will be deleted or recalled and game data including character information can be reset.</li>
                        </ul>
                        </li>
                    </ul>
                </div>   

                <div className="terms_box">
                    <h4>Article. 7 (Recovery Policy)</h4>
                    <ul>
                        <li>Recovery can be delivered when the information and source can be identified without any significant influence on the company’s system and the data. Also, it should not be affecting the balance of the game significantly.</li>
                        <li>If a user wishes to recover anything he or she had lost must submit a request to the company through the customer center within 14 days from the occurrence of the problem.</li>
                        <li>The loss or alteration of the game information followed cannot be recovered.
                        <ul className="sub_list">
                            <li>1) Items not verified based on objective data held by the company</li>
                            <li>2) Damage caused by not being familiar with the information provided through the terms and conditions, game policies, official community (formal SNS, plug cafe, etc.) or announcements in the game</li>
                            <li>3) Intentional damage or any damage cause be negligence of members of the game service.</li>
                            <li>4) Damage caused by activities that is against the game policy and the Terms of Service.</li>
                            <li>5) Damage caused by change in device, phone number, or reset</li>
                            <li>6) Damage caused by using game services not confirmed by the company, such as item or account transactions</li>
                            <li>7) Damage caused by one’s poor management of personal information and account information while using the game service.</li>
                        </ul>
                        </li>
                    </ul>
                </div>    

                <div className="terms_box">
                    <h4>Article. 8 (Community Management)</h4>
                    <ul>
                        <li>The company's community (chats, bulletin boards) and community sites (SNS, plug cafes, etc.) are designed to share various information, general discussions, and suggestions for the company and the game.</li>
                        <li>Based on this policy, the company creates a pleasant community and strives to provide stable community service.</li>
                        <li>Matters not specified and the interpretations of components in this policy shall be subject to the Terms of Service and others shall be under control of the relevant laws and regulations.</li>
                        <li>Postings subject to each of the following may be deleted or modified without prior notice and may be restricted if repeated complaints are made from other users. In addition, further use of the service may be restricted according to the Terms of Service, even if the conditions do not apply specifically to the following items.
                            <ul className="sub_list">
                                <li>1) Posts that violate social order and other general standards</li>
                                <li>2) Posts containing obscene words and vulgar expressions</li>
                                <li>3) A commercial article (Advertisements, Promotion, and Spamming Articles)</li>
                                <li>4) Posts that spread false information not notified through company notice causing confusion in the use of service of other users'</li>
                                <li>5) Posts containing any subject about race, religion, sex, gender, disability, ethnic discrimination and prejudice</li>
                                <li>6) Posts impersonating company and management</li>
                                <li>7) Post containing content related to forgery and modification of the application</li>
                                <li>8) Posts related to the production, sharing and distribution of unofficial programs</li>
                                <li>9) Posts related to cash/ spot transactions</li>
                                <li>10) Posts that requires or expose personal or other’s information</li>
                                <li>11) Posts of harmful sites</li>
                                <li>12) Posts that defame other people’s reputation, shows any curses and slander</li>
                                <li>13) Posts that are antisocial or against relevant laws</li>
                                <li>14) Posts that infringes upon another’s copy right and intellectual property</li>
                                <li>15) Posts that obstruct or slander the company's game management</li>
                            </ul>
                        </li>
                        <li>Users should respect other people's rights, such as the copyright of the third party. Any use of sound sources, images, videos and printed publications without prior permission of the copyright holder shall be deemed to be copyright infringement under the current copyright law. Therefore, if you find any relevant information regarding the copyright infringement, please let the company know to take proper action.</li>
                    </ul>
                </div> 

                <div className="terms_box">
                    <h4>Article. 9 (Dormant Accounts)</h4>
                    <ul>
                        <li>The company may delete a customer's account or character if it meets all the following requirements to prevent it from being used for unfair purposes and to provide a smoother service.
                        <ul className="sub_list">
                            <li>1) In case the accounts or characters’ log-in history hasn’t been existed for more than 12 months</li>
                            <li>2) In case there is any paid item in the account mentioned in 9 - 1) or there is no paid item that has remaining period in the account</li>
                        </ul>
                        </li>
                        <li>In case the account or character information in the game is about to be deleted after being classNameified as dormant, the company notifies the user about the schedule and contents of game information in advance</li>
                    </ul>
                </div>                
                

                <div className="terms_box">
                    <h4>Subsidiary Clause</h4>
                    <p>This game policy will be active after September 27th 2021.</p>
                </div>                
                
            </div>
        </div>        
    </>
  );
}

export default Eng_service;
