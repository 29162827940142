import React, {useEffect , useState} from "react";
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Header from './common/Header';
import Footer from './common/Footer';
import Home from './page/Home';
import NotFound from './page/NotFound';
import Careers from './page/Careers';
import News from './page/News';
import Games from './page/Games';
import ZoidsWildArea from './page/ZoidsWildArea';
import BubsPuzzleBlast from './page/BubsPuzzleBlast';
import Aggretsuko from './page/Aggretsuko';
import PekoPop from './page/PekoPop';
import Mogmog from './page/Mogmog';
import Kitty from './page/Kitty';
import About from './page/About';
import NewsDetail from './page/NewsDetail';

import './css/settings.css';
import { Translation } from "react-i18next";

import Coupon from './page/Coupon';
import Eng_privacy from "./terms/Eng_privacy";
import Eng_service from "./terms/Eng_service";
import Eng_provision from "./terms/Eng_provision";
import Jpn_commerce from "./terms/Jpn_commerce";
import Jpn_privacy from "./terms/Jpn_privacy";
import Jpn_provision from "./terms/Jpn_provision";
import Jpn_payment_agg from "./terms/Jpn_payment_agg";
import Jpn_payment_peko from "./terms/Jpn_payment_peko";
import Jpn_payment_mogmog from "./terms/Jpn_payment_mogmog";
import Jpn_payment_bub from "./terms/Jpn_payment_bub";
import Jpn_service_agg from "./terms/Jpn_service_agg";
import Jpn_service_peko from "./terms/Jpn_service_peko";
import Jpn_service_mogmog from "./terms/Jpn_service_mogmog";
import Jpn_service_bub from "./terms/Jpn_service_bub";
import Kor_provision from "./terms/Kor_provision";
import Kor_privacy from "./terms/Kor_privacy";
import Kor_service from "./terms/Kor_service";


function MainLayout() {
	return (
		<>
		 <Translation>{t => <Header translation={t} />}</Translation>
			<Outlet></Outlet>
		 <Translation>{t => <Footer translation={t} />}</Translation>
		</>
	)
}



function App() {
	
  return (
	  <>
		  <BrowserRouter >
			  <Routes>
				  <Route element={<MainLayout />}>
					  <Route path="/" element={<Translation>{t => <Home translation={t}/>}</Translation>} />
					  <Route path="*" element={<Translation>{t => <NotFound translation={t} />}</Translation>} />
					  <Route path="/news" element={<Translation>{t => <News translation={t} />}</Translation>} />
					  <Route path="/news/*" element={<Translation>{t => <NewsDetail translation={t} />}</Translation>} />
					  <Route path="/careers" element={<Translation>{t => <Careers translation={t} />}</Translation>} />
					  <Route path="/games" element={<Translation>{t => <Games translation={t} />}</Translation>}></Route>
					  <Route path="/games/zoidswildarea" element={<Translation>{t => <ZoidsWildArea translation={t} />}</Translation>} />
					  <Route path="/games/bubspuzzleblast" element={<Translation>{t => <BubsPuzzleBlast translation={t} />}</Translation>} />
					  <Route path="/games/aggretsuko" element={<Translation>{t => <Aggretsuko translation={t} />}</Translation>} />
					  <Route path="/games/pekopop" element={<Translation>{t => <PekoPop translation={t} />}</Translation>} />
					  <Route path="/games/mogmog" element={<Translation>{t => <Mogmog translation={t} />}</Translation>} />
					  <Route path="/games/kitty" element={<Translation>{t => <Kitty translation={t} />}</Translation>} />
					  <Route path="/about/" element={<Translation>{t => <About translation={t} />}</Translation>} />
				  </Route>

				  <Route path="/coupon/:GameName/:LangId" element={<Coupon />} />

				  <Route exact={true} path="/eng/sub/privacy.php" element={<Eng_privacy/>} />
				  <Route exact={true} path="/eng/sub/provision.php" element={<Eng_provision/>} />
				  <Route exact={true} path="/eng/sub/service.php" element={<Eng_service/>} />

				  <Route exact={true} path="/jpn/sub/privacy.php" element={<Jpn_privacy/>} />
				  <Route exact={true} path="/jpn/sub/provision.php" element={<Jpn_provision/>} />
				  <Route exact={true} path="/jpn/sub/commerce.php" element={<Jpn_commerce/>} />
				  <Route exact={true} path="/jpn/sub/payment_agg.php" element={<Jpn_payment_agg/>} />
				  <Route exact={true} path="/jpn/sub/payment_bub.php" element={<Jpn_payment_bub/>} />
				  <Route exact={true} path="/jpn/sub/payment_mogmog.php" element={<Jpn_payment_mogmog/>} />
				  <Route exact={true} path="/jpn/sub/payment_peko.php" element={<Jpn_payment_peko/>} />
				  
				  
				  <Route exact={true} path="/jpn/sub/service_agg.php" element={<Jpn_service_agg/>} />
				  <Route exact={true} path="/jpn/sub/service_bub.php" element={<Jpn_service_bub/>} />
				  <Route exact={true} path="/jpn/sub/service_mogmog.php" element={<Jpn_service_mogmog/>} />
				  <Route exact={true} path="/jpn/sub/service_peko.php" element={<Jpn_service_peko/>} />

				  <Route exact={true} path="/kor/sub/privacy.php" element={<Kor_privacy/>} />
				  <Route exact={true} path="/kor/sub/provision.php" element={<Kor_provision/>} />
				  <Route exact={true} path="/kor/sub/service.php" element={<Kor_service/>} />
			  </Routes>
		  </BrowserRouter>
	  </>
  );
}

export default App;
