import React from "react";
import '../css/terms.css';

function Jpn_payment_peko() {
  return (
    <>
        <div className="terms sub">
            <div className="container">
                <h3 className="sub_title">資金決済法に基づく表記 「Peko Pop!」</h3>
                <hr className="star-primary wow zoomIn"></hr>
                
                <div className="terms_box">
                    <h4>前払式支払手段の名称</h4>
                    <p>ゴールド</p>
                </div>        
                
                <div className="terms_box">
                    <h4>発行者の名称</h4>
                    <p>ACT GAMES Co., Ltd.</p>
                </div>
                                
                <div className="terms_box">
                    <h4>支払可能金額等</h4>
                    <p>ゴールドの購入限度額はありません</p>
                </div>
                
            <div className="terms_box">
                    <h4>有効期限</h4>
                    <p>ゴールドに有効期限はありません。</p>
                </div>
                
                <div className="terms_box">
                    <h4>苦情または相談窓口</h4>
                    <p>所在地：129-2, Samseong-dong, Gangnam-gu, Seoul, Republic of Korea 06157
                    <br/>連絡先： support@actgames.co.kr
                    </p>
                </div>
                
                <div className="terms_box">
                    <h4>使用できる施設または場所の範囲</h4>
                    <p>「Peko Pop : Match 3 Puzzle」内においてのみ使用することができます。ただし、OSが異なる端末間での共通利用はできません。</p>
                </div>
                
                <div className="terms_box">
                    <h4>利用上の注意</h4>
                    <p>ゴールドの払い戻しは行っておりません。
                    <br/>ご利用につきましては、「サービス内通貨ガイドライン」をご確認ください。</p>
                </div>
                
                <div className="terms_box">
                    <h4>未使用残高の確認方法</h4>
                    <p>未使用残高は、本サービス内の「設定→サポート→購入情報」画面等でご確認いただけます。</p>
                </div>
                
                <div className="terms_box">
                    <h4>約款等の存する旨</h4>
                    <p>「利用規約」、「サービス内通貨ガイドライン」をご参照ください。</p>
                </div>
                
            </div>
        </div>
    </>
  );
}

export default Jpn_payment_peko;
