import React, {useEffect , useState} from "react";
import '../css/notfound.css';
function NotFound() {

  return (
    <section className="NotFound">
      <div className="NotFoundWrap">
        <span>+_+</span>
        <h1>PAGE NOT FOUND</h1>
        <h6>There's nothing here</h6>
      </div>
      <div className="BGPattern100"></div>
    </section>
  );
}

export default NotFound;
