import React from "react";
import '../css/terms.css';

function Kor_service() {

  return (
      <div className="TermsWrap">
          <div id="privacy" className="terms sub">
              <div className="container">
                  <h3 className="sub_title">서비스 운영정책</h3>
                  <hr className="star-primary wow zoomIn"></hr>

                  <div className="terms_box">
                      <h4>제 1조 (목적)</h4>
                      <ul>
                          <li>본 운영정책은 ACT GAMES Co., Ltd.(이하 '회사')가 제공하는 모든 게임 및 제반 서비스를 모든 회원이 안정되고 원활하게 제공받을 수 있도록 하는데 그 목적을 두고 있습니다.</li>
                          <li>회사는 본 정책을 바탕으로 쾌적한 게임 환경을 조성하며 안정된 게임 서비스를 제공할 수 있도록 최선을 다합니다.</li>
                          <li>회사는 보다 안정적인 운영을 위해 수시로 운영정책을 변경할 수 있으며, 변경된 내용은 회사에서 제공하는 커뮤니티 사이트(공식 SNS, 카페) 또는 애플리케이션을 통하여 공지합니다. 회원은 수시로 운영정책을 확인하여 게임 이용 시 불이익을 당하지 않도록 주의해야 합니다.</li>
                          <li>본 정책에서 규정하지 않은 사항은 ‘서비스 이용약관’을 따르고, 그 외 사항에 대해서는 관계법령 및 상 관례를 따릅니다.</li>

                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>제 2조 (회원의 권리와 의무)</h4>
                      <ul>
                          <li>회원의 권리
                              <ul className="sub_list">
                                  <li>1) 회원은 회사에서 제공하는 게임 관련 각종 콘텐츠 및 서비스를 유상 또는 무상으로 받을 수 있습니다.</li>
                                  <li>2) 회원은 게임 이용 간에 발생되는 문제로 인한 문의, 건의, 이의 제기 등을 회사에 할 수 있습니다.</li>
                                  <li>3) 회원은 운영정책에 위배되는 행위를 한 다른 회원으로부터 피해를 보거나 원활한 게임 서비스를 이용하지 못하는 경우 회사에 조치를 취하도록 요청할 수 있습니다. 회사는 서비스 이용약관 및 운영정책에 제공하는 기준에 의거하여 적절한 조치를 취합니다.</li>
                                  <li>4) 회원은 게임 이용 시 발생하는 타인으로부터의 권리 침해, 기타 회사가 본 운영정책을 적용함에 따른 의문 사항에 대하여는 게임 내 고객센터를 통하여 문제사항에 대한 질의 및 해결 방법을 제공받을 수 있습니다.</li>
                              </ul>
                          </li>
                          <li>회원의 의무
                              <ul className="sub_list">
                                  <li>1) 회원은 회사의 게임 서비스의 안정적인 운영을 저해하거나, 회사 게임 서비스를 이용하는 다른 회원의 권리를 침해하는 것으로 간주되는 회사의 서비스 이용약관, 운영정책, 관련 법령 및 일반적인 사회 통념에 반하는 행동을 하여서는 안 됩니다. 이로 인하여 발생하는 문제에 대하여 회사는 책임을 지지 않으며, 약관, 운영정책, 법령에 반하는 명백한 회원의 귀책사유로 발생하는 손해에 대하여 경우에 따라 회사는 소정의 민·형사상 책임을 물을 수 있으니, 위반 행위 등이 발생하지 않도록 주의하여 주시기 바랍니다.</li>
                                  <li>2) 회원은 타인의 게임 진행을 방해하거나 기타 원활한 게임 운영에 장애를 초래하는 행위를 할 수 없으며, 운영진의 상담 및 요청에 적극적으로 협조하여야 합니다.</li>
                                  <li>3) 회원은 게임 이용 중 버그(Bug) 및 기타 문제점을 발견하였을 경우, 신속히 운영진에게 제보하여야 하며, 다른 회원에게 내용을 전달, 전송하여 회사 및 다른 회원에게 정신적, 경제적 침해를 야기하는 행위 또는 기타 악용행위를 할 수 없으며 이를 위반할 경우 사안의 경중에 따라 본 운영정책 및 기타 법령에서 정하는 제재를 받을 수 있습니다.</li>
                                  <li>4) 게임 클라이언트 외 게임에 영향을 미치는 모든 불법 소프트웨어 및 하드웨어를 이용, 배포, 판매, 가공, 처분에 관여하는 행위가 적발될 경우 사안의 경중에 따라 본 운영정책 및 기타 법령에서 정하는 제재를 받을 수 있습니다.</li>
                                  <li>5) 게임 서비스와 서비스가 포함하는 모든 정보는 회사에 소유권이 있으며, 회원은 서비스 기간 내에 이를 이용할 권한을 가집니다. 그러므로 게임 서비스를 이용하여 상업적 이익을 얻기 위한 행위를 하지 않아야 합니다.</li>
                              </ul>
                          </li>
                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>제 3조 (계정 관리의 의무)</h4>
                      <ul>
                          <li>계정 및 개인정보 관리에 대한 관리 책임은 일차적으로 해당 회원에게 있습니다. 제3자의 명백한 귀책사유가 아닌 회원 본인의 관리 부주의, 고의적인 개인정보 및 계정의 공유, 양도 행위로 인하여 침해사고가 발생하는 경우 회사는 책임을 지지 않으며, 회원의 권리 보호를 위한 협조를 제한할 수 있습니다.</li>
                          <li>회원은 제3자의 계정과 개인정보를 이용하여서는 안 됩니다. 해당 행위는 회사 이용약관 및 운영정책을 위반하는 행위인 동시에 정보통신망 이용 촉진 및 정보보호에 관한 법률 등 관련 법령을 위반하는 행위로써 법이 정하는 바에 따라 형사 처벌 및 민사상의 손해배상책임을 질 수 있습니다.</li>
                          <li>회사는 회원의 계정 또는 개인정보의 공유 및 거래는 어떠한 경우에도 인정하지 않습니다. 타인과의 계정 공유 혹은 캐릭터, 아이템 등의 현금/현물 거래를 시도할 경우 경고 없이 회원의 이용 권한을 제한할 수 있습니다.</li>
                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>제 4조 (이름에 대한 정책)</h4>
                      <ul>
                          <li>“이름”이란 회사에서 제공하는 게임 서비스에서 사용되는 계정, 닉네임, 단체(길드)명 등 회원이 선정하고 입력하는 모든 명칭을 의미합니다.</li>
                          <li>원칙적으로 회원은 이름을 자유롭게 설정할 수 있습니다.</li>
                          <li>제2항에도 불구하고 다음 각호의 이름은 사용이 제한되며, 회사는 해당 이름을 사전 경고 없이 변경할 수 있습니다. 또한 다음 각호의 이름을 사용하는 행위에 대해서는 제재가 적용될 수 있습니다.
                              <ul className="sub_list">
                                  <li>1) 제3자의 상표권, 저작권, 소유권에 위배될 가능성이 있는, 일반적으로 알려진 이름, 상표, 명칭, 로고 등을 직/간접적으로 사용한 이름</li>
                                  <li>2) 회사 및 회사 직원을 사칭하거나 이를 사칭하려는 의도가 있다고 판단되는 이름</li>
                                  <li>3) 게임명, 게임 내 NPC 및 아이템명 등 다른 회원에게 혼란을 줄 수 있는 이름</li>
                                  <li>4) 욕설, 비속어, 음란성, 선정성, 사행성을 상징하거나 표방하는 내용을 포함한 이름</li>
                                  <li>5) 반사회적이거나 관계 법령에 저촉되는 이름</li>
                                  <li>6) 특정 대상을 광고/홍보하는 내용이 포함된 이름</li>
                                  <li>7) 사업체 및 단체 등을 포함하는 이름</li>
                                  <li>8) 선정적이고 음란하거나, 타인에게 선정적인 표현이라 판단될 수 있는 이름</li>
                                  <li>9) 아이템/계정을 현금/현물 거래할 의도가 있거나 이를 조장하는 이름</li>
                                  <li>10) 특정 국적, 인종, 종교, 민족, 인물, 성별, 장애 등을 비방하는 내용의 이름</li>
                                  <li>11) 금지하고 있는 이름을 일부 수정하는 행위, 또는 위 내용에 해당하는 이름의 철자를 바꾸거나 앞뒤에 다른 문자를 조합한 이름</li>
                                  <li>12) 기타 회사에 의해 수정 및 변경이 필요하다고 판단되는 이름</li>
                              </ul>
                          </li>
                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>제 5조 (운영진의 의무와 역할)</h4>
                      <ul>
                          <li>운영진은 회원이 원활한 서비스를 이용할 수 있도록 도움을 드리는 역할을 수행합니다.</li>
                          <li>운영진은 운영정책 및 서비스 이용약관, 개인정보 처리방침을 준수합니다.</li>
                          <li>운영진은 고객의 개인정보(비밀번호)등을 묻지 않으며, 고객의 개인정보를 절대 임의로 수정/유출하지 않습니다. 단, 사법기관의 정식 협조요청이 있을 시에는 해당 기관에 제공할 수 있습니다.</li>
                          <li>운영진은 고객의 문의 및 요청 사항을 최대한 신속하고 친절하게 응대합니다.<br />
                              단, 운영 정책을 위반하는 내용이나 정상적인 게임 운영이 어렵다고 판단되는 경우, 객관적인 입장에서 판단/조치할 수 있습니다.</li>
                          <li>운영진은 고객 여러분께 발생될 수 있는 모든 형태의 버그를 제보 받고 있으며, 회사는 이를 확인 및 수정할 의무를 가집니다. 고객께서는 그 어떠한 형태의 버그라도 발견한 즉시 운영진에게 제보해 주시기 바랍니다.</li>
                          <li>기술적인 오류로 아이템/캐릭터가 소실되거나 정보가 변경된 경우, 데이터를 통한 객관적인 자료 및 정보가 확인되는 범위 내에서 복구가 가능합니다.</li>
                          <li>운영진은 본 운영정책을 위반한 고객 또는 고객 집단에 대하여 본 운영정책에서 규정하고 있는 각종 조치를 취할 수 있습니다.</li>
                          <li>운영진은 게임 내/외 비정상적인 현상이 발생할 경우 게임의 일부 혹은 전체를 제한 할 수 있습니다.</li>
                          <li>운영진을 사칭하거나 업무 관여, 중재를 방해하는 행위를 할 경우 서비스 이용에 제한 받을 수 있습니다.</li>
                          <li>건전한 게임 문화를 만들기 위해 운영정책에 의거하여 게시물을 사전 통보 없이 편집, 이동, 삭제를 할 수 있으며, 경우에 따라 홈페이지 이용 등을 제한할 수 있습니다.</li>
                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>제 6조 (게임 이용제한 정책)</h4>
                      <ul>
                          <li>회사는 쾌적한 서비스를 회원에게 제공하기 위하여 이용제한 정책을 마련하고 있으며, 회원이 이용제한 정책에 해당하는 행위를 할 경우 서비스의 이용을 제한할 수 있습니다.</li>
                          <li>그 외 서비스 이용약관/운영정책을 포함한 일반적인 규율에 위반된다고 판단되는 사항이나 쾌적한 서비스 유지에 방해 및 심각한 위협을 줄 수 있다고 판단되는 모든 행위에 대해서는 경고 및 계정 제재 등의 조치를 취할 수 있습니다.
                              <div className="terms_table" style={{ color: "black" }}>
                                  <table>
                                      <thead>
                                          <tr>
                                              <th width="">분류</th>
                                              <th width="">설명</th>
                                              <th width="8%">1차</th>
                                              <th width="8%">2차</th>
                                              <th width="8%">3차</th>
                                              <th width="8%">4차</th>
                                              <th width="8%">5차</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <td>욕설/비방</td>
                                              <td>개인, 단체, 지역 등을 대상으로 욕설 조를 모욕, 성적인 표현, 무조건적인 비방 등의 표현를 하는 위</td>
                                              <td>경고 (채팅제한 2시간)</td>
                                              <td>경고 (채팅제한 24시간)</td>
                                              <td>경고 (채팅제한 72시간)</td>
                                              <td>1일</td>
                                              <td>3일</td>
                                          </tr>
                                          <tr>
                                              <td>도배</td>
                                              <td>채팅에 의미 없는 글 혹은 동일한 내용의 글을 연속적으로 등록하여 타인에게 피해를 주는 행위(3회 이상 등록하는 경우)</td>
                                              <td>경고 (채팅제한 2시간)</td>
                                              <td>경고 (채팅제한 24시간)</td>
                                              <td>경고 (채팅제한 72시간)</td>
                                              <td>1일</td>
                                              <td>3일</td>
                                          </tr>
                                          <tr>
                                              <td>부적절한 이를 사용</td>
                                              <td>부적절하거나 운영정책에 위반되는 이름을 사용하는 행위</td>
                                              <td>경고 (닉네임 변경)</td>
                                              <td>7일</td>
                                              <td>15일</td>
                                              <td>30일</td>
                                              <td>영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>게임진행 방해</td>
                                              <td>다른 회원의 원활한 게임 서비스 이용을 방해하는 행위</td>
                                              <td>1일</td>
                                              <td>7일</td>
                                              <td>15일</td>
                                              <td>30일</td>
                                              <td>영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>게임운영 방해</td>
                                              <td>
                                                  <ul className="table_list_style">
                                                      <li>허위사실을 유포, 기타 부정한 방법으로 다른 회원에게 혼란을 초래하는</li>
                                                      <li>기타 정상적인 게임 운영을 방해하는 행위</li>
                                                  </ul>
                                              </td>
                                              <td>7일</td>
                                              <td>30일</td>
                                              <td colSpan="3">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>사기</td>
                                              <td>다른 회원을 기망하여 게임 내에서 부당한 이득을 취하거나 이를 시도하는 행위</td>
                                              <td>7일</td>
                                              <td>30일</td>
                                              <td colSpan="3">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>현금/타 게임 간 거래</td>
                                              <td>
                                                  <ul className="table_list_style">
                                                      <li>게임 내 아이템, 게임머니를 현금 혹은 현물 등으로 판매하거나 타 게임 아이 템으로 그함하거나 이를 시도하는 행위</li>
                                                      <li>계정 및 캐릭터를 공유하거나 현금 혹은 현물 등으로 판매/교환하거나 이를 시도하는 행의</li>
                                                  </ul>
                                              </td>
                                              <td>7일</td>
                                              <td>30일</td>
                                              <td colSpan="3">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>운영자/회사 관계자 사장</td>
                                              <td>운영자 혹은 회사의 직원이나 관계자를 사칭하는 행위</td>
                                              <td>영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>영리/상업 목적의 광고</td>
                                              <td>게임 외적인 영리 추구를 위한 상업적 광고를 하는 행위</td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>계정도용/해킹</td>
                                              <td>타인의 계정으로 접속하여 계정 및 아이템을 부당하게 이용, 편취하거나 이동/삭제하는 행위</td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>어뷰징</td>
                                              <td>비정상적인 방법으로 게임 시스템 설계상 제한을 회피하여 부당한 이익을 취하는 행위</td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>애플리케이션 위조 및 변조</td>
                                              <td>애플리케이션을 위조 및 변조하거나 해당 내용 및 위/변조된 애플리케이션을 유포하는 행위</td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>비공식 프로그램 사용 및 유포</td>
                                              <td>회사에서 인정하지 않는 비공식 프로그램을 이용하여 게임 서비스를 비정상적 으로 이용하는 행위 및 이를 유포하는 행위</td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>버그 악용 및 유포</td>
                                              <td>버그를 인지하고도 고의적, 상습적으로 사용하여 이득을 취하거나 다른 회원에 게 피해를 주는 행위 및 이를 유포하는 행위</td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>개인정보 유출</td>
                                              <td>다른 회원의 개인정보를 동의 없이 유출하는 행위</td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>
                                          <tr>
                                              <td>비정상 결제</td>
                                              <td>
                                                  <ul className="table_list_style">
                                                      <li>타인의 결제수단을 무단으로 이용하여 유료 서비스를 결제하는 행위</li>
                                                      <li>회사 또는 플랫폼사업자가 제공하는 결제, 환불정책을 악용하여 부당한 이의 를 취하는 행위</li>
                                                  </ul>
                                              </td>
                                              <td colSpan="5">영구제한</td>
                                          </tr>

                                      </tbody>
                                  </table>
                              </div>


                          </li>
                          <li>
                              <ul className="sub_list">
                                  <li>1) 위의 항목에 구체적으로 해당되지 않는 사항이라 하더라도 게임의 균형 및 타 회원의 게임 진행에 악영향을 끼치는 경우 운영진의 판단 및 회사의 약관에 의거하여 사용이 제한될 수 있습니다.</li>
                                  <li>2) 사례가 중복되거나 내용의 고의성 및 심각성에 따라 제한이 가중되어 적용될 수 있습니다.</li>
                                  <li>3) 제재와 별도로, 사용자가 제재 대상 행위로 인하여 획득한 게임 머니 및 아이템 등은 삭제 또는 회수 조치되며, 캐릭터 정보를 포함한 게임 데이터가 초기화될 수 있습니다.</li>
                              </ul>
                          </li>
                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>제 7조 (복구 정책)</h4>
                      <ul>
                          <li>회사는 게임 내 균형에 심각한 영향을 미치지 않고 회사 시스템상 그 정보와 자료가 명확히 확인되는 범위 내에서 복구가 가능합니다.</li>
                          <li>복구를 원하는 회원은 문제가 발생한 날로부터 14일 이내에 문제가 발생한 계정으로 고객센터를 통해 회사에 접수해야 합니다.</li>
                          <li>다음 각호의 게임이용정보 손실이나 변경 등은 복구나 손해배상의 대상이 아닙니다.
                              <ul className="sub_list">
                                  <li>1) 회사가 보유한 객관적 자료에 근거하여 확인되지 않는 사항</li>
                                  <li>2) 이용약관이나 운영정책, 공식 커뮤니티(공식 SNS, 카페 등) 공지, 게임 내 공지 등을 통해서 안내된 내용에 대하여 숙지하지 못하여 발생한 피해</li>
                                  <li>3) 게임 서비스 회원의 고의 또는 과실에 의해 발생한 피해</li>
                                  <li>4) 서비스 이용약관, 운영정책에 위배되는 행위로 인해 발생한 피해</li>
                                  <li>5) 디바이스의 변경, 초기화, 번호 이동으로 발생한 피해</li>
                                  <li>6) 아이템 거래 및 계정의 거래 등 회사가 인정하지 않는 게임 서비스 이용으로 인해 발생한 피해</li>
                                  <li>7) 게임 서비스 회원의 개인정보, 계정정보 관리 소홀로 발생한 피해</li>
                              </ul>
                          </li>
                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>제8조 (커뮤니티 정책)</h4>
                      <ul>
                          <li>회사에서 서비스하는 게임 내 커뮤니티(채팅, 게시판) 및 커뮤니티 사이트(SNS, 카페 등)는 회원이 게임을 이용하는 데 필요한 각종 정보의 교환, 일상적인 대화, 회사 및 게임에 대한 건의사항 등을 공유하기 위한 목적으로 마련되었습니다.</li>
                          <li>회사는 본 정책을 바탕으로 쾌적한 커뮤니티를 조성하며, 안정된 커뮤니티 서비스를 제공할 수 있도록 최선을 다합니다.</li>
                          <li>본 정책에서 정하지 않은 사항과 본 정책의 해석에 관하여는 ‘서비스 이용약관’을 따르고, 그 외에 사항에 대하여는 관계법령 및 상 관례에 따릅니다.</li>
                          <li>아래 각호에 해당하는 게시물은 사전 안내 없이 삭제 또는 수정 조치될 수 있으며, 반복적으로 다른 회원에게 불쾌감을 주는 경우 이용에 제한이 따를 수 있습니다. 또한, 아래 항목에 구체적으로 해당하지 않는 사항이라 하더라도 커뮤니티 전체 분위기에 악영향을 미치는 경우, 서비스 이용약관에 따라 서비스 이용이 제한될 수 있습니다.
                              <ul className="sub_list">
                                  <li>1) 사회 질서나 기타 미풍양속에 위배되는 게시물</li>
                                  <li>2) 음란한 단어 및 비속적인 표현을 내포하고 있는 게시물</li>
                                  <li>3) 상업적인 내용이 포함된 글(광고, 홍보, 도배성 게시물)</li>
                                  <li>4) 회사 공지를 통해 안내하지 않은 허위 사실을 유포하여 타 회원의 서비스 이용에 혼란을 유발하는 게시물</li>
                                  <li>5) 인종, 종교, 성, 성별, 장애, 민족적인 차별 및 편견이 내포된 게시물</li>
                                  <li>6) 회사, 운영진을 사칭하는 게시물</li>
                                  <li>7) 애플리케이션을 위조 및 변조와 관련된 내용이 포함된 게시물</li>
                                  <li>8) 비공식 프로그램의 제작, 공유, 배포와 관련된 내용의 게시물</li>
                                  <li>9) 현금/현물 거래와 관련된 게시물</li>
                                  <li>10) 개인정보를 노출하거나 타인의 개인정보를 요구하는 게시물</li>
                                  <li>11) 유해 사이트를 유포하는 게시물</li>
                                  <li>12) 욕설, 비방 및 타인의 명예를 훼손하는 게시물</li>
                                  <li>13) 반사회적이거나 관계 법령에 저촉되는 게시물</li>
                                  <li>14) 타인의 저작권, 지적 재산권을 침해하는 게시물</li>
                                  <li>15) 회사의 영업을 방해하거나 비방 목적의 게시물</li>
                              </ul>
                          </li>
                          <li>회원은 제3자의 저작권 등 타인의 권리를 존중해야 합니다. 음원, 이미지, 영상물 및 이를 편집, 가공한 게시물을 저작권자의 사전 허락 없이 사용할 경우, 현행 저작권법에 의거하여 저작권 침해로 간주합니다. 따라서 관련 정보를 발견한 경우, 회사가 적절한 조치를 할 수 있도록 회사에 알려주시기 바랍니다.</li>
                      </ul>
                  </div>
                  <div className="terms_box">
                      <h4>제9조 (휴면계정 정책)</h4>
                      <ul>
                          <li>회사는 고객의 계정 또는 캐릭터가 부당한 목적으로 사용되는 것을 방지하고 보다 원활한 서비스 제공을 위하여 다음의 요건을 모두 충족하는 경우 해당 계정 또는 캐릭터를 삭제할 수 있습니다.
                              <ul className="sub_list">
                                  <li>1) 12개월 이상 접속기록이 없는 고객의 계정 또는 캐릭터</li>
                                  <li>2) 위 1항의 계정 또는 캐릭터에 유료로 구매한 아이템 또는 이용기간이 남아있는 유료 아이템이 존재하지 않는 경우</li>
                              </ul>
                          </li>
                          <li>휴면상태로 분류되어 계정 또는 캐릭터 게임 정보 삭제가 이루어질 경우 회사는 게임정보 삭제 일정 및 내용을 사전 고지합니다.</li>
                      </ul>
                  </div>

                  <div className="terms_box">
                      <h4>본 운영정책은 2021년 09월 27일부터 시행됩니다.</h4>
                  </div>

              </div>
          </div>
      </div>
  );
}

export default Kor_service;
