import React from "react";
import '../css/terms.css';

function Jpn_provision() {
  return (
    <>
        <div id="provision" className="terms sub">
            <div className="container">
                <h3 className="sub_title">利用規約</h3>
                <hr className="star-primary wow zoomIn"></hr>
                <div className="terms_box">
                    <h4>第1条 (目的)</h4>
                    <p>本利用規約は、ACT GAMES Co., Ltd.(以下「会社」)が提供する全てのゲーム及び諸般のサービスの利用に関連し、ユーザーと会社間の加入及び利用に関する諸般の事項と、その他必要な事項を具体的に規定することを目的としています。</p>
                </div>

                <div className="terms_box">
                    <h4>第2条 (用語の定義)</h4>
                    <p>本規約で使用する用語の定義は次のとおりです。</p>
                    <ul>
                        <li>「ユーザー」とは、会社が提供するすべてのゲーム及び諸般のサービスを利用する者(一般会員及び臨時会員を含む)をいい、そのタイプは以下のとおり区分されます。
                            <ul className="sub_list">
                                <li>1) 一般会員:提携しているプラットフォーム事業者を介して情報を入力し、会社が提供する一般アカウントを付与されたユーザーをいいます。この際の「アカウント」とは、サービスの利用に関連して会員識別のための文字と数字の組み合わせをいいます。</li>
                                <li>2) 臨時会員:情報の入力無しに、会社がランダムに付与する臨時アカウントを介してサービスを利用する者をいいます。臨時会員は、サービスを利用しているデバイス(端末)がリセットされたりコンテンツをデバイスから削除する場合、臨時アカウントがリセットされ、既存のゲームデータなどの情報は消滅します。</li>
                            </ul>
                        </li>
                        <li>「コンテンツ」とは、情報通信網(情報通信網の利用促進及び情報保護等に関する法律 第2条第1項第1号の規定による)で使用される符号、文字、音声、音響、画像又は映像などに含まれた資料又は情報のことで、その保存と利用において効用を高められるように電子的な形で製作又は処理されたものです。会社が提供するすべてのゲーム及びネットワークサービス、アプリ、ゲームマネー及びゲームアイテムなどのサービスに関連してデジタル方式で製作された内容物の一切をいいます。</li>
                        <li>「有料コンテンツ」とは、有料キャッシュ(第16条第1項)やその他ゲーム関連アイテムなど、ユーザーがサービスに関連して会社に金銭などの代価を支払い、有償で購入するコンテンツをいいます。</li>
                        <li>掲示物」とは、ユーザーがサービスを使用するにあたって会社が管理･運営する電子掲示板(会社のウェブサイト又はアプリ内の掲示板及び会社のSNS掲示板などを含む)に掲示した文字、文書、絵、音声、映像又はその組み合わせでできたすべての情報をいいます。</li>
                        <li>「デバイス」とは、コンテンツをダウンロードしたりインストールして使用できるパソコン、スマートフォン、タブレットパソコンなどの電子端末をいいます。</li>
                        <li>「サービス」とは、デバイスの種類と関係無く、会社がユーザーに提供するネットワークの利用に関するサービスの一切をいいます。</li>
                        <li>「アプリ」とは、会社が提供するサービスを利用するために、本条第5項で説明したデバイスを介してダウンロードしたりインストールして使用するプログラムの一切をいいます。</li>
                        <li>「オープンマーケット事業者」とは、弊社のゲームをインストールして決済できるように提供するオープンマーケットなどの電子商取引提供者の一切(ゲーム内決済を提供する事業者、通信キャリアを含む)を提供する事業者をいいます。(例.Google Play、App Store、ONE storeなど)</li>
                        <li>「決済代行会社」とは、弊社のゲームをウェブで決済できるように提携してサービスを提供する事業者をいいます。関連代行会社はタイ/ベトナムでのみサービスを行います。</li>
                        <li>「プラットフォーム事業者」とは、弊社のゲームを広めたり利用するにあたって、会社と提携してサービスを提供する事業者及び関連サービスの一切をいいます。</li>
                        <li>「キャッシュ」とは、会社がサービスする個別アプリのゲーム内で使用される仮想データのことで、ユーザーは該当ゲーム内の有料サービスの利用又はコンテンツや商品の購入などにキャッシュを使用できます。</li>
                        <li>「In-App決済」とは、キャッシュやアイテム、機能、その他コンテンツの購入などの目的で、会社がサービスするアプリのゲームで行われる決済をいいます。</li>
                        <li>「Web決済」とは、キャッシュやアイテム、機能、その他コンテンツの購入などの目的で、会社が提供するウェブサイトで行われる決済をいいます。</li>
                        <li>本規約で使用する用語の定義は、本条で定めるものを除いては関係法令で定めるところによります。関係法令に定められていないものは、一般的な商慣例によるものとします。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第3条　(規約の効力及び適用と変更)</h4>
                    <ul>
                        <li>本規約は、ユーザーがアプリをインストール及び実行して規約に同意すると同時に効力が発生します。会社は、ユーザーが手軽に見られるように、会社のウェブサイトとアプリの画面に本規約の内容を掲示します。</li>
                        <li>会社は、サービス改善などの目的で「規約の規制に関する法律」及び「情報通信網の利用促進及び保護などに関する法律」、その他関連法令を違反しない範囲内で本規約を随時変更することができます。</li>
                        <li>第2項により規約を変更する場合、会社は変更された規約が適用される最低7日、最高30日前から、変更された規約の内容と適用日時を会社のホームページ及び公式カフェ、アプリのお知らせに掲示する方法でユーザーに告示します。ただし、ユーザーに不利な内容に規約を改定する場合には、上の告示以外にEメールかメッセージ、ログイン時の同意画面、携帯電話のメールのうち1種類以上の方法でユーザーに追加通知します。</li>
                        <li>ユーザーは、変更された規約に対し拒否する権利があります。本規約の変更に対し異議のあるユーザーは、サービスの利用を中断して利用契約を解消(会員退会)することができます。</li>
                        <li>会社が第3項により変更された規約を告示又は通知してユーザーが適用日までに拒否を意思表示をしなかった場合、変更された規約を承諾するという意思表示が表明されたものとみなすという意味を、別途に明確にお知らせ又は告示したにもかかわらず、ユーザーが該当期限までに明示的に拒否意思を表明しなかった場合、ユーザーは変更された規約に同意したものとみなします。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第4条 (規約以外の準則)</h4>
                    <p>本規約に定められていない事項は、会社が定めた個別サービス利用規約と情報通信網の利用促進及び情報保護などに関する法律、電子商取引などにおける消費者保護に関する法律で定めるところに従い、個別サービス利用規約と関係法令に定められていないものは一般的な商慣例に従います。</p>
                </div>
                
                <div className="terms_box">
                    <h4>第5条 (利用契約の承諾)</h4>
                    <ul>
                        <li>サービスに対する利用契約は、申込者がアプリをインストールし、アプリ内で「Sprigncomes のゲームサービス利用規約に同意します」という表示に同意することで本規約に同意して会社にサービス利用を申し込み、会社がこのような申し込みに対してサービス利用を承諾することで成立します。</li>
                        <li>会社は、ユーザーの第1項によるサービス利用申込に対し、業務上又は技術上の問題がない場合、それを承諾することを原則とします。ただし、会社は次の各号で定める場合には、承諾を拒否したり承諾をした後でもそれを事後取消しすることができます。
                            <ul className="sub_list">
                                <li>1) 虚偽又は他人の情報を記載したり、会社が提示する様式に必要な情報を記載しない場合</li>
                                <li>2) 社会の安寧と秩序又は公序良俗を乱したり、乱すことを目的として申し込んだ場合</li>
                                <li>3) 不正な用途でサービスを利用しようとする場合</li>
                                <li>4) 営利を追求する目的でサービスを利用しようとする場合</li>
                                <li>5) 本規約で定めた事項に違反して申し込んだ場合</li>
                            </ul>
                        </li>
                        <li>会社は、次の各号の場合にその原因が解消されるまで承諾を保留できます。
                            <ul className="sub_list">
                                <li>1) サービス関連設備に余裕が無い場合</li>
                                <li>2) 業務上又は技術上、サービスを提供できない障害事項が発生した場合</li>
                                <li>3) その他「会員」として不適切な行為をする恐れがあると認められる場合</li>
                            </ul>
                        </li>
                        <li>本条によってサービス利用申込に対する承諾を拒否又は留保する場合、会社が該当事実を申込者に通知しなければなりません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第6条 (会社の義務)</h4>
                    <ul>
                        <li>会社は、ユーザーに対するサービス提供に関連して関連法令及び本規約、公序良俗に反する行為をするものではなく、継続的かつ安定的なサービス提供のために最善を尽くします。</li>
                        <li>会社は、業務上又は技術上特別な支障が無い限り、ユーザーに24時間年中無休サービスを提供することを原則とします。ただし、会社は第8条第2項によってサービスの提供を中止又は制限することができます。</li>
                        <li>会社は、ユーザーが安全にサービスを利用できるように、ユーザーの個人情報(クレジット情報を含む)を保護するために最善を尽くし、これに関連して個人情報取扱方針をお知らせし遵守します。</li>
                        <li>会社は、サービス利用に関連してユーザーの不満及び被害救済要請を適切に処理できるように、必要な人材とシステムを構築及び維持できるように最善を尽くします。会社は、ユーザーから提起された意見や不満が正当であると客観的に認められる場合には、合理的な期間内に迅速に処理しなければならず、その処理に長い期間がかかる場合には、ユーザーにその事由と処理日程を通知しなければなりません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第7条 (ユーザーの義務)</h4>
                    <ul>
                        <li>ユーザーは、サービス利用に関連して次の各号に該当する行為をしてはなりません。
                            <ul className="sub_list">
                                <li>1) 問い合わせ、有料コンテンツの復旧と払戻し要請、イベント当選などによって会社に個人情報を提供する際、実名ではない情報又は他人の情報を使用したり、虚偽の事実を記載する行為</li>
                                <li>2) 他人のふりをしたり他人との関係を虚偽に明示する行為、他のユーザーのユーザーアカウント情報を盗用･不正使用したり、他人のクレジットカードや有/無線電話、銀行口座などを無断で盗用して有料コンテンツを購入する行為</li>
                                <li>3) 会社が提供していないサービスを介して有料コンテンツなどを他人と取引したり売買する行為</li>
                                <li>4) 会社のサービス及び提供されたアプリを利用して得た情報を、会社の事前承諾無しにコピー、流通、その助長をしたり商業的に利用、又は周知や未知のバグを悪用してサービスを利用する行為</li>
                                <li>5) 会社のサービス及び提供されたアプリを利用して、自分又は他人が財産上の利益を得る行為</li>
                                <li>6) 会社の知的財産権、第三者の知的財産権、肖像権などその他の権利を侵害したり、会社の承認を得ずに他のユーザーの個人情報を収集、保存、流布、掲示する行為</li>
                                <li>7) 第三者を騙して利益を得たり、会社が提供するサービスを不健全に利用して第三者に被害を及ぼす行為</li>
                                <li>8) 他人の名誉を棄損したり、他人に損害を与える行為</li>
                                <li>9) わいせつ、低俗な情報を交流、掲載したり、わいせつなサイトをリンクしたり、承認されていない広告や広報物を掲載する行為</li>
                                <li>10) 羞恥心や嫌悪感又は恐怖心を引き起こさせる言葉や音響、文や画像又は映像を相手に転送、到達、流布する行為</li>
                                <li>11) 財物をかけて賭博を行うなどの射幸行為を誘導したり参加する行為</li>
                                <li>12) 関連法令によってその転送又は掲示が禁止されている情報(コンピュータプログラム)又はコンピュータソフトウェア、ハードウェア、電気通信装置の正常な稼働を妨害、破壊する目的で考案されたソフトウェアウイルス、その他他のコンピュータコード、ファイル、プログラムを含んでいる資料を転送、掲示、流布、使用する行為</li>
                                <li>13) 会社から特別な権利を付与されずにアプリを変更したり、アプリに別のプログラムを追加又は挿入、サーバをハッキング･逆設計、ソースコードやアプリデータの流出や変更、別途のサーバ構築、ウェブサイトの一部分を任意に変更又は盗用して、会社を詐称する行為</li>
                                <li>14) 会社の社員や運営者を仮称したり、詐称又は他人の名義を盗用して書き込みをしたりメールを送る行為</li>
                                <li>15) 会社の同意無しに営利、営業、広告、政治活動、不法選挙運動などを目的にサービスを使用する行為</li>
                                <li>16) サービスの運営を故意に妨害したり、サービスの安定的な運営を妨害できる情報などを転送する行為</li>
                                <li>17) その他公共秩序や公序良俗に反したり、不法･不当な行為及び関係法令に違反する行為</li>
                            </ul>
                        </li>
                        <li>ユーザーは、会社のウェブサイト又はアプリ上のお知らせ及び利用規約の変更事項などを随時確認して遵守する義務があり、その他会社の業務を妨害する行為をしてはなりません。</li>
                        <li>ユーザーは、サービス利用に関連して自分のアカウントを第三者に利用させてはなりません。ユーザーは、第三者が自分のユーザーアカウント情報などの個人情報を利用していることを知った場合、会社にその事実を通知して会社の案内に従わなければならず、その案内に従わずに発生した不利益(例:第三者がユーザーの名義で有料コンテンツなどを決済した場合、それに伴う代金支払いの義務など)に対する責任はユーザーにあります。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第8条 (サービスの内容及び変更)</h4>
                    <ul>
                        <li>会社は、新しいゲーム内容の追加や各種バグパッチなどのサービス運営上または技術上必要な場合、提供しているサービスの全部又は一部を常時修正、追加、廃止など変更することができます。これに関連し、会社は変更するサービスの内容及び変更日時などの情報を、会社のウェブサイトとアプリを介してユーザーに告示します。</li>
                        <li>会社は、次の各号に該当する場合、サービスの全部又は一部の提供を制限又は中止することができます。その場合、会社はウェブサイト及びアプリにこのような事項を告示しなければならず、ユーザーはサービスの終了時に使用期間が残っていない有料コンテンツに対して会社に払戻しを要請することはできません。
                            <ul className="sub_list">
                                <li>1) 戦時、事変、天災地変又は国家非常事態などの不可抗力的な事由がある場合</li>
                                <li>2) 停電、諸般設備の障害又は利用量の輻輳などによって正常なサービス提供に支障がある場合</li>
                                <li>3) サービス用設備のメンテナンスなどの工事によるやむを得ない場合</li>
                                <li>4) 会社の諸般事情によりサービスを提供できない場合</li>
                            </ul>
                        </li>
                        <li>第2項によりサービスが中断された場合には、会社が運営するウェブサイト又はアプリ内に掲示して通知します。ただし、会社がコントロールできない事由によるサービスの中断によって事前通知が不可能な場合にはその限りではありません。</li>
                        <li>会社は、サービスが変更されたり中止された原因が会社の故意または重大な過失による場合を除いては、サービスの変更又は中止によって発生する問題に対して責任を負うものではありません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第9条 (青少年に対する一部サービスの利用制限)</h4>
                    <ul>
                        <li>満19歳未満のユーザー(ただし、満19歳になる年の1月1日を迎えた者は除く)は、会社がサービスを介して提供するコンテンツのうち、青少年保護法上で青少年有害メディアに該当するコンテンツを利用することはできません。</li>
                        <li>青少年有害メディアに該当するコンテンツを使用しようとするユーザーは、関連法令に従って本人及び年齢認証手続きを取らなければなりません。本項の手続きを履行しないユーザーは、該当コンテンツに関連したサービスの利用が制限されます。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第10条 (情報の提供及び掲載)</h4>
                    <ul>
                        <li>会社は、サービスの利用に関連した情報をユーザーに、Eメールやサービス画面、ウェブサイト、アプリのお知らせなどを介して提供することができます。ユーザーは、関連法令による取引関連情報及び問い合わせなどに対する回答などを除いては、会社が提供する情報が含まれたEメールの受信を拒否することができます。</li>
                        <li>会社は、サービス画面、ウェブサイト、アプリへの掲載などを介してユーザーにお知らせなどの情報を提供することができます。ユーザーの本規約に対する同意は、会社のホームページ又はサービス利用時に上の広告の露出に対する同意を含みます。</li>
                        <li>会社は、ユーザーの事前同意のもとに、ユーザーから収集した個人情報を活用して第1項の情報提供及び第2項の広告などを提供する場合、SMS(LMS)、スマートフォンの通知(Push通知)、メールアドレスなどを活用して送信することができ、ユーザーはそれを希望しない場合、会社のウェブサイト又はサポートセンターを介して意思表示することで、いつでも受信を拒否することができます。</li>
                        <li>会社が提供するサービス又は広告を媒介にして、ユーザー間又はユーザーと第三者間で成立した取引などに対し、会社はいかなる責任も追うものではありません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第11条 (有料コンテンツの購入、使用期間及び利用)</h4>
                    <ul>
                        <li>ユーザーは、サービスに関連した有料コンテンツを購入することができます。これに関連し、サービスを利用するデバイスの種類、通信キャリア、プラットフォーム事業者又はオープンマーケット事業者のポリシーによって、具体的な決済金額又は支払い方法に差が生じることがあります。</li>
                        <li>サービス内でユーザーが購入した有料コンテンツは、該当サービスのアプリをインストールしたデバイスでのみ使用できます。</li>
                        <li>有料コンテンツの利用期間は、購入時に明記された期間となります。ただし、第8条2項により、サービスが終了される場合、利用期間が明記されていない有料コンテンツは、その利用期間をサービス終了告知で告知されたサービスの終了日までとなります。</li>
                        <li>ユーザーは、会社が別途定めて告示した方法以外にはユーザー本人のアカウントでのみ購入した有料コンテンツを利用でき、それを第三者に譲渡、貸与、売買できません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第12条 (In-App決済)</h4>
                    <ul>
                        <li>会社がサービスのために提供するアプリは、アイテムを購入するためのIn-App決済機能を含みます。</li>
                        <li>ユーザーは、デバイスのパスワード設定機能やオープンマーケットが提供するパスワード設定機能などを利用して、第三者のユーザーデバイスを介したIn-App決済を防止しなければならず、会社はそのために放送通信委員会の勧告及び「オープンマーケットのモバイルコンテンツ決済ガイドライン」に従って、オープンマーケットが提供する認証手続きなどに適用されたIn-App決済のためのモジュールやライブラリーなどをアプリに適用します。</li>
                        <li>ユーザーがデバイスやオープンマーケットのパスワード設定機能などを利用しなかったり、上のパスワードを不注意に露出して発生した第三者のIn-App決済に対しても、会社は責任を負うものではありません。</li>
                        <li>通信キャリアの青少年料金制に加入したユーザーが該当デバイスでIn-App決済をする場合、該当決済に対して法定代理人の同意があるものとみなします。</li>
                        <li>ユーザーは、In-App決済時にそれに伴う有料コンテンツ代金を支払う義務があります。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第13条 (Web決済)</h4>
                    <ul>
                        <li>会社がサービスのために提供するIn-App決済機能の他に、Web決済があります。</li>
                        <li>ユーザーがWeb決済に必要なアカウントとパスワードを不注意に露出して発生した第三者のWeb決済に対し、会社はいかなる責任も負うものではありません。</li>
                        <li>Web決済は即決済ではないプリペイドカードを購入して決済する方法で、タイ/ベトナムでのみ購入できます。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第14条 (掲示物の管理)</h4>
                    <ul>
                        <li>会社は、ユーザーが作成又は掲示した掲示物を管理上の必要によって事前通知無しにサイト内で移動させることができ、会社のシステム上、保存容量の超過など避けられない事由がある場合、事前通知無しに掲示物を削除することができます。</li>
                        <li>ユーザーの掲示物に対する責任はユーザー個人の責任であり、第三者の権利を侵害する内容又は方法で掲示物を開示してはなりません。もし会員が掲示した掲示物が第三者の権利を侵害したことを理由に、会社に第三者が異議、抗議などを提起した場合、会社は関連法令で定めたところに従って必要な臨時措置を取ることができ、会員にその事実を通知します。</li>
                        <li>ユーザーが掲示した掲示物が第三者の権利を侵害したことを理由に、会社が損害賠償を請求させるなどの法的問題が生じた場合、ユーザーは会社の免責のために必要なすべての協力をしなければならず、会社に帰責事由が無い限り、ユーザーはこれによって発生したすべての問題に対して責任を負わなければなりません。</li>
                        <li>会社は、次の各号に該当する掲示物や資料は、任意に削除したり移動又は登録を拒否することができます。
                            <ul className="sub_list">
                                <li>1) 他のユーザー又は第三者を誹謗したり、中傷謀略によって名誉を棄損する内容の場合</li>
                                <li>2) 公共秩序及び公序良俗に反する内容の場合</li>
                                <li>3) ユーザーアカウント又はポイント売買に関連した内容の場合</li>
                                <li>4) 営利を目的とする広告の場合</li>
                                <li>5) 犯罪行為と結びついていると認められる内容の場合</li>
                                <li>6) 他のユーザー又は第三者の著作権など、その他の権利を侵害する内容の場合</li>
                                <li>7) 会社が規定した掲示物の原則に反したり、掲示板の性格に合っていない場合</li>
                                <li>8) 会社やサービスの正常な運営を妨害する場合</li>
                                <li>9) 犯罪行為と結びついていると判断される場合</li>
                                <li>10) 承認されていない広告及び広報物を掲示した場合</li>
                                <li>11) 同一内容を繰り返し多数掲示するなど、掲示の目的に反する場合</li>
                                <li>12) 不法コピーやハッキングなどを助長する内容</li>
                                <li>13) その他関係法令に違反すると判断される場合</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第15条 (イベント景品及び賞金)</h4>
                    <ul>
                        <li>会社は、サービスに関連して当選したユーザーに景品又は賞金などを支給するなどのイベントを、一定期間開催することができます。</li>
                        <li>ユーザーが虚偽の個人情報提供又はその他不法な方法を使用して会社が開催するイベントに参加した場合、会社は該当会員のイベント当選を取り消すことができます。</li>
                        <li>会社は、別途の基準に従って公租公課の源泉徴収などの手続きを終えてから、イベント当選者に対する景品又は賞金を支給します。</li>
                        <li>イベントに当選したユーザーが満20歳未満の場合、会社は該当ユーザーの両親(又は法定代理人)の同意を得た後に景品又は賞金を支給します。</li>
                        <li>会社の帰責事由無しに、イベント当選者発表後1ヵ月が過ぎてもユーザーが景品又は賞金を受領しない場合、会社は該当ユーザーに対するイベント当選を取り消すことができます。</li>
                        <li>会社は、景品又は商品を支給するためにユーザーの個人情報を収集又は利用することができ、それに関連した事項は会社の個人情報取扱方針に従います。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第16条 (キャッシュ及びポイント)</h4>
                    <ul>
                        <li>キャッシュは、(i) ユーザーがIn-App決済を介して有償で購入するキャッシュ(以下「有料キャッシュ」という)と、(ii) ユーザーがゲームをプレイしながら獲得したり、イベントを介して会社から無償で提供されるキャッシュ(以下「無料キャッシュ」という)に分けられ、各タイプによって個別に管理されます。</li>
                        <li>会社は、無料キャッシュとは別に、ユーザーに無償でゲームなどのサービス利用に関連して使用できるマイレージなどのポイント(以下「無料ポイント」という)を提供することができます。</li>
                        <li>ユーザーが有料キャッシュ、無料キャッシュ及び無料ポイントを同時に保有した状態で、ゲーム内の有料サービスの利用又はコンテンツ、商品の購入などにこれを使用する場合、該当時点で保有している(i) 有料キャッシュ、(ii) 無料キャッシュ、(iii) 無料ポイントの順に差し引かれます。</li>
                        <li>無料キャッシュと無料ポイントは払戻しや賠償又は補償の対象にはならず、会社はこれに関連した責任を負うものではありません。</li>
                        <li>無料キャッシュと無料ポイントの場合、その使用条件に制限がある場合があります。会社は、無料キャッシュと無料ポイントの使用に関連した具体的な条件(有効期間、使用範囲、その他必要な事項)を、別途のお知らせを介してユーザーに知らせます。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第17条 (契約キャンセル及び購入代金の払戻しなど)</h4>
                    <ul>
                        <li>有料コンテンツの購入後には、原則として購入キャンセルはできないものとします。</li>
                        <li>会社の帰責事由によって購入した有料コンテンツを利用できない場合、会社はユーザーに同一の有料コンテンツを無償で提供するか、該当購入金額を契約締結日又は購入日に関係無く全額払い戻します。</li>
                        <li>購入代金の払い戻しは、ユーザーが利用する各オープンマーケット事業者の払い戻し運営ポリシーに沿って進められ、具体的な払い戻し申請手続きは会社の運営ポリシーに従います。また、払い戻しが行われると、サービス内の有料コンテンツはこれを反映して差し引かれます。</li>
                        <li>ユーザーが有料コンテンツを無料コンテンツに転換した場合、全体或いは一部の使用によりゲームサービス内で購入できる基本単位が毀損した場合、メッセージ箱やプレゼント箱などの有料コンテンツの送受信に関連した画面で、受諾などの同意過程を経た場合などが発生すると、ユーザーが該当有料コンテンツを使用したものとみなします。該当事項は、ユーザーの真意とは相違して他人を介して購入された場合も含みます。</li>
                        <li>ユーザーが、購入内訳が記録される利用代金の決済を介することなくサービス利用中に報酬を取得したり、サービス内部のイベント或いは外部提携イベントなどを介して取得した有料コンテンツは払い戻し対象から除外されます。</li>
                        <li>ユーザーが契約キャンセルや払い戻しなどを要求する場合、会社のサポートセンター及び会社が指定した委託会社で別途の個人情報取扱いに対する同意過程を経た後、会社がプラットフォーム事業者又はオープン事業者を介して該当ユーザーの購入内訳を確認する手続きが進められます。会社は、この過程の中でユーザーのキャンセル事由を確認するために、ユーザーから提供された情報を介してユーザーに連絡し、正確な事実を確認することができ、追加証憑を要求することができます。[例.プラットフォーム事業者に登録された個人情報、オープンマーケットでの購入又は決済内訳、他人によってユーザーの意図と相違して購入されたことが確認できる書類(家族関係証明)など]</li>
                        <li>ユーザーに現行法令又は重大な規約の違反などの帰責事由がある場合、本条及び第19条、第20条の契約キャンセルが制限されることがあります。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第18条 (In-Appアイテムの契約キャンセルなど)</h4>
                    <ul>
                        <li>第17条にもかかわらず、ユーザーがIn-App決済及びWeb決済を介して購入した有料コンテンツ(以下「In-Appアイテム」という)が次の各号に該当する場合、素に対する契約キャンセルが制限されます。この場合、会社は該当In-Appアイテムに対してIn-App決済及びWeb決済がある前に契約キャンセルが制限されるという点を明確に表示するなどの方法で、ユーザーの契約キャンセルなどの権利行使が妨げられないように措置を講じなければならず、この措置が講じられていない場合、ユーザーは次の各号に該当したとしても該当In-Appアイテムに対する契約をキャンセルすることができます。
                            <ul className="sub_list">
                                <li>1) 購入後すぐに使用が開始されたり、すぐにアプリに適用されるIn-Appアイテムの場合</li>
                                <li>2) In-App決済無しにサービス利用過程で取得したコンテンツの場合</li>
                                <li>3) 追加特典が提供されるIn-Appアイテムで、該当の追加特典がすでに使用された場合</li>
                                <li>4) セットで販売されたIn-Appアイテムのうち、一部が既に使用された場合</li>
                                <li>5) 開封行為を使用とみなすことができたり、 開封時に効用が決定するカプセル/確率タイプのIn-Appアイテムを開封した場合</li>
                                <li>6) In-Appアイテムなどの財貨を全部又は一部使用した場合</li>
                                <li>7) 該当In-Appアイテムの使用期間(第11条第3項に定める)がすでに経過した場合</li>
                            </ul>
                        </li>
                        <li>In-App決済は、オープンマーケット事業者が提供する決済方式によるため、ユーザーは決済過程で過誤金が発生した場合、原則的にオープンマーケット事業者に対して該当過誤金の払い戻しを要請しなければなりません。ただし、オープンマーケット事業者のポリシーとシステムによって可能な場合、会社がオープンマーケット事業者に必要な払い戻し手続きの履行を要請することができます。</li>
                        <li>プレゼント機能を介して取得したIn-Appアイテムは、瑕疵がある場合を除いては原則的に決済取り消しと払い戻しが不可能で、瑕疵を原因として同アイテムの払い戻しを要請する権利はプレゼントしたユーザーにのみあります。</li>
                        <li>Web決済は、決済代行会社を介して決済する方式で、会社では払い戻しが不可能です。ただし、もう一度使用できるように決済サイト内の財貨に契約キャンセルすることは可能です。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第19条 (未成年者の契約キャンセルなど)</h4>
                    <ul>
                        <li>未成年者であるユーザーが法定代理人の同意無しに有料コンテンツを購入した場合、未成年者又は法定代理人はそれに対する契約をキャンセルできます。</li>
                        <li>未成年者であるユーザーの決済金額が法定代理人から処分を許された財産の範囲内である場合、又は該当ユーザーが詐術などを使って会社が該当ユーザーを青年であると信じるように仕向けた場合は、第1項の契約キャンセルが制限されます。</li>
                        <li>有料コンテンツの購入者が未成年者かどうかは、決済が行われた端末又はクレジットカードなどの決済手段の名義者を基準として判断されます。</li>
                        <li>本条により未成年者の契約キャンセルを要請する場合、ユーザー又はその法定代理人は会社の要請によって未成年者及び法定代理人を証明できる書類を提出しなければなりません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第20条 (契約解消及びサービスの利用制限など)</h4>
                    <ul>
                        <li>ユーザーは、サービスの利用を希望しない場合はいつでも、退会によってサービス利用契約を解消することができ、解消意思を明らかにすると同時に解消処理されます。</li>
                        <li>ユーザーが第7条第1項の各号に違反した行為を取った場合、会社は該当ユーザーとのサービス利用契約を解消するか、又は期間を定めてサービスの利用を中止させることができます。</li>
                        <li>第2項によって利用契約の解消又はサービスの利用を中止させる場合、会社はユーザーに該当措置の事由と効力発生日に関する事項を、Eメールやメッセージ、その他の手段を介して通知します。</li>
                        <li>第2項の規定によってサービス利用契約を解消したり中断した場合、ユーザーはサービス関連コンテンツを使用することができず、既に支払った有料コンテンツの購入代金やデータ通話料、月極サービス利用料などの払い戻しは受けられません。</li>
                        <li>本条の契約解消によって、会社は関連法令及び個人情報取扱方針に従い会社が保有すべき情報を除いた該当ユーザーのすべての個人情報及びデータ(ユーザーが自分のアカウントに保有していたコンテンツ情報を含む)を削除します。これに関連し、会社がユーザーに該当情報の削除に関する事項を案内したにもかかわらず、ユーザーが適切な措置を取らずに発生した損害に対して、会社はいかなる責任を負うものではありません。</li>
                        <li>会社は臨時会員に対し、メールアドレスやパスワードなどの顧客情報を収集するものではなく、既存のデバイス又はプラットフォーム連動がリセットされたりゲームが削除された場合、該当臨時会員に対するゲーム関連情報とデータを復旧することはできず、これに関連した責任を負うものではありません。</li>
                        <li>本条の解消は、第23条の損害賠償に影響を及ぼすものではありません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第21条 (個人情報の保護及び使用)</h4>
                    <ul>
                        <li>会社は、利用契約のためにユーザーが提供した個人情報を、サービスを提供するための目的以外の用途で使用したり、ユーザーの同意無しに第三者に提供するものではありません。ただし、次の各場合には例外とします。
                            <ul className="sub_list">
                                <li>1) 法令に基づきユーザー情報の利用と第三者に対する情報提供が許可された場合</li>
                                <li>2) その他会社の規約及びポリシーに従ってユーザーから同意を得た場合</li>
                            </ul>
                        </li>
                        <li>個人情報の保護及び使用に対しては、関連法令及び会社の個人情報取扱方針が適用されます。会社がサービスアプリ上、又はウェブサイトの掲示板を介して掲載、通知する個人情報取扱方針は規約の一部を構成し、ユーザーはその内容に同意します。</li>
                        <li>会社は、収集した個人情報の取扱及び管理などの業務を自ら遂行することを原則としますが、必要な場合、業務の一部又は全部を第三者に委託することができます。これに関連した諸般の事項は、個人情報取扱方針を介してユーザーに告示、案内します。</li>
                        <li>会社は、ユーザーの帰責事由によって露出されたユーザーのアカウント情報を含むすべての情報に対し、一切の責任を負うものではありません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第22条 (免責)</h4>
                    <ul>
                        <li>会社は、戦時、事変、天災地変又は国家非常事態、解決が難しい技術的欠陥、その他不可抗力的な事由でサービスを提供できない場合には、責任が免除されます。</li>
                        <li>会社は、ユーザーの帰責事由によるサービスの中止/利用障害に対して責任を負うものではなく、会社は、基幹通信事業者が電気通信サービスを中止したり正常に提供せずにユーザーに損害が生じた場合には、責任が免除されます。</li>
                        <li>会社は、事前に知らされたり緊急に実施されたサービス用設備のメンテナンス、交換、定期点検、工事などのやむを得ない事由でサービスが中止されたり障害が発生した場合には、責任が免除されます。</li>
                        <li>会社は、ユーザーがサービスを利用して期待するスコアや順位などを得られなかったことに対し、責任を負うものではなく、サービスに対する取捨選択又は利用によって発生する損害などに対しては、責任が免除されます。</li>
                        <li>会社は、ユーザーが本人の個人情報など(アカウントを含む)を変更して被った不利益と情報喪失に対しては、一切責任を負うものではありません。</li>
                        <li>会社は、ユーザーの端末環境によって発生した諸般の問題、又は会社の帰責事由が無いネットワーク環境によって発生した問題に対して、責任を負うものではありません。</li>
                        <li>会社は、ユーザー相互間又はユーザーと第三者間でサービスを媒介として発生した紛争に対し介入する義務は無く、それによる損害を賠償する責任もありません。</li>
                        <li>会社は、関連法令に特別な規定が無い以上、無料で提供するサービス又はコンテンツに関連して、ユーザーに対し損害賠償の責任を負うものではありません。　ただし、会社の故意または重大な過失によって発生した損害の場合はその限りではありません。</li>
                        <li>会社は、ユーザーがデバイス上でのゲーム状況をソーシャルネットワークのアカウントまたは会社から付与されたアカウントと関連付けられたアカウントに接続していなかった場合には、デバイス上のプレイデータが消滅しても、有料コンテンツ、無料キャッシュ、無料ポイントや他のゲームプレイに関するデータを復旧することはできません。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第23条 (損害賠償など)</h4>
                    <ul>
                        <li>ユーザーが本規約の義務を違反することによって会社に損害を与えた場合、又はユーザーがサービスを利用するにあたって会社に損害を与えた場合、ユーザーは会社が被った損害を賠償しなければなりません。</li>
                        <li>ユーザーがサービスを利用するにあたって行った不法行為や本規約に違反した行為によって、会社が当該ユーザー以外の第三者から損害賠償を請求されたり訴訟をはじめとした各種異議提起を受けた場合、当該ユーザーは自己の責任と費用で会社を免責しなければならず、会社が免責されなかった場合、当該ユーザーはそれによって会社に発生した損害を賠償する責任があります。</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>第24条 (裁判権及び準拠法)</h4>
                    <ul>
                        <li>サービス利用に関連して会社とユーザー間で発生した紛争に対しては、両当事者間の合意によって円満に解決しなければなりません。</li>
                        <li>会社とユーザー間で訴訟の必要が生じた場合、ソウル地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
                        <li>ユーザーと会社間で紛争が発生した場合、韓国法を準拠法として適用します。</li>
                        <li> 利用規約等のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、利用規約等の残りの規定および一部が無効又は執行不能と判断された既定の残りの部分は、継続して完全に効力を有するものとします。 </li>
                    </ul>
                </div>

                <div className="terms_box">
                    <h4>本利用規約は2021年09月27日から施行されます。</h4>
                </div>                              
            </div>
        </div>
    </>
  );
}

export default Jpn_provision;
