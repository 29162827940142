import React, {useEffect , useState} from "react";
import '../css/gamesdetail.css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';


// import required modules
import { EffectFade, Autoplay } from 'swiper/modules';


function Kitty(props) {
  const [isShowVideoModal,setVideoModal ] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
	}, []);

  return (
    <div className="GameWrap">
      <section className="GamesDetailsSetion">
      <button className="VideoPlayBtn" onClick={() => {setVideoModal(true);}}><img className="SmVideo"/>Watch</button>
        <video className="BGVideo" autoPlay loop muted playsInline>
          <source src={props.translation('games_kitty_videoUrl')} type="video/mp4"></source>
        </video>
        <div className="BGPattern100"></div>
        <div className="MovieScreen"></div>
      </section>

      <section className="IntroduceGame">
        <div className="IntroduceGameWrap">
          <div className="PhoneImgWrap">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              effect={'fade'}
              modules={[EffectFade, Autoplay]}
              className="HeightSwiper"
            >
              <SwiperSlide>
                <img className="HeightImg" src={props.translation('games_kitty_phoneImg1')} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="HeightImg" src={props.translation('games_kitty_phoneImg2')} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="HeightImg" src={props.translation('games_kitty_phoneImg3')} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="HeightImg" src={props.translation('games_kitty_phoneImg4')} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="GameText">
            <div className="GameTextWrap">
            <img src="https://data.actgames.co.kr/actgames/images/games/kity/app.webp"/>
              <h4>{props.translation('games_kitty_Title')}</h4>
              <p>{props.translation('games_kitty_Dec')}</p>
              <div className="DownloadLink">
                  {/*TODO: 각 마켓 URL 로 변경 필요*/}
                <Link to={props.translation('games_kitty_googleUrl')} target="_blank"><img src="https://data.actgames.co.kr/actgames/images/common/button_google.png"/></Link>
                <Link to={props.translation('games_kitty_iosUrl')} target="_blank"><img src="https://data.actgames.co.kr/actgames/images/common/button_apple.png"/></Link>
                {/* <Link to=""><img src="https://data.actgames.co.kr/actgames/images/common/download_windows.png"/></Link>
                <Link to=""><img src="https://data.actgames.co.kr/actgames/images/common/dwonload_apk.png"/></Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="GameImageSection">
        <div className="GameImageSectionWrap">
        <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              effect={'fade'}
              modules={[EffectFade, Autoplay]}
              className="ImagesSwiper"
            >
              <SwiperSlide>
                <img src={props.translation('games_kitty_copyrightBgImg1')} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={props.translation('games_kitty_copyrightBgImg2')} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={props.translation('games_kitty_copyrightBgImg3')} />
              </SwiperSlide>
            </Swiper>
            <div className="CopyRight">
              <div className="CopyRightWrap">
                {/* <div className="BtnWrap">
                <Link to={props.translation('games_kitty_Privacy')}>Privacy Policy</Link>
                <Link to={props.translation('games_kitty_Terms')}>Terms of Use</Link>
                </div> */}
                <p>{props.translation('games_kitty_copyright')}</p>
              </div>
            </div>
        </div>
      </section>
      <section className="NextPrevWrap">
        <Link to="/games/aggretsuko" className="PrevBtn ThumbAgg">
          <p className="TextWrap"><img />{props.translation('games_agg_name')}</p>
          <div className="BGPattern100"></div>
        </Link>
        <Link to="/games/mogmog" className="NextBtn ThumbMogMog">
          <p className="TextWrap">{props.translation('games_mog_name')}<img /></p>
          <div className="BGPattern100"></div>
        </Link>
      </section>
      {isShowVideoModal && <div className="VideoModal">
        <video className="ModalVideo" controls autoPlay>
            <source src={props.translation('games_kitty_videoUrl')} type="video/mp4"></source>
        </video>
        <button className="CloseBtn" onClick={() => { setVideoModal(false); }}></button>
    </div>}
    </div>
  );
}

export default Kitty;
