import React, {useEffect, useState} from "react";
import '../css/games.css';
import {Link} from 'react-router-dom';

function Games(props) {

    useEffect(() => {
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <section className="GamesSetion">
            <div className="SectionHeaderWrap">
                {/* <h2>{props.translation('about_header_text')}</h2> */}
                <div className="BGPattern100"></div>
                <div className="MovieScreen"></div>
            </div>
            <div className="GamesListWrap">
                <div className="GameList">
                    <div className="GameGrid">
                        <Link to="/games/zoidswildarea" className="GameContent">
                            <img src="https://data.actgames.co.kr/actgames/images/games/zoids/thumb.png"/>
                            <h6>ZOIDS WILD ARENA</h6>
                            <div className="ServiceIcon">
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_google.png"/>
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_apple.png"/>
                                <img src="https://data.actgames.co.kr/actgames/images/common/download_windows.png"/>
                                <img src="https://data.actgames.co.kr/actgames/images/common/dwonload_apk.png"/>
                            </div>
                        </Link>
                        <Link to="/games/bubspuzzleblast" className="GameContent">
                            <img src="https://data.actgames.co.kr/actgames/images/games/bubs/thumb.png"/>
                            <h6>Bub's Puzzle Blast</h6>
                            <div className="ServiceIcon">
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_google.png"/>
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_apple.png"/>
                            </div>
                        </Link>
                        <Link to="/games/aggretsuko" className="GameContent">
                            <img src="https://data.actgames.co.kr/actgames/images/games/aggretsuko/thumb.png"/>
                            <h6>Aggretsuko : The short-timer strikes back</h6>
                            <div className="ServiceIcon">
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_google.png"/>
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_apple.png"/>
                            </div>
                        </Link>
                        <Link to="/games/pekopop" className="GameContent">
                            <img src="https://data.actgames.co.kr/actgames/images/games/pekopop/thumb.png"/>
                            <h6>Peko Pop Match 3 Puzzle</h6>
                            <div className="ServiceIcon">
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_google.png"/>
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_apple.png"/>
                            </div>
                        </Link>
                        <Link to="/games/mogmog" className="GameContent">
                            <img src="https://data.actgames.co.kr/actgames/images/games/mogmog/thumb.png"/>
                            <h6>Mogmog Planet : Match 3 Puzzle</h6>
                            <div className="ServiceIcon">
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_google.png"/>
                                <img src="https://data.actgames.co.kr/actgames/images/common/button_apple.png"/>
                            </div>
                        </Link>
                        <Link className="GameContent Disabled">
                            <img src="https://data.actgames.co.kr/actgames/images/games/Transformer/games_thumb.png"/>
                            <h6>Transformers Project</h6>
                            <p>Coming Soon</p>
                        </Link>
                        <Link className="GameContent Disabled">
                            <img src="https://data.actgames.co.kr/actgames/images/games/kity/games_thumb.png"/>
                            <h6>Hello Kitty project</h6>
                            <p>Coming Soon</p>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Games;
