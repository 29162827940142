import React from "react";
import '../css/terms.css';

function Kor_provision() {
  return (
    <>
        <div id="provision" className="terms sub">
            <div className="container">
                <h3 className="sub_title">게임서비스 이용약관</h3>
                <hr className="star-primary wow zoomIn"></hr>
                <div className="terms_box">
                    <h4>제 1 조 (목적)</h4>
                    <p>이 이용약관은 ACT GAMES Co., Ltd.(이하 '회사')가 제공하는 모든 게임 및 제반 서비스의 이용과 관련하여 회원과 회사간의 가입 및 이용에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.</p>
                </div>

                <div className="terms_box">
                    <h4>제 2조 (용어의 정의)</h4>
                    <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                    <ul>
                        <li>'회원'이란 회사가 제공하는 모든 게임 및 제반 서비스를 이용하는 자(일반회원 및 임시회원 포함)를 말하며, 그 유형은 아래와 같이 구분됩니다.
                            <ul className="sub_list">
                                <li>1) 일반회원: 제휴 되어 있는 플랫폼 사업자를 통해 정보를 입력하여 회사가 제공하는 일반 계정을 부여 받은 회원을 말합니다. 이 때, ‘계정’이라 함은 서비스의 이용과 관련하여 회원 식별을 위한 문자 및 숫자의 조합을 말합니다.</li>
                                <li>2) 임시회원: 정보의 입력 없이, 회사가 랜덤하게 부여하는 임시계정을 통해 서비스를 이용하는 자를 말합니다. 임시회원은 서비스를 이용하는 디바이스(기기)가 초기화되거나 콘텐츠를 디바이스에서 삭제하는 경우 임시계정이 초기화되어 기존의 게임 데이터 등 정보가 소멸합니다.</li>
                            </ul>
                        </li>
                        <li>"콘텐츠”란 정보통신망(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 따름)에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것이며 회사가 제공하는 모든 게임 및 네트워크 서비스, 어플리케이션, 게임 머니 및 게임 아이템 등 서비스와 관련되어 디지털 방식으로 제작된 내용물 일체를 말합니다.</li>
                        <li>"유료콘텐츠”란 유료캐시(제20조 제1항), 기타 게임 관련 아이템 등 회원이 서비스와 관련하여 회사에 금전 등 대가를 지급하고 유상으로 구매하는 콘텐츠를 말합니다.</li>
                        <li>“게시물”은 회원이 서비스를 이용함에 있어 회사가 관리 · 운영하는 전자게시판(회사 웹사이트 또는 애플리케이션 내 게시판 및 회사의 SNS 게시판 등을 포함)에 게시한 문자, 문서, 그림, 음성, 영상 또는 이들의 조합으로 이루어진 모든 정보를 말합니다.</li>
                        <li>‘디바이스’란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 PC, 스마트폰, 태블릿, 등의 전자기기를 말합니다.</li>
                        <li>‘서비스’란 디바이스의 종류와 상관없이, 회사가 회원에게 제공하는 네트워크 이용에 관한 서비스 일체를 말합니다.</li>
                        <li>"애플리케이션"이란 회사가 제공하는 서비스를 이용하기 위하여 본 조 제5항에서 설명한 디바이스를 통해 다운로드 받거나 설치하여 사용하는 프로그램 일체를 말합니다.</li>
                        <li>"오픈마켓사업자"란 당사 게임을 설치하고 결제할 수 있도록 제공하는 오픈마켓 등 전자상거래 제공자 일체(게임 내 결제를 제공하는 사업자, 이동통신사 포함)를 제공하는 사업자를 말합니다. (예. 구글 플레이 앱스토어, 애플 앱스토어, 원스토어 등)</li>
                        <li>“결제대행사”란 당사 게임을 웹으로 결제가 가능하도록 제휴하여 서비스를 제공하는 사업자를 말합니다. 관련 대행사는 태국/베트남 국가에서만 서비스 됩니다.</li>
                        <li>"플랫폼사업자"란 당사 게임을 알리거나 이용함에 있어서 회사와 제휴하여 서비스를 제공하는 사업자 및 관련 서비스 일체를 말합니다.</li>
                        <li>”‘캐시”는 회사가 서비스하는 개별 애플리케이션 게임 내에서 사용되는 가상의 데이터를 의미하며, 회원은 해당 게임 내 유료 서비스의 이용 또는 콘텐츠, 상품의 구매 등에 캐시를 사용할 수 있습니다.</li>
                        <li>“In-App결제”란 캐시, 아이템, 기능 기타 콘텐츠 구매 등의 목적으로 회사가 서비스하는 애플리케이션 게임 내에서 이루어지는 결제를 말합니다.</li>
                        <li>“Wep결제”란 캐시, 아이템, 기능 기타 콘텐츠 구매 등의 목적으로 회사가 제공하는 웹사이트에서 이루어지는 결제를 말합니다.</li>
                        <li>이 약관에서 사용하는 용어의 정의는 본 조에서 정하는 것을 제외하고는 관계법령에서 정하는 바에 의합니다. 관계법령에서 정하지 않는 것은 일반적인 상 관례에 의합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 3 조 (회사정보 등의 제공)</h4>
                    <p>회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 게임 서비스 초기화면이나 게임 서비스 홈페이지(https://www.actgames.co.kr/)에 게시하여 회원이 이를 쉽게 알 수 있도록 합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</p>
                    <ul>
                        <li>상호 및 대표자의 성명</li>
                        <li>영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다.)</li>
                        <li>전화번호, 전자우편주소</li>
                        <li>사업자 등록번호</li>
                        <li>통신판매업 신고번호</li>
                        <li>개인정보 취급방침</li>
                        <li>서비스 이용약관</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 4 조 (약관의 효력 및 적용과 변경)</h4>
                    <ul>
                        <li>이 약관은 회원이 애플리케이션을 설치 및 실행하고 약관에 동의함과 동시에 효력이 발생합니다. 회사는 회원이 쉽게 볼 수 있도록 회사 웹사이트 및 애플리케이션 화면에 이 약관의 내용을 게시합니다.</li>
                        <li>회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「게임산업진흥에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업진흥법」 등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                        <li>제2항에 따라 약관을 변경하는 경우, 회사는 변경된 약관이 적용되기 최소 7일, 최대 30일 전부터 변경된 약관의 내용 및 적용일자를 회사 홈페이지 및 공식 카페, 어플리케이션 공지사항에 게시하는 방법으로 회원에게 고지합니다. 다만, 회원에게 불리한 내용의 약관 개정의 경우에는 위 고지 외에 전자우편, 전자쪽지, 로그인시 동의창, 휴대전화 메시지 중 1가지 이상의 방법으로 회원에게 추가적으로 통지합니다.</li>
                        <li>회원은 변경된 약관에 대해 거부할 권리가 있습니다. 본 약관의 변경에 대해 이의가 있는 회원은 서비스 이용을 중단하고 이용계약을 해지(회원 탈퇴)할 수 있습니다.</li>
                        <li>회사가 제3항에 따라 변경된 약관을 고지 또는 통지하면서 회원이 적용일자까지 거부의 의사표시를 하지 않으면 변경된 약관에 동의한 것으로 간주합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 5 조 (약관 이외의 준칙)</h4>
                    <p>이 약관에서 정하지 않은 사항은 회사가 정한 개별 서비스 이용약관 및 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령에서 정하는 바에 따르며 개별 서비스 이용약관 및 관계법령에서 정하지 않는 것은 일반적인 상관례에 따릅니다.</p>
                </div>
                
                <div className="terms_box">
                    <h4>제 6 조 (운영정책)</h4>
                    <ul>
                        <li>약관을 적용하기 위하여 필요한 사항과 약관에서 구체적 범위를 정하여 위임한 사항을 서비스 운영정책<br/>(이하 “운영정책”이라 합니다)으로 정할 수 있습니다.</li>
                        <li>회사는 운영정책의 내용을 회원이 알 수 있도록 서비스 홈페이지 혹은 공식 커뮤니티에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지하여야 합니다.</li>
                        <li>운영정책을 개정하는 경우에는 제4조 제3항의 절차에 따릅니다. 다만, 운영정책 개정내용이 다음 각 호의 어느 하나에 해당하는 경우에는 제3항의 방법으로 사전에 공지합니다.
                            <ul className="sub_list">
                                <li>1) 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우</li>
                                <li>2) 회원의 권리·의무와 관련 없는 사항을 개정하는 경우</li>
                                <li>3) 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측할 수 있는 범위 내에서 운영정책을 개정하는 경우</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 7 조 (이용신청 및 방법)</h4>
                    <ul>
                        <li>회사가 제공하는 서비스를 이용하고자 하는 자는 약관의 내용에 대하여 동의를 하고, 개별 서비스 초기 화면이나 서비스 홈페이지(https://www.actgames.co.kr/)에서 제공하는 이용신청서를 작성하는 방법으로 이용신청을 하여야 합니다.</li>
                        <li>회원은 이용 신청 시 회사에서 요구하는 제반 정보를 제공하여야 합니다.</li>
                        <li>회사는 회원에게 실명확인 및 본인인증을 요청할 수 있으며, 그 절차와 방식은 관련 법령에 따릅니다.</li>
                        <li>회원은 제1항의 이용 신청 시 본인의 실명 및 실제 정보를 기재하여야 합니다. 실명 또는 식별정보를 허위로 기재하거나 타인의 명의를 도용한 경우 이 약관에 의한 회원의 권리를 주장할 수 없고, "회사"는 환급 없이 이용계약을 취소하거나 해지할 수 있습니다.</li>
                        <li>청소년(18세 미만의 자로서 「초 중등교육법」 제2조의 규정에 의한 고등학교에 재학 중인 학생을 포함한다)이 이용신청을 할 경우에는 법정대리인의 동의를 얻어야 하고, 구체적인 동의절차는 「게임산업진흥에 관한 법률」 및 시행령에 따라 "회사"가 제공하는 방법에 따르도록 합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 8 조 (이용 계약의 승낙)</h4>
                    <ul>
                        <li>서비스에 대한 이용계약은 신청자가 애플리케이션을 설치하고 애플리케이션 내에서 ACT GAMES Co., Ltd. 게임 서비스 이용약관에 동의합니다.’는 표시에 동의함으로써 이 약관에 동의하여 회사에 서비스 이용을 신청하고, 회사가 이러한 신청에 대하여 서비스 이용을 승낙함으로써 성립합니다.</li>
                        <li>회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않을 수 있습니다.
                            <ul className="sub_list">
                                <li>1) 제7조에 위반하여 이용신청을 하는 경우</li>
                                <li>2) 이용요금을 납부하지 않거나 잘못 납부하여 확인할 수 없는 경우</li>
                                <li>3) 미성년자가 법정대리인의 동의를 얻지 아니하거나 동의를 얻었음을 확인할 수 없는 경우</li>
                                <li>4) 최근 3개월 내 이용제한 기록이 있는 회원이 이용신청을 하는 경우</li>
                                <li>5) 제3자의 신용카드, 유/무선 전화, 은행 계좌 등을 무단으로 이용 또는 도용하여 ‘콘텐츠’ 이용요금을 결제하는 경우</li>
                                <li>6) 대한민국 이외의 국가 중 회사에서 아직 서비스를 제공할 것으로 결정하지 않은 국가에서 서비스를 이용하는 경우로 회사가 해외 서비스 업체와 체결한 계약이나 특정 국가에서 접속하는 회원에 대한 서비스 제공과 관련하여 서비스제공을 제한할 필요가 있는 경우</li>
                                <li>7) 게임산업진흥에 관한 법률』 등 관련 법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우</li>
                                <li>8) 기타 상기 제1호 내지 제7호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우</li>
                            </ul>
                        </li>
                        <li>회사는 다음 각 호의 경우에 그 원인이 해소될 때까지 승낙을 보류할 수 있습니다.
                            <ul className="sub_list">
                                <li>1) 서비스 관련 설비에 여유가 없는 경우</li>
                                <li>2) 업무상 또는 기술상 서비스를 제공하지 못할 장애 사항이 발생한 경우</li>
                                <li>3) 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우</li>
                                <li>4) 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우</li>
                                <li>5) 그 밖의 ‘회원’으로서 부적절한 행위를 할 우려가 있다고 인정되는 경우</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 9 조 (개인정보의 보호 및 사용)</h4>
                    <ul>
                        <li>회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침에 따릅니다. 다만, 회사가 제공하는 서비스 이외의 링크된 서비스에서는 회사의 개인정보처리방침이 적용되지 않습니다.</li>
                        <li>서비스의 특성에 따라 회원의 개인정보와 관련이 없는 별명·캐릭터 사진·상태정보 등 자신을 소개하는 내용이 공개될 수 있습니다.</li>
                        <li>회사는 관련 법령에 의해 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다.</li>
                        <li>회사는 회원의 귀책사유로 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지 않습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 10 조 (회사의 의무)</h4>
                    <ul>
                        <li>회사는 회원에 대한 서비스 제공과 관련하여 관련 법령 및 이 약관, 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스 제공을 위하여 최선을 다합니다.</li>
                        <li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보(신용정보 포함)를 보호하기 위하여 최선을 다하며, 이와 관련하여 개인정보취급방침을 공시하고 준수합니다.</li>
                        <li>회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실·훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 11 조 (회원의 의무)</h4>
                    <ul>
                        <li>회원은 서비스 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안됩니다.
                            <ul className="sub_list">
                                <li>1) 고객 문의, 유료 콘텐츠 복구 및 환불요청, 이벤트 당첨 등으로 인해 회사에 개인정보 제공 시 실명이 아닌 정보 또는 다른 사람의 정보를 사용하거나, 허위의 사실을 기재하는 행위</li>
                                <li>2) 다른 회원의 회원 계정정보를 도용, 부정하게 사용하거나, 타인의 신용카드, 유/무선전화, 은행 계좌 등을 무단으로 도용하여 유료콘텐츠를 구매하는 행위</li>
                                <li>3) 비정상적인 방법을 통해 사이버 자산(ID, 캐릭터, 아이템, 게임 머니 등)을 매매 또는 증여하거나, 이를 취득하여 이용하는 행위</li>
                                <li>4) 회사의 서비스 및 제공된 애플리케이션을 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제, 유통, 조장하거나 상업적으로 이용, 또한 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위</li>
                                <li>5) 회사의 서비스 및 제공된 애플리케이션을 이용하여 자신 또는 타인으로 하여금 재산상 이익을 얻게 하는 행위</li>
                                <li>6) 회사의 지적재산권, 제3자의 지적재산권, 초상권 등 기타 권리를 침해하거나 다른 회원의 개인정보를 수집, 저장, 유포, 게시하는 행위</li>
                                <li>7) 제3자를 기망하여 이득을 취하거나 회사가 제공하는 서비스를 불건전하게 이용하여, 제3자에게 피해를 주는 행위</li>
                                <li>8) 타인의 명예를 훼손하거나 타인에게 손해를 가하는 행위</li>
                                <li>9) 음란, 저속한 정보를 교류, 게재하거나 음란 사이트를 연결(링크)하거나 승인되지 않은 광고 및 홍보물 게재하는 행위</li>
                                <li>10) 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 상대방에게 전송, 도달, 유포하는 행위</li>
                                <li>11) 재물을 걸고 도박 하는 등 사행 행위를 유도하거나 참여하는 행위</li>
                                <li>12) 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램) 또는 컴퓨터 소프트웨어, 하드웨어, 전기 통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 전송, 게시, 유포, 사용하는 행위</li>
                                <li>13) 회사로부터 특별한 권리를 부여 받지 않고 애플리케이션을 변경하거나 애플리케이션에 다른 프로그램을 추가 또는 삽입하거나 서버를 해킹, 역 설계하거나, 소스 코드나 애플리케이션 데이터의 유출 및 변경, 별도의 서버를 구축하거나 웹사이트의 일부분을 임의로 변경 또는 도용하여 회사를 사칭하는 행위</li>
                                <li>14) 회사의 직원이나 운영자를 가장하거나 사칭, 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위</li>
                                <li>15) 회사의 동의 없이 영리, 영업, 광고, 정치 활동, 불법 선거운동 등을 목적으로 서비스를 사용하는 행위</li>
                                <li>16) 서비스의 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 등을 전송하는 행위</li>
                                <li>17) 기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 관계 법령에 위배되는 행위</li>
                            </ul>
                        </li>
                        <li>회원은 회사 웹사이트 또는 애플리케이션 상 공지사항 및 이용약관 변경사항 등을 수시로 확인하고 준수할 의무가 있으며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</li>
                        <li>회원의 계정 및 모바일 기기에 관한 관리 책임은 회원에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안 됩니다. 모바일 기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을 지지 않습니다.</li>
                        <li>회원은 각 오픈마켓에서 부정한 결제가 이루어지지 아니하도록 결제 비밀번호 기능을 설정하고 관리하여야 합니다. 회원의 부주의로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 12 조 (서비스의 제공)</h4>
                    <ul>
                        <li>회사는 제7조, 제8조의 규정에 따라 이용계약이 완료된 회원에게 그 즉시 서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의 필요에 따라 지정된 일자부터 서비스를 개시할 수 있습니다.</li>
                        <li>회사는 회원에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여 기타 부가적인 서비스를 함께 제공할 수 있습니다.</li>
                        <li>회사는 회원의 등급을 구분하고 이용시간, 이용횟수, 제공 서비스의 범위 등을 세분화하여 이용에 차등을 둘 수 있습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 13 조 (서비스의 이용)</h4>
                    <ul>
                        <li>서비스는 회사의 영업방침에 따라 정해진 시간 동안 제공합니다. 회사는 서비스 제공시간을 게임 애플리케이션 초기화면이나 서비스 공지사항에 적절한 방법으로 안내합니다.</li>
                        <li>제1항에도 불구하고 회사는 다음 각 호의 경우에는 서비스의 전부 또는 일부를 일시 정지할 수 있습니다. 이 경우 회사는 사전에 그 정지의 사유와 기간을 게임 애플리케이션 초기화면이나 서비스 공지사항 등에 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우 사후에 공지할 수 있습니다.
                            <ul className="sub_list">
                                <li>1) 시스템 정기점검, 서버의 증설 및 교체, 네트워크의 불안정 등의 시스템 운영상 필요한 경우</li>
                                <li>2) 정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비 보수 또는 점검 등으로 인하여 정상적인 서비스 제공이 불가능한 경우</li>
                                <li>3) 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 회사가 통제할 수 없는 상황이 발생한 경우</li>
                            </ul>
                        </li>
                        <li>회사는 모바일 기기를 위한 전용 애플리케이션 또는 네트워크를 이용하여 서비스를 제공합니다. 회원은 애플리케이션을 다운로드하여 설치하거나 네트워크를 이용하여 무료 또는 유료로 서비스를 이용할 수 있습니다.</li>
                        <li>유료 콘텐츠의 경우에는 해당 서비스에 명시된 요금을 지급하여야 이용할 수 있습니다. 네트워크를 통해 애플리케이션을 다운로드하거나 서비스를 이용하는 경우에는 가입한 이동통신사에서 정한 별도의 요금이 발생할 수 있습니다.</li>
                        <li>다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 디바이스 또는 이동통신사의 특성에 맞도록 제공됩니다. 디바이스의 변경·번호 변경 또는 해외 로밍의 경우에는 콘텐츠의 전부 또는 일부의 이용이 불가능할 수 있으며, 이 경우 회사는 책임을 지지 않습니다.</li>
                        <li>다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 백그라운드 작업이 진행될 수 있습니다. 이 경우 모바일 기기 또는 이동통신사의 특성에 맞도록 추가요금이 발생할 수 있으며 이와 관련하여 회사는 책임을 지지 않습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 14 조 (서비스의 변경 및 중단)</h4>
                    <ul>
                        <li>회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를 변경할 수 있으며, 변경 전에 해당 내용을 서비스 내에 공지합니다. 다만, 버그·오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.</li>
                        <li>회사는 영업양도·분할·합병 등에 따른 영업의 폐지, 게임제공의 계약만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 중단일자 30일 이전까지 중단일자·중단사유·보상조건 등을 게임 애플리케이션 초기화면 또는 그 연결화면을 통해 공지하여 회원에게 통지합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 15 조 (정보의 수집 등)</h4>
                    <ul>
                        <li>회사는 회원간에 이루어지는 채팅 내용을 저장·보관할 수 있으며 이 정보는 회사만이 보유합니다. 회사는 회원간의 분쟁 조정, 민원 처리 또는 게임질서의 유지를 위한 경우에 한하여, 제3자는 법령에 따라 권한이 부여된 경우에 한하여 이정보를 열람할 수 있습니다.</li>
                        <li>회사 또는 제3자가 제1항에 따라 채팅 정보를 열람할 경우 회사는 사전에 열람의 사유 및 범위를 해당 회원에게 고지합니다. 다만, 제11조 제1항에 따른 금지행위의 조사·처리·확인 또는 그 행위로 인한 피해 구제와 관련하여 이 정보를 열람해야 할 경우에는 사후에 고지할 수 있습니다.</li>
                        <li>회사는 서비스의 원활하고 안정적인 운영 및 서비스 품질의 개선을 위하여 회원의 개인정보를 제외한 회원의 디바이스 정보(설정, 사양, 운영체제, 버전 등)를 수집 · 활용할 수 있습니다.</li>
                        <li>회사는 서비스 개선 및 회원 대상 서비스 소개 등을 위한 목적으로 회원에게 추가정보를 요청할 수 있습니다. 이 요청에 대해 회원은 승낙하거나 거절할 수 있으며, 회사가 이 요청을 할 경우에는 회원이 이 요청을 거절할 수 있다는 뜻을 함께 고지합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 16 조 (광고의 제공)</h4>
                    <ul>
                        <li>회사는 서비스의 운영과 관련하여 게임서비스 내에 광고를 게재할 수 있습니다. 또한 수신에 동의한 회원에 한하여 전자우편, 문자서비스(LMS/SMS), 푸시메시지(Push Notification) 등의 방법으로 광고성 정보를 전송할 수 있습니다. 이 경우 회원은 언제든지 수신을 거절할 수 있으며, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지 아니합니다.</li>
                        <li>회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다.</li>
                        <li>제2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다. 다만, 회사가 고의 또는 중과실로 손해의 발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는 그러하지 아니합니다.</li>
                        <li>회원의 본 약관에 대한 동의는 회사 홈페이지 또는 서비스 이용 시 광고 노출에 대한 동의를 포함합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 17 조 (저작권 등의 귀속)</h4>
                    <ul>
                        <li>회사가 제작한 게임서비스 내의 콘텐츠에 대한 저작권과 기타 지적재산권은 회사에 귀속합니다.</li>
                        <li>회원은 회사가 제공하는 게임서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의 없이 복제⋅전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을 포함합니다. 이하 같습니다)에 의하여 영리목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다.</li>
                        <li>회원은 게임 내에서 보여지거나, 게임서비스와 관련하여 회원 또는 다른 회원이 애플리케이션 또는 게임서비스를 통해 업로드 또는 전송하는 대화 텍스트를 포함한 커뮤니케이션, 이미지, 사운드 및 모든 자료 및 정보(이하 “이용자 콘텐츠”라 합니다.)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다.
                            <ul className="sub_list">
                                <li>1) 해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송, 2차적 저작물 작성 등 어떠한 형태로든 이용 가능하며, 이용기간과 지역에는 제한이 없음)</li>
                                <li>2) 이용자 콘텐츠를 제작한 회원의 사전 동의 없이 거래를 목적으로 이용자 콘텐츠를 판매, 대여, 양도 행위를 하지 않음</li>
                            </ul>
                        </li>
                        <li>게임 내에서 보여지지 않고 게임서비스와 일체화되지 않은 회원의 이용자 콘텐츠(예컨대, 일반게시판 등에서의 게시물)에 대하여 회사는 회원의 명시적인 동의가 없이 이용하지 않으며, 회원은 언제든지 이러한 이용자 콘텐츠를 삭제할 수 있습니다.</li>
                        <li>회사는 회원이 게시하거나 등록하는 서비스 내의 게시물에 대해 제11조 제1항에 따른 금지행위에 해당된다고 판단되는 경우에는 사전 통지 없이 이를 삭제 또는 이동하거나 그 등록을 거절할 수 있습니다.</li>
                        <li>회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 회원은 회사에 해당 정보의 삭제 또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 회사는 신속하게 필요한 조치를 취하고 이를 신청인에게 통지합니다.</li>
                        <li>이 조는 회사가 게임서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 18 조 (유료 콘텐츠의 구매, 사용기간 및 이용)</h4>
                    <ul>
                        <li>회원은 서비스와 관련한 유료콘텐츠를 구매할 수 있습니다. 이와 관련하여, 서비스를 이용하는 디바이스의 종류, 이동통신사, 플랫폼사업자 또는 오픈마켓 사업자의 정책에 따라 구체적인 결제금액 또는 납부방식에 차이가 발생할 수 있습니다.</li>
                        <li>서비스 내에서 회원이 구매한 유료콘텐츠는 해당 서비스 애플리케이션을 설치한 디바이스에서만 사용할 수 있습니다.</li>
                        <li>유료콘텐츠의 구매 시 명시된 기간에 따릅니다. 다만, 제13조 제2항에 따라 서비스 중단이 이루어지는 경우 기간의 정함이 없는 유료 콘텐츠의 이용기간은 서비스 중단 공지 시 공지된 서비스의 중단일자까지로 합니다.</li>
                        <li>회원은 회사가 별도로 정하여 고지한 방법 이외에는 회원 본인의 계정에서만 구매한 유료 콘텐츠를 이용할 수 있으며, 이를 제3자에게 양도, 대여, 매매할 수 없습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 19 조 (In-App결제)</h4>
                    <ul>
                        <li>회사가 서비스를 위하여 제공하는 애플리케이션은 아이템 구매를 위한 In-App결제 기능을 포함합니다.</li>
                        <li>회원은 디바이스의 비밀번호 설정 기능, 오픈마켓에서 제공하는 비밀번호 설정 기능 등을 이용하여 제3자의 회원의 디바이스를 통한 In-App결제를 방지하여야 하며, 회사는 이를 위하여 방송통신위원회의 권고 및 "오픈마켓 모바일콘텐츠 결제 가이드라인"에 따라 오픈마켓에서 제공하는 인증절차 등이 적용된 In-App결제를 위한 모듈, 라이브러리 등을 애플리케이션에 적용합니다.</li>
                        <li>회원의 디바이스 및 오픈마켓의 비밀번호 설정 기능 등을 이용하지 않거나 위 비밀번호를 부주의하게 노출하여 발생하는 제3자의 In-App결제에 대하여 회사는 어떠한 책임도 부담하지 않습니다.</li>
                        <li>이동통신사의 청소년 요금제에 가입한 회원이 해당 디바이스에서 In-App결제를 하는 경우, 해당 결제에 대하여 법정대리인의 동의가 있는 것으로 간주합니다.</li>
                        <li>회원은 In-App결제시 그에 따른 유료콘텐츠 대금을 지급할 의무가 있습니다.
                    </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 20 조 (Web결제)</h4>
                    <ul>
                        <li>회사가 서비스를 위하여 제공하는 In-App결제 기능 외 Web결제가 존재합니다.</li>
                        <li>회원이 Web결제에 필요한 계정 및 비밀번호를 부주의하게 노출하여 발생하는 제3자의 Web결제에 대하여 회사는 어떠한 책임도 부담하지 않습니다.</li>
                        <li>Web결제는 바로 결제가 아닌 선불카드를 구매하여 결제하는 방식으로 태국/베트남에서만 구매가 가능합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 21 조 (캐시 및 포인트)</h4>
                    <ul>
                        <li>캐시는 (i) 회원이 In-App결제를 통해 유상으로 구매하는 캐시(이하 “유료캐시”라 함)와 (ii) 회원이 게임을 진행하면서 획득하거나, 이벤트를 통하여 회사로부터 무상으로 제공받는 캐시(이하 “무료캐시”라 함)로 구분되며, 각 유형에 따라 개별적으로 관리됩니다.</li>
                        <li>회사는 무료캐시와 별도로 회원에게 무상으로 게임 등 서비스 이용과 관련하여 사용할 수 있는 마일리지 등 포인트(이하 “무료포인트”라 함)를 제공할 수 있습니다.</li>
                        <li>회원이 유료캐시, 무료캐시 및 무료포인트를 함께 보유한 상태에서 게임 내 유료서비스의 이용 또는 콘텐츠, 상품의 구매 등에 이를 사용하는 경우, 해당 시점에서 보유하고 있는 (i) 유료캐시, (ii) 무료캐시, (iii) 무료포인트의 순으로 차감이 순차적으로 이루어집니다.</li>
                        <li>무료캐시 및 무료포인트는 환불, 배상 또는 보상의 대상이 되지 않으며, 회사는 이와 관련한 책임을 부담하지 않습니다.</li>
                        <li>무료캐시 및 무료포인트의 경우 그 사용조건에 제한이 있을 수 있습니다. 회사는 무료캐시 및 무료포인트의 사용과 관련한 구체적인 조건(유효기간, 사용범위 기타 필요한 사항)을 별도의 공지를 통하여 회원에게 공지합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 22 조 (청약 철회 및 구매 대금의 환불 등)</h4>
                    <ul>
                        <li>유료콘텐츠를 구매한 회원은 구매일 또는 유료콘텐츠 이용가능일로부터 7일 이내까지는 웹결제 사이트 또는 애플리케이션 등을 통하여 별도의 수수료 없이 구매한 유료콘텐츠에 대한 청약철회(구매취소)를 할 수 있습니다.</li>
                        <li>회원은 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회를 할 수 없습니다. 다만, 가분적 콘텐츠로 구성된 구매계약의 경우에는 가분적 콘텐츠 중 다음 각 호에 해당하지 아니하는 나머지 부분에 대하여는 그러하지 아니합니다.
                            <ul className="sub_list">
                                <li>1) 구매 즉시 사용되거나 적용되는 유료 콘텐츠</li>
                                <li>2) 추가혜택이 제공되는 경우에 그 추가 혜택이 사용된 콘텐츠</li>
                                <li>3) 개봉행위를 사용으로 볼 수 있거나 개봉 시 효용이 결정되는 콘텐츠의 개봉행위가 있는 경우</li>
                                <li>4) 메시지함 및 선물함 등 유료 콘텐츠의 송수신과 관련된 화면에서 수락 등의 동의 과정을 거친 경우</li>
                            </ul>
                        </li>
                        <li>회사는 제2항 각 호의 규정에 따라 청약철회가 불가능한 콘텐츠의 경우에는 그 사실을 회원이 쉽게 알 수 있는 곳에 명확하게 표시하고, 해당 콘텐츠의 시험사용 상품을 제공(한시적 이용의 허용, 체험용 제공 등)하거나 이에 대한 제공이 곤란한 경우에는 콘텐츠에 관한 정보 제공함으로써 회원의 청약철회의 권리행사가 방해 받지 아니하도록 조치합니다. 만약 회사가 이러한 조치를 하지 아니한 경우에는 제2항 각 호의 청약철회 제한사유에도 불구하고 회원은 청약철회를 할 수 있습니다.</li>
                        <li>회원은 제1항 및 제2항에도 불구하고 구매한 유료 콘텐츠의 내용이 표시·광고의 내용과 다르거나 구매계약의 내용과 다르게 이행된 경우에 해당 콘텐츠가 이용 가능하게 된 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.</li>
                        <li>구매 대금의 환불은 회원이 이용하는 각 오픈마켓 사업자의 환불 운영정책에 따라 진행되며, 구체적인 환불 신청 절차는 회사의 운영정책에 따릅니다. 또한 환불이 이루어짐에 따라 서비스 내 유료콘텐츠는 이를 반영하여 차감 반영됩니다.</li>
                        <li>회원이 청약철회 및 환불 등을 요구하는 경우, 회사 고객센터 및 회사가 지정한 위탁업체에 별도의 개인정보 취급에 대한 동의 과정을 거친 후 회사가 플랫폼사업자 또는 오픈마켓 사업자를 통해 해당 회원의 구매내역을 확인하는 절차가 진행됩니다. 회사는 이 과정 중에서 회원의 정당한 철회 사유를 확인하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락하여 정확한 사실 확인을 할 수 있으며, 추가적으로 증빙을 요구할 수 있습니다. [예. 플랫폼사업자에 등록된 개인정보, 오픈마켓에서의 구매 또는 결제내역, 타인에 의하여 회원의 의도와 상이하게 구매가 이루어졌음을 확인할 수 있는 서류(가족관계 증명) 등]</li>
                        <li>미성년자가 모바일 기기에서 콘텐츠 구매계약을 체결하는 경우, 회사는 법정대리인의 동의가 없으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 고지하며, 미성년자가 법정대리인의 동의 없이 구매계약을 체결한 때에는 미성년자 본인 또는 법정대리인은 회사에 그 계약을 취소할 수 있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여 처분을 허락한 재산으로 콘텐츠를 구매한 경우 또는 미성년자가 속임수로써 자기를 성년자로 믿게 하거나 법정대리인의 동의가 있는 것으로 믿게 한 경우에는 취소할 수 없습니다.</li>
                        <li>콘텐츠 구매계약의 당사자가 미성년자인지 여부는 결제가 진행된 모바일 기기, 결제 실행자 정보, 결제 수단 명의자 등을 근거로 판단합니다. 또한 회사는 정당한 취소인지를 확인하기 위해 미성년자 및 법정대리인임을 증명할 수 있는 서류의 제출을 요청할 수 있습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 23 조 (미성년자의 청약 철회 등)</h4>
                    <ul>
                        <li>미성년자인 회원이 법정대리인의 동의 없이 유료콘텐츠를 구매한 경우, 미성년자 또는 법정대리인은 그에 대한 청약을 철회할 수 있습니다.</li>
                        <li>미성년자인 회원의 결제 금액이 법정대리인으로부터 처분을 허락 받은 재산의 범위 내인 경우 또는 해당 회원의 사술 등을 사용하여 회사가 해당 회원을 성년자로 믿도록 한 때에는 제1항의 청약 철회가 제한됩니다.</li>
                        <li>유료콘텐츠 구매자가 미성년자인지 여부는 결제가 진행된 단말기 또는 신용카드 등 결제수단의 명의자를 기준으로 판단됩니다.</li>
                        <li>본 조에 따른 미성년자의 청약 철회를 요청하시는 경우, 회원 또는 그 법정대리인은 회사의 요청에 따라 미성년자 및 법정대리인을 증명할 수 있는 서류를 제출하여야 합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 24 조 (과오납금의 환급)</h4>
                    <ul>
                        <li>회사는 과오납금이 발생하는 경우 과오납금을 회원에게 환급합니다. 다만, 과오납금이 회사의 고의 또는 과실 없이 회원의 과실로 인하여 발생한 경우에는 그 환급에 소요되는 실제 비용은 합리적인 범위 내에서 회원이 부담합니다.</li>
                        <li>애플리케이션을 통한 결제는 오픈마켓 사업자가 제공하는 결제방식에 따르며, 결제 과정에서 과오납금이 발생하는 경우, 회사 또는 오픈마켓 사업자에게 환급을 요청하여야 합니다.</li>
                        <li>애플리케이션의 다운로드 또는 네트워크 서비스의 이용으로 인해 발생한 통신요금(통화료, 데이터 통화료 등)은 환급 대상에서 제외될 수 있습니다.</li>
                        <li>환급은 서비스를 이용하고 있는 모바일 기기의 운영체제 종류에 따라 각 오픈마켓 사업자 또는 회사의 환급정책에 따라 진행됩니다.</li>
                        <li>회사는 과오납금의 환급을 처리하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며, 필요한 정보의 제공을 요청할 수 있습니다. 회사는 회원으로부터 환급에 필요한 정보를 받은 날부터 3영업일 이내에 환급합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 25 조 (회원에 대한 서비스 이용제한)</h4>
                    <ul>
                        <li>회원은 제11조에 따른 회원의 의무를 위반하는 행위를 하여서는 안 되며, 해당 행위를 하는 경우에 회사는 다음 각 호의 구분에 따른 회원의 서비스 이용제한, 관련 정보(글, 사진, 영상 등) 삭제 및 기타의 조치를 포함한 이용제한 조치를 할 수 있습니다. 이용제한 조치가 이루어지는 구체적인 사유 및 절차는 제25조제1항에 따라 개별 게임의 운영정책에서 정합니다.
                            <ul className="sub_list">
                                <li>1) 일부 권한 제한 : 일정기간 채팅 등 일정 권한을 제한</li>
                                <li>2) 캐릭터 이용제한 : 일정기간 또는 영구히 회원 캐릭터의 이용을 제한</li>
                                <li>3) 계정 이용제한 : 일정기간 또는 영구히 회원 계정의 이용을 제한</li>
                                <li>4) 회원 이용제한 : 일정기간 또는 영구히 회원의 게임서비스 이용을 제한</li>
                            </ul>
                        </li>
                        <li>제1항의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다.</li>
                        <li>회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당 계정의 서비스 이용을 정지할 수 있습니다.
                            <ul className="sub_list">
                                <li>1) 계정이 해킹 또는 도용 당했다는 정당한 신고가 접수된 경우</li>
                                <li>2) 불법프로그램 사용자 또는 작업장 등 위법행위자로 의심되는 경우</li>
                                <li>3) 그 밖에 각 호에 준하는 사유로 서비스 이용의 잠정조치가 필요한 경우</li>
                            </ul>
                        </li>
                        <li>제3항의 조사가 완료된 후, 유료 게임서비스의 경우에는 정지된 시간만큼 회원의 이용시간을 연장하거나 그에 상당하는 유료서비스 또는 캐쉬 등으로 보상합니다. 다만, 회원이 제3항 각 호의 사유에 해당하는 경우에는 그러하지 아니합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 26 조 (이용제한 조치의 사유와 절차)</h4>
                    <ul>
                        <li>회사는 제25조 제1항에 따른 이용제한 조치의 구체적인 사유 및 절차를 제11조 제1항에 따른 금지행위의 내용·정도·횟수·결과 등을 고려하여 운영정책으로 정합니다.</li>
                        <li>회사가 제25조 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각 호의 사항을 회원에게 사전 통지합니다. 다만, 긴급히 조치할 필요가 있는 경우에는 사후에 통지할 수 있습니다.
                            <ul className="sub_list">
                                <li>1) 이용제한 조치의 사유</li>
                                <li>2) 이용제한 조치의 유형 및 기간</li>
                                <li>3) 이용제한 조치에 대한 이의신청 방법</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 27 조 (이용제한 조치에 대한 이의신청 절차)</h4>
                    <ul>
                        <li>회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의 통지를 받은 날부터 14일 이내에 불복 이유를 기재한 이의 신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.</li>
                        <li>회사는 제1항의 이의신청서를 접수한 날부터 15일 이내에 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변합니다. 다만, 회사는 이 기간 내에 답변이 어려운 경우에는 그 사유와 처리일정을 통지합니다.</li>
                        <li>회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 28 조 (계약 해지 등)</h4>
                    <ul>
                        <li>회원은 언제든지 서비스 이용을 원하지 않는 경우 탈퇴를 통해 서비스 이용계약을 해지할 수 있으며, 해지의사를 밝힌 때에 즉시 해지처리가 이루어집니다. 회원탈퇴로 인해 회원이 게임서비스 내에서 보유한 게임이용정보는 모두 삭제되어 복구가 불가능하게 됩니다.</li>
                        <li>회원이 제11조 제1항 각 호에 위반하는 행위를 한 경우, 회사는 해당 회원과의 서비스 이용계약을 해지하거나 또는 기간을 정하여 서비스의 이용을 중지시킬 수 있습니다.</li>
                        <li>제2항에 따라 이용계약의 해지 또는 서비스의 이용을 중지시키는 경우, 회사는 회원에게 해당 조치 사유 및 효력 발생일에 관한 사항을 전자우편, 전자쪽지, 기타 수단을 통하여 통지합니다.</li>
                        <li>제2항의 규정에 따라 서비스 이용계약을 해지하거나 중단하는 경우, 회원은 서비스 관련 콘텐츠를 사용할 수 없으며, 기 지급한 유료콘텐츠 구매 대금 및 데이터 통화료, 월정액 서비스 이용료 등을 반환 받을 수 없습니다.</li>
                        <li>회사는 임시회원에 대하여 이메일 계정 주소, 비밀번호 등 고객정보를 수집하지 않으므로, 기존 디바이스 또는 플랫폼 연동이 초기화되거나 게임이 삭제되는 경우, 해당 임시회원에 대한 게임 관련 정보 및 데이터를 복구할 수 없으며 이와 관련한 책임을 부담하지 않습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 29 조 (손해배상 등)</h4>
                    <ul>
                        <li>회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아니 합니다.</li>
                        <li>회사가 개별서비스 제공자와 제휴 계약을 맺고 회원에게 개별서비스를 제공하는 경우에 회원이 개별서비스 이용약관에 동의를 한 뒤 개별서비스 제공자의 고의 또는 과실로 인해 회원에게 손해가 발생한 경우에 그 손해에 대해서는 개별서비스 제공자가 책임을 집니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 30 조 (면책)</h4>
                    <ul>
                        <li>회사는 전시, 사변, 천재지변, 국가비상사태, 해결이 곤란한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이 면제됩니다.</li>
                        <li>회사는 회원의 귀책사유로 인한 서비스의 중지/이용장애에 대하여 책임을 지지 아니하며, 회사는 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이 면제됩니다.</li>
                        <li>회사는 사전에 공지되거나 긴급하게 실시된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서는 책임이 면제됩니다.</li>
                        <li>회사는 회원이 서비스를 이용하여 기대하는 점수, 순위 등을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.</li>
                        <li>회사는 회원이 본인의 개인정보 등(계정 포함)을 변경하여 얻는 불이익 및 정보상실에 대해서는 일체 책임을 지지 않습니다.</li>
                        <li>회원이 디바이스의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 및 회사의 귀책사유가 없는 네트워크 환경으로 발생하는 문제에 대해서 회사는 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.</li>
                        <li>회사는 회원 상호간 또는 회원과 제3자 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</li>
                        <li>회사는 관련 법령에 특별한 규정이 없는 이상 무료로 제공하는 서비스 또는 콘텐츠와 관련하여 회원에게 손해배상 책임을 부담하지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.</li>
                        <li>회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.</li>
                        <li>회사는 임시회원이 서비스 이용으로 발생한 손해에 대해서는 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 31 조 (회원에 대한 통지)</h4>
                    <ul>
                        <li>회사가 회원에게 통지를 하는 경우 회원의 전자우편주소, 전자메모, 게임 서비스 내 쪽지, 문자메시지(LMS/SMS) 등으로 할 수 있습니다.</li>
                        <li>회사는 회원 전체에게 통지를 하는 경우 7일 이상 게임서비스 내에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.</li>
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 32 조 (재판권 및 준거법)</h4>
                    <ul>
                        <li>한국/아시아/아메리카/유럽 서비스 회원과 회사간 발생한 분쟁으로 소송이 제기되는 경우 대한민국 법을 준거법으로 적용합니다.</li>
                        <li>일본 서비스 회원과 회사간 발생한 분쟁으로 소송이 제기되는 경우 일본 법을 준거법으로 적용합니다.</li>
                        <li>TW,HK,MO 서비스 회원과 회시간 발생한 분쟁으로 소송이 제기되는 경우 대만 법을 준거법으로 적용합니다.</li>
                        <li>본 약관 중 법적인 판단에 따라 무효 및 집행 불가의 항목이 있을 경우 무효로 간주합니다. 그 외의 약관은 여전히 유효합니다.</li>                
                    </ul>
                </div>
                
                <div className="terms_box">
                    <h4>제 33 조 (회원의 고충처리 및 분쟁해결)</h4>
                    <ul>
                        <li>회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 게임 서비스내 또는 그 연결화면에 안내합니다. 회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담인력을 운영합니다.</li>
                        <li>회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 게임서비스 내 공지하거나 제31조제1항에 따라 통지합니다.</li>
                        <li>회사와 회원 간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 회원에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.</li>
                    </ul>
                </div>
                <div className="terms_box">
                    <h4>본 이용약관은 2021년 09월 27일부터 시행됩니다.</h4>
                </div>                              
            </div>
        </div>      
    </>
  );
}

export default Kor_provision;
